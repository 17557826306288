export enum FEATURE_FLAGS {
    QR_MULTI_LINK_QR_CODES = 'qr-multi-link-qr-codes',
    QR_CREATE_QR_CODES_CTA = 'qr-create-cta',
    QR_ANALYTICS_BANNER =  'qr-analytics-banner',
    QR_PRICING_SHOW_STARTER_PLAN = 'qr-pricing-show-starter-plan',
    QR_TRIAL_FIRST_FINISH_PLACEMENT = 'qr-trial-first-finish-placement',
    QR_WEBSITE_NAME_CHANGE = 'qr-website-name-change',
    QR_LIST_ALL_ORG_OBJECTS = 'all-organization-objects',
    QR_COLLAPSIBLE_SIDE_NAV = 'qr-collapsible-side-nav',

    // DBC flags
    VIRTUAL_BACKGROUND = 'virtual-background',
    DBC_EMAIL_SIGNATURE = 'email-signature',
    DBC_ACTIVATION_EXPERIMENT_1 = 'dbc-activation-experiment-1',

    // Forms flags
    FORMS_MIGRATE_TO_KAIZEN = 'forms-migrate-to-kaizen',
    NEW_DBC_ONBOARDING = 'new-dbc-onboarding',

    // AI Analytics Summary flags
    QR_AI_ANALYTICS = 'qr-ai-analytics-cta',
    QR_AI_ANALYTICS_OPT_OUT_SECTION = 'qr-ai-analytics-opt-out-flow'
}
