import { BrowserModule, HAMMER_GESTURE_CONFIG, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { SIDEBAR_TOGGLE_DIRECTIVES } from './shared/sidebar.directive';
import { AsideToggleDirective } from './shared/aside.directive';
import { BreadcrumbsComponent } from './shared/breadcrumb.component';
// Routing Module
import { AppRoutingModule } from './app.routing';
// Layouts
import { IsOnHigherPlan, FullLayoutComponent, MyHammerConfig } from './layouts/full-layout.component';
// Global services
import { AuthService } from './global-services/auth.service';
import { LoggedInGuard } from './guards/logged-in.guard';
import { LoginComponent } from './pages/login.component';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { OverlayService } from './global-services/overlay.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleLayoutComponent } from './layouts/simple-layout.component';
import { P404Component } from './pages/404.component';
import { P500Component } from './pages/500.component';
import { UserOrgService } from './user-account/user-organization.service';
import { UserService } from './user-account/user.service';
import { MultiUserGuard } from './guards/reseller-user.guard';
import { LowerCaseTextInputModule } from './shared/lower-case-text-input/lower-case-text-input.module';
import { MessageModalModule } from './shared/message-modal/message-modal.module';
import { DiscardChangesGuard } from './guards/discard-changes.guard';
import { PasswordResetComponent } from './pages/password-reset.component';
import { IntercomService } from './global-services/intercom.service';
import { HelpMenuDisplayModule } from './global-components/help-menu-display/help-menu-display.module';
import { HelpMenuDisplayService } from './global-components/help-menu-display/help-menu-display.service';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { UserSessionGuard } from './guards/user-session-guard';
import { UserSubscriptionService } from './global-services/user-subscription-service';
import { StripeCardModule } from './global-components/stripe-card/stripe-card.module';
import { QrSavedInstanceService } from './global-services/qr-saved-instance.service';
import { OrganizationCountService } from './global-services/organization-count.service';
import { FirebaseService } from './global-services/firebase.service';
import { SsoAuthService } from './global-services/sso-auth.service';
import { SsoLoginComponent } from './pages/sso-login.component';
import { InviteComponent } from './pages/invite.component';
import { ChurnUnsubscribeComponent } from './pages/churn-unsubscribe.component';
import { ProductOwnerAccessService } from './shared/product-owner-access/product-owner-access.service';
import { ProductOwnerAccessModule } from './shared/product-owner-access/product-owner-access.module';
import { SearchService } from './global-services/search.service';
import { GlobalSearchModule } from './global-search/global-search.module';
import { AmplitudeService } from './global-services/amplitude.service';
import { AdminLoginGuard } from './guards/admin-login.guard';
import { SsoLogoutComponent } from './pages/sso-logout.component';
import { RouterReloadService } from './global-services/router-reload.service';
import { BeaconstacModule } from 'kaizen-design-system';
import { EmailVerificationModule } from './email-verification/email-verification.module';
import { UpsellModule } from './global-components/upsell/upsell.module';
import { GoogleAnalyticsService } from './global-services/google-analytics.service';
import { MyOrganizationsService } from './user-account/my-organizations.service';
import { BeaconstacAddOnModule } from './global-components/beaconstac-add-on/beaconstac-add-on.module';
import { DashboardService } from './global-services/dashboard.service';
import { ProductRouteGuard } from './guards/product.guard';
import { ProductSegmentationService } from './global-services/product-segmentation.service';
import { DBCProductGuard } from './guards/dbc-product.guard';
import { CurrentProductState } from './guards/current-product-state.guard';
import { QrProductGuard } from './guards/qr-product.guard';
import { LoggerService } from './global-services/logger.service';

import { WalletPassTemplateModule } from './wallet-pass-template/wallet-pass-template.module';
import { ApiAccessGuard } from './guards/api-access.guard';
import { IntegrationAccessGuard } from './guards/integration-access.guard';
import { DiscardChangesGuardV2 } from './guards/discard-changes-v2.guard';
import { DiscardChangeModule } from './global-components/discard-change/discard-change.module';
import { BeaconstacMobileDrawerModule } from './global-components/beaconstac-mobile-drawer/beaconstac-mobile-drawer.module';
import { FirebasePopupNotificationModule } from './global-components/firebase-popup-notification/firebase-popup-notification-module';
import { MatNativeDateModule } from '@angular/material/core';
import { CustomFontsService } from './global-services/custom-fonts.service';
import { ResponseAddonModalModule } from './global-components/response-addon-modal/response-addon-modal.module';
import { ResponseAddonService } from './global-services/response-addon-service';
import { RecommendationService } from './qr-recommendations/recommendation-service';
import { FormThemeService } from './forms/form.service';
import { IncentiveService } from './global-services/incentive.service';
import { SessionStorageService } from './global-services/session-storage.service';
import { ProductUsageCountService } from './global-services/product-usage-count.service';
import {
    PwaNudgeDesktopModule
} from './digital-business-card/digital-business-card-global-components/pwa-nudge-component/pwa-nudge-component.module';
import { InfoActionPopupModule} from './global-components/info-action-popup/info-action-popup.module';
import { MycardsPopupComponent } from './global-components/mycards-popup/mycards-popup.component';
import { PwaNudgeMobileModule } from './digital-business-card/digital-business-card-global-components/pwa-nudge-mobile/pwa-nudge-mobile.module';
import { ConditionalDiscardChangesGuardV2 } from './guards/conditional-discard-changes-V2.guard';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import {AuthMigrationComponent} from './pages/auth-migration.component';
import {DbcLDFlagService} from './global-services/dbc-ld-flag/dbc-ld-flag.service';
import { CollapsedSideNavComponent } from './layouts/collapsed-side-nav/collapsed-side-nav.component';
import { WaitLoaderModule } from './global-components/wait-loader/wait-loader.module';
@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        FormsModule,
        BrowserAnimationsModule,
        LowerCaseTextInputModule,
        MessageModalModule,
        ProductOwnerAccessModule,
        TooltipModule.forRoot(),
        HelpMenuDisplayModule,
        StripeCardModule,
        GlobalSearchModule,
        BeaconstacModule,
        EmailVerificationModule,
        UpsellModule,
        BeaconstacMobileDrawerModule,
        BeaconstacAddOnModule,
        WalletPassTemplateModule,
        DiscardChangeModule,
        FirebasePopupNotificationModule,
        MatNativeDateModule,
        ResponseAddonModalModule,
        PwaNudgeDesktopModule,
        InfoActionPopupModule,
        PwaNudgeMobileModule,
        WaitLoaderModule
    ],
    declarations: [
        AppComponent,
        FullLayoutComponent,
        IsOnHigherPlan,
        SimpleLayoutComponent,
        LoginComponent,
        PasswordResetComponent,
        P404Component,
        P500Component,
        BreadcrumbsComponent,
        SIDEBAR_TOGGLE_DIRECTIVES,
        AsideToggleDirective,
        SsoLoginComponent,
        InviteComponent,
        ChurnUnsubscribeComponent,
        SsoLogoutComponent,
        MycardsPopupComponent,
        SignupPageComponent,
        AuthMigrationComponent,
        CollapsedSideNavComponent,
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        AuthService,
        SsoAuthService,
        UserService,
        UserOrgService,
        LoggedInGuard,
        DBCProductGuard,
        QrProductGuard,
        CurrentProductState,
        AdminLoginGuard,
        MultiUserGuard,
        OverlayService,
        RouterReloadService,
        Title,
        DiscardChangesGuard,
        IntercomService,
        AmplitudeService,
        SearchService,
        HelpMenuDisplayService,
        CookieService,
        UserSessionGuard,
        UserSubscriptionService,
        QrSavedInstanceService,
        OrganizationCountService,
        FirebaseService,
        ProductOwnerAccessService,
        GoogleAnalyticsService,
        MyOrganizationsService,
        DashboardService,
        ProductRouteGuard,
        ProductSegmentationService,
        LoggerService,
        ApiAccessGuard,
        IntegrationAccessGuard,
        DiscardChangesGuardV2,
        CustomFontsService,
        ResponseAddonService,
        RecommendationService,
        FormThemeService,
        IncentiveService,
        SessionStorageService,
        ProductUsageCountService,
        ConditionalDiscardChangesGuardV2,
        DbcLDFlagService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
