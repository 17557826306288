<div class="d-flex justify-content-between">

  <div class="font-fs-20 d-flex align-items-center" style="font-weight:600;">{{
      cardType === DBCCardTypes.TEMPLATE_CARDS ?
        DBCCopies.templateCreation.Heading : DBCCopies.cardCreation.Heading
    }}
  </div>

  <div *ngIf="!isMobileScreen">
    <beaconstac-button
      [type]="BUTTON_TYPES.SECONDARY"
      [label]="isMobileScreen ? 'Change Design' : 'Change Card Design'"
      [style]="BUTTON_STYLES.STROKE"
      (onClick)="showLayoutSelectScreen()"
      [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LAYOUT"
    ></beaconstac-button>
    <span class="ml-2"
          [ngClass]="cardSetting.layout | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LAYOUT: cardSetting: orgCardSetting"
          (click)="handleIconClick(CARD_PROPERTY_TYPES.LAYOUT)"></span>
  </div>
</div>

<div class="subheading" [ngClass]="isMobileScreen ? 'pt-2' : 'pt-1'" >{{
    cardType === DBCCardTypes.TEMPLATE_CARDS ?
      DBCCopies.templateCreation.subHeading : DBCCopies.cardCreation.subHeading
  }}
</div>

<app-digital-business-card-templates-dropdown
  *ngIf="hasCardsTemplateAccess && (cardType !== DBCCardTypes.TEMPLATE_CARDS)"
  (onTemplateSelectChange)="onTemplateSelect($event)"
  [product]="product"
  [showNoneOption]="!orgCardSetting.enforce_card_template"
></app-digital-business-card-templates-dropdown>


<!-- Upload image -->

<div class="d-flex align-items-center flex-column my-4" *ngIf="product && !isMultiLanguageContent && showProfileImage">
  <div (click)="updateLogo(IMAGE_TYPE.DBCUserImage)" *ngIf="!product?.user_image_url"
       class="profile-img-container"
       [ngClass]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.USER_IMAGE_URL) ? 'custom-not-allowed' : 'cursor-pointer'">

    <img class="profile-img"
         [src]="environment.imagesEndpoint + 'vcard_plus/default_vcard_image.png'" alt="Logo image">
    <span class="fa fa-camera img-camera">
        <input class="d-none" [accept]="['image/png', 'image/jpeg']" type="file"
               (change)="onVcardPlusUserImageAdded($event)"
               [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.USER_IMAGE_URL"/>
      </span>
  </div>

  <div (click)="updateProfilePic()" *ngIf="product.user_image_url"
       class="profile-img-container"
       [ngClass]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.USER_IMAGE_URL) ? 'custom-not-allowed' : 'cursor-pointer'">
    <img class="profile-img"
         [src]="product.user_image_url">
    <span class="fa fa-pencil img-pencil">
      <input class="d-none" [accept]="['image/png', 'image/jpeg']" type="file"
             (change)="onVcardPlusUserImageAdded($event)"
             [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.USER_IMAGE_URL"/>
    </span>
  </div>
  <div>Profile photo
    <span
      [ngClass]="cardSetting.user_image_url | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.USER_IMAGE_URL: cardSetting: orgCardSetting"
      (click)="handleIconClick(CARD_PROPERTY_TYPES.USER_IMAGE_URL)"></span>
  </div>
</div>


<div class="d-flex align-items-center flex-column my-4" *ngIf="product && isMultiLanguageContent && showProfileImage">
  <div (click)="updateLogo(IMAGE_TYPE.DBCUserImage)" *ngIf="!product?.language_data[currentLanguage].user_image_url"
       class="img-avatar profile-name-holder profile-img-container"
       [ngClass]="(cardSetting.user_image_url && (cardType !== DBCCardTypes.TEMPLATE_CARDS)) ? 'custom-not-allowed' : 'cursor-pointer'">
             <span>
               <img style="border-radius: 50%; width:100%; height:100%; object-fit: cover;"
                    [src]="environment.imagesEndpoint + 'vcard_plus/default_vcard_image.png'" alt="Logo image">
             </span>
             <span class="fa fa-camera img-camera">
               <input style="display: none" [accept]="['image/png', 'image/jpeg']" type="file"
                      (change)="onVcardPlusUserImageAddedMultiLang($event)"
                      [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.USER_IMAGE_URL"/>
             </span>
  </div>

  <div (click)="updateProfilePic()" *ngIf="product.language_data[currentLanguage].user_image_url"
       class="img-avatar profile-name-holder profile-img-container"
       [ngClass]="(cardSetting.user_image_url && (cardType !== DBCCardTypes.TEMPLATE_CARDS)) ? 'custom-not-allowed' : 'cursor-pointer'">
             <span>
               <img style="border-radius: 50%; width:100%; height:100%; object-fit: cover;"
                    [src]="product.language_data[currentLanguage].user_image_url">
             </span>
    <span class="fa fa-pencil img-pencil">
               <input style="display: none" [accept]="['image/png', 'image/jpeg']" type="file"
                      (change)="onVcardPlusUserImageAddedMultiLang($event)"
                      [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.USER_IMAGE_URL"/>
             </span>
  </div>
  <div>Profile photo
    <span
      [ngClass]="cardSetting.user_image_url | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.USER_IMAGE_URL: cardSetting: orgCardSetting"
      (click)="handleIconClick(CARD_PROPERTY_TYPES.CUSTOMIZATIONS)"></span>
  </div>
</div>


<div style="max-width:100%" *ngIf="product && !isMultiLanguageContent">

  <div class="bx--row" style="margin: 40px 0px" *ngIf="dbcFormUpsellTouhPointsPermission?.multi_language && (cardType !== DBCCardTypes.TEMPLATE_CARDS)">
    <div class="bx--col p-0 d-flex align-items-center">
      <beaconstac-toggle #switchMultiLanguage
                         id="multi-language-content"
                         [disabled]="!hasWriteAccess"
                         (change)="onMultiLanguageContentSwitchChange()"
                         [checked]="isMultiLanguageContent"
      ></beaconstac-toggle>
      <label class="form-control-label" style="font-size: 15px" id="multi-language-support-content">Multi-language
        support</label>
        <img class="mb-2 pl-1" *ngIf="!hasMultiLangAccess" alt="multi-lang-toggle" [src]="'../../assets/logo/crown-solid.svg'" />
    </div>
  </div>

  <!-- Personal information -->
  <div class="bg-white box-border">
    <div class="d-flex justify-content-between py-3 px-4" (click)="updateCollapse(COLLAPSE.PERSONAL_INFO)">
      <div class="block-title">PERSONAL INFO</div>
      <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                         [icon]="collapseSection.personalInfo ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                         [style]="BUTTON_STYLES.TEXT">
      </beaconstac-button>
    </div>

    <hr *ngIf="!collapseSection.personalInfo" class="block-border-bottom">

    <div class="px-4 pb-4 pt-3" *ngIf="!collapseSection.personalInfo" @collapse>
      <div class="bx--row">
        <div class="bx--col-lg-8">
          <beaconstac-textfield name="Prefix" [label]="true"
                                [placeholder]="''"
                                [title]="'Prefix'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.prefix"
                                [required]="false"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PREFIX"
                                (input)="onVCardContentChange()"
                                [maxWidth]="'300px'"
                                [labelIcon]="cardSetting.prefix | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PREFIX: cardSetting: orgCardSetting"
                                (labelIconClick)="handleIconClick(CARD_PROPERTY_TYPES.PREFIX)"
          ></beaconstac-textfield>
        </div>

        <div class="bx--col-lg-8 px-3" [ngClass]="isMobileScreen ? 'mt-3' : '' ">
          <beaconstac-textfield name="First_name" [label]="true"
                                [placeholder]="''"
                                [title]="'First name*'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.first_name"
                                [required]="true"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.FIRST_NAME"
                                [errorMessage]="product.first_name.length > 255 ? 'Name cannot be more than 255 characters' : 'First name is required'"
                                (textChange)="firstNameValidation($event)"
                                [maxWidth]="'300px'"
                                [valid]="validations.first_name ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR"
                                [labelIcon]="cardSetting.first_name | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.FIRST_NAME: cardSetting: orgCardSetting"
                                (labelIconClick)="handleIconClick(CARD_PROPERTY_TYPES.FIRST_NAME)">
          </beaconstac-textfield>
        </div>
      </div>

      <div class="bx--row pt-3">
        <div class="bx--col-lg-8">
          <beaconstac-textfield name="Last_name" [label]="true"
                                [placeholder]="''"
                                [title]="'Last name'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.last_name"
                                [required]="false"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LAST_NAME"
                                (input)="onVCardContentChange()"
                                [maxWidth]="'300px'"
                                [labelIcon]="cardSetting.last_name | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LAST_NAME: cardSetting: orgCardSetting"
                                (labelIconClick)="handleIconClick(CARD_PROPERTY_TYPES.LAST_NAME)">
          </beaconstac-textfield>
        </div>
        <div class="bx--col-lg-8" [ngClass]="isMobileScreen ? 'mt-3' : '' " >
          <beaconstac-textfield name="Suffix" [label]="true"
                                [placeholder]="''"
                                [title]="'Suffix'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.suffix"
                                [required]="false"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SUFFIX"
                                (input)="onVCardContentChange()"
                                [maxWidth]="'300px'"
                                [labelIcon]="cardSetting.suffix | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SUFFIX: cardSetting: orgCardSetting"
                                (labelIconClick)="handleIconClick(CARD_PROPERTY_TYPES.SUFFIX)">
          </beaconstac-textfield>
        </div>
      </div>

      <div class="bx--row pt-3">
        <div class="bx--col-lg-16">
          <beaconstac-textfield name="Pronouns" [label]="true"
                                [placeholder]="''"
                                [title]="'Pronouns'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.pronouns_v2"
                                [required]="false"
                                (input)="onVCardContentChange()"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PRONOUNS"
                                [maxWidth]="'300px'"
                                [labelIcon]="cardSetting.pronouns | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PRONOUNS: cardSetting: orgCardSetting"
                                (labelIconClick)="handleIconClick(CARD_PROPERTY_TYPES.PRONOUNS)"
          ></beaconstac-textfield>
        </div>
      </div>
    </div>

  </div>

  <!-- Company Detail -->
  <div class="bg-white mt-4 box-border">
    <div class="d-flex justify-content-between py-3 px-4" (click)="updateCollapse(COLLAPSE.COMPANY_DETAILS)">
      <div class="block-title">COMPANY DETAILS</div>
      <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                         [icon]="collapseSection.companyDetails ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                         [style]="BUTTON_STYLES.TEXT">
      </beaconstac-button>
    </div>

    <hr *ngIf="!collapseSection.companyDetails" class="block-border-bottom">

    <div class="px-4 pb-4 pt-3" *ngIf="!collapseSection.companyDetails" @collapse>
      <div class="bx--row">
        <div class="bx--col-lg-16">
          <beaconstac-textfield name="Company name" [label]="true"
                                [placeholder]="''"
                                [title]="'Company name'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.company"
                                [required]="false"
                                (input)="onVCardContentChange()"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.COMPANY"
                                [maxWidth]="'300px'"
                                [labelIcon]="cardSetting.company | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.COMPANY: cardSetting: orgCardSetting"
                                (labelIconClick)="handleIconClick(CARD_PROPERTY_TYPES.COMPANY)"
          ></beaconstac-textfield>
        </div>
      </div>

      <div class="bx--row pt-3">
        <div class="bx--col">
          <beaconstac-textfield name="Department" [label]="true"
                                [placeholder]="''"
                                [title]="'Department'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.department"
                                [required]="false"
                                (input)="onVCardContentChange()"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.DEPARTMENT"
                                [maxWidth]="'300px'"
                                [labelIcon]="cardSetting.department | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.DEPARTMENT: cardSetting: orgCardSetting"
                                (labelIconClick)="handleIconClick(CARD_PROPERTY_TYPES.DEPARTMENT)"
          ></beaconstac-textfield>
        </div>
      </div>
      <div class="bx--row pt-3">
        <div class="bx--col" [ngClass]="isMobileScreen ? 'mt-3' : '' ">
          <beaconstac-textfield name="Job title" [label]="true"
                                [placeholder]="''"
                                [title]="'Job title'"
                                [type]="TEXT_FIELD_TYPES.TEXTAREA"
                                [(text)]="product.designation"
                                [required]="false"
                                (input)="onVCardContentChange()"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.DESIGNATION"
                                [maxWidth]="'300px'"
                                [labelIcon]="cardSetting.designation | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.DESIGNATION: cardSetting: orgCardSetting"
                                (labelIconClick)="handleIconClick(CARD_PROPERTY_TYPES.DESIGNATION)"
          ></beaconstac-textfield>
        </div>
      </div>

      <div class="bx--row pt-3">
        <div class="bx--col textarea-container">
          <beaconstac-textfield name="Bio" [label]="true"
                                [placeholder]="''"
                                [title]="'Bio'"
                                [type]="TEXT_FIELD_TYPES.TEXTAREA"
                                [(text)]="product.summary"
                                [required]="false"
                                (input)="onVCardContentChange()"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SUMMARY"
                                [maxWidth]="'100%'"
                                [labelIcon]="cardSetting.summary | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SUMMARY: cardSetting: orgCardSetting"
                                (labelIconClick)="handleIconClick(CARD_PROPERTY_TYPES.SUMMARY)"
          ></beaconstac-textfield>
        </div>
      </div>

      <div class="bx--row pt-3">
        <div class="bx--col-lg-8">
          <div class="beaconstac-label">Company logo
            <span
              [ngClass]="cardSetting.logo_url | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LOGO_URL: cardSetting: orgCardSetting"
              (click)="handleIconClick(CARD_PROPERTY_TYPES.LOGO_URL)">
                  </span>
          </div>
          <beaconstac-upload #logoDropVCard
                             [width]="'webkit-fill-available'"
                             (fileChange)="onVcardPlusLogoAdded($event)" [isUploading]="isIconUploading"
                             [acceptTypes]="['image/png', 'image/jpeg', 'image/svg+xml']" [maxSize]="5e+7"
                             [height]="'auto'"
                             [preview]="product.logo_url" [overrideClick]="hasWriteAccess"
                             (onClick)="updateLogo(IMAGE_TYPE.DBCLogo)"
                             [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LOGO_URL"
                             [showRemoveButton]="true"
                             (onFileRemove)="onVcardPlusLogoRemoved()">
          </beaconstac-upload>
        </div>

        <div class="bx--col-lg-8">
          <div class="d-flex">
            <div class="pr-2" [ngClass]="isMobileScreen ? 'mt-3' : ''"  style="flex: 6">
              <div class="beaconstac-label mb-3 logo-slider">
                Logo size
                <span
                  [ngClass]="cardSetting.logo_url | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LOGO_URL: cardSetting: orgCardSetting"
                  (click)="handleIconClick(CARD_PROPERTY_TYPES.LOGO_URL)">
                              </span>
              </div>
              <beaconstac-slider
                [(value)]="product.logo_size"
                (valueChange)="onVCardContentChange()"
                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LOGO_URL"
              >
              </beaconstac-slider>
            </div>

            <div class=" logo-slider-value pl-2" style="flex: 2" [ngClass]="isMobileScreen ? 'pt-5' : 'pt-3'">
              <beaconstac-textfield
                [text]='product.logo_size + "%"'
                [disableInput]="true"
                [disabled]="true"
              ></beaconstac-textfield>
            </div>
          </div>
        </div>
      </div>

      <div class="bx--row pt-4">
        <div class="bx--col">
          <div class="beaconstac-label">Location
            <span
              [ngClass]="cardSetting.address | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS: cardSetting: orgCardSetting"
              (click)="handleIconClick(CARD_PROPERTY_TYPES.ADDRESS)">
                        </span>
          </div>
        <div class="location-input-container mb-4">
          <input
              placeholder="Search Google Maps"
              style="font-family:Arial, FontAwesome"
              [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS"
              [attr.disabled]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS) ? 'disabled' : null"
              class="form-control fa search-box mw-100"
              type="text"
              id="vcard-address"
              name="vcard-address"
              #search
              [formControl]="searchControl"
          >
          <beaconstac-button
              *ngIf="product.address_url"
              [label]="''"
              [type]="BUTTON_TYPES.DANGER"
              [style]="BUTTON_STYLES.TEXT"
              (onClick)="removeLocation()"
              [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS"
              [icon]="'fa fa-times'"
          >
          </beaconstac-button>
        </div>

          <div style="border: solid thin #888;" class="mw-100 mh-20">
            <app-map class="agm-map-container" [center]="center"
                     [markerOptions]="markerOptions"
                     [locationData]="locationData"
                     [addMarkerCluster]="addMarkerCluster"
                     [zoomValue]="mapZoomValue"
                     (onMapDragEnd)="(hasWriteAccess && !cardSetting.address) ? setVCardLocationURL($event) : null"
                     (onMapClick)="(hasWriteAccess && !cardSetting.address)? setVCardLocationURL($event): null">
            </app-map>
          </div>
        </div>

      </div>

      <div class="bx--row pt-3">
        <div class="bx--col-lg-16">
          <beaconstac-textfield name="Address" [label]="true"
                                [placeholder]="'Enter address or search on Google Maps above'"
                                [title]="'Address'"
                                [type]="TEXT_FIELD_TYPES.TEXTAREA"
                                [(text)]="product.address_v2"
                                [required]="false"
                                (input)="onVCardContentChange()"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS"
                                [maxWidth]="'100%'"
                                [labelIcon]="cardSetting.address | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS: cardSetting: orgCardSetting"
                                (labelIconClick)="handleIconClick(CARD_PROPERTY_TYPES.ADDRESS)"
          ></beaconstac-textfield>
        </div>
      </div>

    </div>
  </div>

  <!-- Contact Details -->
  <div class="bg-white mt-4 box-border">
    <div class="d-flex justify-content-between py-3 px-4" (click)="updateCollapse(COLLAPSE.CONTACT_DETAILS)">
      <div class="block-title">CONTACT DETAILS</div>
      <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                         [icon]="collapseSection.contactDetails ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                         [style]="BUTTON_STYLES.TEXT">
      </beaconstac-button>
    </div>

    <hr *ngIf="!collapseSection.contactDetails" class="block-border-bottom">

    <div *ngIf="!collapseSection.contactDetails" cdkDropList (cdkDropListDropped)="updateContactDetailsOrdering($event)" [cdkDropListDisabled]="dragdropState" @collapse>
        <div *ngFor="let contact of product.__contact_info_list__; let i = index" [cdkDragLockAxis]="'y'" cdkDrag>
            <div [ngSwitch]="contact.key">
                    <ng-container *ngSwitchCase="CONTACT_DETAIL.PHONE_V2">
                        <div class="phone desktop-screen-border" [ngClass]="!isMobileScreen ? 'm-4' : 'm-3'">
                            <div class="d-flex bg-white" [ngClass]="!isMobileScreen ? 'p-3' : 'p-2'" style="border-radius:8px">
                                <img class="group-icon cursor-pointer" src="../../../../assets/img/group-icon.png" alt="group_icon" height="27" [ngClass]="isMobileScreen ? 'pr-2' : 'pr-3'">
                                <div class="phone_group w-100">
                                    <div class="beaconstac-label" id="phone_v2">Phone
                                        <beaconstac-tooltip
                                            [elementClass]="'fas fa-info-circle'"
                                            [content]="'Include a country code to ensure people can contact you easily'"
                                            [tooltipPosition]="TOOLTIP_POSITION.TOP"
                                            [caretPosition]="CARET_POSITION.LEFT"
                                        ></beaconstac-tooltip>
                                        <span
                                            [ngClass]="cardSetting.phone | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PHONE: cardSetting: orgCardSetting"
                                            (click)="handleIconClick(CARD_PROPERTY_TYPES.PHONE)"></span>
                                    </div>

                                    <beaconstac-input-group [childComponent]="phoneCompo">
                                    </beaconstac-input-group>

                                    <ng-template #phoneCompo>
                                        <div class="d-flex flex-column" *ngFor="let phone of this.product.phone_v2 ; let i = index">

                                            <div class="d-flex flex-nowrap mb-2" style="border: 1px solid #DBDFE3;">
                                                <beaconstac-filter-dropdown
                                                    [class]="'input-group-selector'"
                                                    [ngStyle]="{'flex':'3'}"
                                                    [isInputGroup]="true"
                                                    title="contact-section-phone-{{i}}"
                                                    [value]="{'name' : phone.label , 'value' : phone.label }"
                                                    placeholder="Select Label"
                                                    [listItems]="labelsForPhone"
                                                    optionLabelField="name"
                                                    optionValueField="value"
                                                    [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PHONE"
                                                    (valueChange)="setLabel('phone',$event,i);"
                                                ></beaconstac-filter-dropdown>

                                                <beaconstac-textfield
                                                    [ngStyle]="{'flex':'6'}"
                                                    [maxWidth]="'100%'"
                                                    [isInputGroup]="true"
                                                    [class]="'input-group-selector'"
                                                    type="text"
                                                    [(text)]="phone.value"
                                                    [placeholder]="'Phone number'"
                                                    [changeButtonVisible]="false"
                                                    (textChange)="onVCardContentChange()"
                                                    [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PHONE"
                                                ></beaconstac-textfield>

                                                <beaconstac-button
                                                    [class]="'input-group-selector'"
                                                    [ngStyle]="{'flex':'1'}"
                                                    [isInputGroup]="true"
                                                    [style]="BUTTON_STYLES.STROKE"
                                                    type="danger"
                                                    [disabled]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PHONE ) || this.product.phone_v2.length <= 1"
                                                    label=""
                                                    icon="fas fa-trash-alt"
                                                    iconPosition="left"
                                                    (onClick)="removeField('phone',i)"
                                                ></beaconstac-button>
                                            </div>
                                        </div>
                                    </ng-template>

                                    <beaconstac-button
                                        [style]="BUTTON_STYLES.TEXT"
                                        type="primary"
                                        [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PHONE"
                                        label="Add Phone Number"
                                        icon="fa fa-plus"
                                        [iconSize]="'12px'"
                                        iconPosition="left"
                                        (onClick)="addNewField('phone')"
                                    ></beaconstac-button>
                                </div>

                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="CONTACT_DETAIL.EMAIL_V2">
                        <!-- email -->
                        <div class="email desktop-screen-border" [ngClass]="!isMobileScreen ? 'm-4' : 'm-3'">
                            <div class="d-flex bg-white" [ngClass]="!isMobileScreen ? 'p-3' : 'p-2'" style="border-radius:8px">

                                <img class="group-icon cursor-pointer" src="../../../../assets/img/group-icon.png" alt="group_icon" height="27" [ngClass]="isMobileScreen ? 'pr-2' : 'pr-3'">

                                <div class="email_group w-100">
                                    <div class="mb-2 beaconstac-label" id="email_v2">Email
                                        <span
                                            [ngClass]="cardSetting.email | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.EMAIL: cardSetting: orgCardSetting"
                                            (click)="handleIconClick(CARD_PROPERTY_TYPES.EMAIL)"></span>
                                    </div>

                                    <beaconstac-input-group [childComponent]="emailCompo"></beaconstac-input-group>

                                    <ng-template #emailCompo>
                                        <div class="flex flex-column" *ngFor="let email of this.product.email_v2 ; let i = index">
                                            <div class="d-flex flex-nowrap mb-2" style="border: 1px solid #DBDFE3;">
                                                <beaconstac-filter-dropdown
                                                    [class]="'input-group-selector'"
                                                    [ngStyle]="{'flex':'3'}"
                                                    [isInputGroup]="true"
                                                    [value]="{'name' : email.label , 'value' : email.label }"
                                                    title="contact-section-email-{{i}}"
                                                    placeholder="Select Label"
                                                    [listItems]="labelsForEmail"
                                                    optionLabelField="name"
                                                    optionValueField="value"
                                                    [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.EMAIL"
                                                    (valueChange)="setLabel('email',$event,i);"
                                                ></beaconstac-filter-dropdown>

                                                <beaconstac-textfield
                                                    [ngStyle]="{'flex':'6'}"
                                                    [maxWidth]="'100%'"
                                                    [isInputGroup]="true"
                                                    [class]="'input-group-selector'"
                                                    type="text"
                                                    [(text)]="email.value"
                                                    placeholder="Email"
                                                    [changeButtonVisible]="false"
                                                    maxWidth="300px"
                                                    (textChange)="inputChange('email',$event,i)"
                                                    [valid]="email.valid"
                                                    [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.EMAIL"
                                                ></beaconstac-textfield>

                                                <beaconstac-button
                                                    [class]="'input-group-selector'"
                                                    [ngStyle]="{'flex':'1'}"
                                                    [isInputGroup]="true"
                                                    [style]="BUTTON_STYLES.STROKE"
                                                    type="danger"
                                                    [disabled]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.EMAIL ) || this.product.email_v2.length <= 1"
                                                    label=""
                                                    icon="fas fa-trash-alt"
                                                    iconPosition="left"
                                                    (onClick)="removeField('email',i)"
                                                ></beaconstac-button>
                                            </div>

                                            <div class="text-field-error-msg">
                                                <div *ngIf="email?.valid === TEXT_FIELD_VALIDATIONS.ERROR" style="color:#ff445e"> Please enter a valid
                                                    email address.
                                                </div>
                                            </div>
                                        </div>

                                    </ng-template>

                                    <beaconstac-button
                                        [style]="BUTTON_STYLES.TEXT"
                                        type="primary"
                                        [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.EMAIL"
                                        label="Add Email"
                                        icon="fa fa-plus"
                                        [iconSize]="'12px'"
                                        iconPosition="left"
                                        (onClick)="addNewField('email')"
                                    ></beaconstac-button>

                                </div>

                            </div>

                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="CONTACT_DETAIL.WEBSITE_V2">
                        <!-- website -->
                        <div class="website desktop-screen-border" [ngClass]="!isMobileScreen ? 'm-4' : 'm-3'">
                            <div class="d-flex bg-white" [ngClass]="!isMobileScreen ? 'p-3' : 'p-2'" style="border-radius:8px">
                                <img class="group-icon cursor-pointer" src="../../../../assets/img/group-icon.png" alt="group_icon" height="27" [ngClass]="isMobileScreen ? 'pr-2' : 'pr-3'">
                                <div class="website_group w-100">
                                    <div class="mb-2 beaconstac-label" id="website_v2">Website
                                        <span
                                            [ngClass]="cardSetting.website | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.WEBSITE: cardSetting: orgCardSetting"
                                            (click)="handleIconClick(CARD_PROPERTY_TYPES.WEBSITE)">
                                        </span>
                                    </div>
                                    <beaconstac-input-group [childComponent]="websiteCompo"></beaconstac-input-group>

                                    <ng-template #websiteCompo>
                                        <div class="flex flex-column" *ngFor="let website of this.product.website_v2 ; let i = index">
                                            <div class="d-flex flex-nowrap mb-2" style="border: 1px solid #DBDFE3;">
                                                <beaconstac-filter-dropdown
                                                    [ngStyle]="{'flex':'3'}"
                                                    [isInputGroup]="true"
                                                    [class]="'input-group-selector'"
                                                    [value]="{'name' : website.label , 'value' : website.label }"
                                                    title="contact-section-website-{{i}}"
                                                    placeholder="Select Label"
                                                    [listItems]="labelsForWebsite"
                                                    optionLabelField="name"
                                                    optionValueField="value"
                                                    [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.WEBSITE"
                                                    (valueChange)="setLabel('website',$event,i);"
                                                ></beaconstac-filter-dropdown>
                                                <beaconstac-textfield
                                                    [ngStyle]="{'flex':'6'}"
                                                    [maxWidth]="'100%'"
                                                    [isInputGroup]="true"
                                                    [class]="'input-group-selector'"
                                                    [(text)]="website.value"
                                                    placeholder="Website Link"
                                                    [changeButtonVisible]="false"
                                                    (textChange)="inputChange('website',$event,i)"
                                                    [valid]="website.valid"
                                                    [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.WEBSITE"
                                                ></beaconstac-textfield>
                                                <beaconstac-button
                                                    [ngStyle]="{'flex':'1'}"
                                                    [isInputGroup]="true"
                                                    [class]="'input-group-selector'"
                                                    [style]="BUTTON_STYLES.STROKE"
                                                    type="danger"
                                                    [disabled]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.WEBSITE ) || this.product.website_v2.length <= 1"
                                                    label=""
                                                    icon="fas fa-trash-alt"
                                                    iconPosition="left"
                                                    (onClick)="removeField('website',i)"
                                                ></beaconstac-button>
                                            </div>

                                            <div class="text-field-error-msg">
                                                <div *ngIf="website?.valid === TEXT_FIELD_VALIDATIONS.ERROR" style="color:#ff445e"> Please enter a valid
                                                    URL
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <beaconstac-button
                                        [style]="BUTTON_STYLES.TEXT"
                                        type="primary"
                                        [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.WEBSITE"
                                        label="Add Website"
                                        icon="fa fa-plus"
                                        [iconSize]="'12px'"
                                        iconPosition="left"
                                        (onClick)="addNewField('website')"
                                    ></beaconstac-button>
                                </div>
                            </div>

                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="CONTACT_DETAIL.CUSTOM_FIELDS">
                        <!-- custom-field -->
                        <div class="custom-field desktop-screen-border" [ngClass]="!isMobileScreen ? 'm-4' : 'm-3'">
                            <div class="d-flex bg-white" [ngClass]="!isMobileScreen ? 'p-3' : 'p-2'" style="border-radius:8px">
                                <img class="group-icon cursor-pointer" src="../../../../assets/img/group-icon.png" alt="group_icon" height="27" [ngClass]="isMobileScreen ? 'pr-2' : 'pr-3'">
                                <div class="custom_field_group w-100">
                                    <div class="mb-2 beaconstac-label">Custom field
                                        <span
                                            [ngClass]="cardSetting.custom_fields | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOM_FIELDS: cardSetting: orgCardSetting"
                                            (click)="handleIconClick(CARD_PROPERTY_TYPES.CUSTOM_FIELDS)"></span>
                                    </div>

                                    <beaconstac-input-group [childComponent]="customCompo"></beaconstac-input-group>

                                    <ng-template #customCompo>
                                        <div class="d-flex flex-nowrap mb-2" style="border: 1px solid #DBDFE3;"
                                             *ngFor="let custom_field of this.product.custom_fields ; let i = index">
                                            <beaconstac-textfield
                                                [ngStyle]="{'flex':'3'}"
                                                [maxWidth]="'100%'"
                                                [isInputGroup]="true"
                                                [class]="'input-group-selector'"
                                                [(text)]="custom_field.label"
                                                placeholder="Label"
                                                [changeButtonVisible]="false"
                                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOM_FIELDS"
                                                (textChange)="inputChange('custom_fields',$event,i)"
                                            ></beaconstac-textfield>
                                            <beaconstac-textfield
                                                [ngStyle]="{'flex':'6'}"
                                                [maxWidth]="'100%'"
                                                [isInputGroup]="true"
                                                [class]="'input-group-selector'"
                                                [(text)]="custom_field.value"
                                                placeholder="Value"
                                                [changeButtonVisible]="false"
                                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOM_FIELDS"
                                                (textChange)="inputChange('custom_fields',$event,i)"
                                            ></beaconstac-textfield>

                                            <beaconstac-button
                                                [ngStyle]="{'flex':'1'}"
                                                [isInputGroup]="true"
                                                [class]="'input-group-selector'"
                                                [style]="BUTTON_STYLES.STROKE"
                                                type="danger"
                                                [disabled]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOM_FIELDS ) || this.product.custom_fields.length <= 1"
                                                label=""
                                                icon="fas fa-trash-alt"
                                                iconPosition="left"
                                                (onClick)="removeField('custom_fields', i)"
                                            ></beaconstac-button>
                                        </div>
                                    </ng-template>

                                    <beaconstac-button
                                        [style]="BUTTON_STYLES.TEXT"
                                        type="primary"
                                        [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOM_FIELDS"
                                        label="Add Custom Field"
                                        icon="fa fa-plus"
                                        [iconSize]="'12px'"
                                        iconPosition="left"
                                        (onClick)="addNewField('custom_fields')"
                                    ></beaconstac-button>
                                </div>
                            </div>


                        </div>
                    </ng-container>
                </div>
        </div>
    </div>
  </div>

  <div class="new-social-links bg-white mt-4 box-border">
    <div class="d-flex justify-content-between py-3 px-4" (click)="updateCollapse (COLLAPSE.SOCIAL_LINKS)">
      <div class="block-title">SOCIAL LINKS
        <span [ngClass]="cardSetting.social_links | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS: cardSetting: orgCardSetting" (click)="handleIconClick(CARD_PROPERTY_TYPES.SOCIAL_LINKS)">
        </span>
      </div>
      <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                         [icon]="collapseSection.socialLinks ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                         [style]="BUTTON_STYLES.TEXT">
      </beaconstac-button>
    </div>


    <hr *ngIf="!collapseSection.socialLinks" class="block-border-bottom">

    <div class="" *ngIf="!collapseSection.socialLinks" @collapse>
      <div *ngIf="!isMobileScreen" class="px-4 pb-4 pt-3">
          <ng-container *ngIf="!showSvgSocialIcons">
            <button *ngFor="let media of socialMediaMetaData; let i = index;" type="button" class="btn"
                    (click)="addSocialField(media.id)"
                    [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS"
                    data-toggle="tooltip"
                    style="margin-right: 10px; margin-bottom: 14px; min-height: 50px; background-size:contain;
                            width: 50px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-color: transparent;"
                    [style.border]="isMediaSelected(media.id) ? '2px solid #2595FF' : '0.5px solid #e7ecec'"
                    data-placement="right"
                    [style.background-image]="'url('+media.logo+')'"
                    container="body" title="{{media.title}}"></button>
          </ng-container>

          <ng-container *ngIf="showSvgSocialIcons">
              <button *ngFor="let media of socialMediaMetaData; let i = index;" class="social-icons-btn"
                      (click)="addSocialField(media.id)"
                      [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS"
              >
                  <i *ngIf="media['font_aweomse_class']" [ngClass]="media['font_aweomse_class']" style="color: white;"></i>
                  <div *ngIf="!media['font_aweomse_class']" class="social-icon-image d-flex justify-content-center" [innerHtml]="media | getSocialIconSvg">
                  </div>
              </button>
          </ng-container>
      </div>
      <div *ngIf="isMobileScreen" class="social-icons-container mb-3">
        <div class="form-group has-search mx-4 my-3">
          <span class="fas fa-search form-control-feedback"></span>
          <input type="text" id="search-input" placeholder="Search" [value]="searchedIconInput" (input)="onIconSearch($event)" class="form-control">
        </div>
        <div *ngIf="socialMediaMetaMobileList.length" class="list-container mx-4">
          <div class="icon-list-item" *ngFor="let icon of socialMediaMetaMobileList; let i = index" >
              <ng-container *ngIf="!showSvgSocialIcons">
                <button class="cursor-pointer"
                        style="margin-right: 10px; margin-bottom: 14px; min-height: 50px; background-size:contain;
                            width: 50px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-color: transparent;"
                        [style.border]="isMediaSelected(icon.id) ? '2px solid #2595FF' : '0.5px solid #e7ecec'"(click)="addSocialField(icon.id)"
                        [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS">
                  <img class="icon-image" src="{{icon.logo}}">
                </button>
              </ng-container>
              <ng-container *ngIf="showSvgSocialIcons">
                  <button class="social-icons-btn"
                          (click)="addSocialField(icon.id)"
                          [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS"
                  >
                      <i *ngIf="icon['font_aweomse_class']" [ngClass]="icon['font_aweomse_class']" style="color: white;"></i>
                      <div *ngIf="!icon['font_aweomse_class']" class="social-icon-image d-flex justify-content-center" [innerHtml]="icon | getSocialIconSvg">
                      </div>
                  </button>
              </ng-container>
          </div>
        </div>
        <div *ngIf="!socialMediaMetaMobileList.length" class="mt-2">
          <div class="text-center w-100 d-flex flex-column social-icon-search">
            <span>Uh-oh! No results found</span>
            <span *ngIf="!socialMediaMetaMobileList.length && showCustomIcon">Add a custom social media link</span>
          </div>
        </div>
        <div *ngIf="!socialMediaMetaMobileList.length && showCustomIcon" class="mt-3">
          <div class="icon-list-item">
              <div *ngIf="!showSvgSocialIcons" class="cursor-pointer" style="width: 50px; height:50px" (click)="addSocialField(24)">
                <img class="icon-image" src="../../../assets/img/vcard-plus-social-icons/URL.png">
              </div>
              <div *ngIf="showSvgSocialIcons" class="ml-4 social-icons-box d-flex justify-content-center align-items-center"
                   (click)="addSocialField(24)"
                   [innerHtml]="socialMediaMetaData[23] | getSocialIconSvg"
              ></div>
          </div>
        </div>
      </div>

      <div *ngFor="let field of socialFieldDetails; let i = index" class="d-flex mx-4 my-3 social-icon-input" style="column-gap: 12px;">
        <div class="d-flex align-items-center" style="max-width: 42px; width:100%; height:36px">
            <img *ngIf="!showSvgSocialIcons" class="icon-image" src="{{field.image}}">
            <div *ngIf="showSvgSocialIcons" class="social-icons-box d-flex justify-content-center align-items-center">
                <i *ngIf="field['font_aweomse_class']" [ngClass]="field['font_aweomse_class']" style="color: white;"></i>
                <div *ngIf="!field['font_aweomse_class']" class="social-icon-image d-flex justify-content-center" [innerHtml]="field | getSocialIconSvg"></div>
            </div>
        </div>
        <div class="d-flex social-icon-label" style="max-width: 200px; min-width: 100px;">
          <h5 style="font-weight: bold; font-size:15px" class="pt-2">{{field.title}}</h5>
        </div>
        <div class="social-url-input" style="max-width: 300px; max-height: fit-content;">
          <input id="vcardPlusForm-social" name="vcardPlusForm-social" #vcardPlusFormSocial="ngModel" class="form-control"
                 (input)="onVCardContentChange(); onChangeSocialLink($event, field.pos);"
                 [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS"
                 placeholder="{{field.placeholder}}"
                 [(ngModel)]="field.model" required>
          <div *ngIf="vcardPlusFormSocial.invalid && (vcardPlusFormSocial.dirty || vcardPlusFormSocial.touched)"
               class="alert alert-danger px-1">

            <div *ngIf="vcardPlusFormSocial.errors.required && field.title==='Whatsapp'"
                 style="vertical-align: middle; line-height:16px;">
              Please enter a valid phone number.
            </div>
            <div *ngIf="vcardPlusFormSocial.errors.required && field.title!=='Whatsapp'"
                 style="vertical-align: middle; line-height:16px;">
              Please enter a valid url.
            </div>
          </div>
          <div
            *ngIf="(!(vcardPlusFormSocial.invalid && (vcardPlusFormSocial.dirty || vcardPlusFormSocial.touched)) && !(field.model | isLinkValid) && (field.title!=='Whatsapp'))"
            class="alert alert-danger px-1">
            <div *ngIf="!(vcardPlusFormSocial.value | isLinkValid)" style="vertical-align: middle; line-height:16px;">
              Please enter a valid url.
            </div>
          </div>
        </div>
        <button *ngIf="hasWriteAccess" class="btn btn-outline-danger" [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS"
                (click)="removeSocialField(i)" style="max-width: 40px; max-height: 35px;">
          <i class="fas fa-trash-alt"></i>
        </button>

      </div>
    </div>

  </div>


</div>

<!-- Multi language -->

<div style="max-width: 100%" *ngIf="product && isMultiLanguageContent">
  <div class="bx--row" style="margin: 40px 0px 24px 0px">
    <div class="bx--col p-0 d-flex">
      <beaconstac-toggle #switchMultiLanguage
                         id="multi-language-content"
                         [disabled]="!hasWriteAccess"
                         (change)="onMultiLanguageContentSwitchChange()"
                         [checked]="isMultiLanguageContent"
      ></beaconstac-toggle>
      <label class="form-control-label mr-1" style="font-size: 15px" id="multi-language-support-content">Multi-language
        support</label>
    </div>
  </div>
  <div class="bx--row">
    <div class="bx--col">
      <button class="link-button mb-2" (click)="languageModal.show()"><i class="fas fa-plus"> </i> Add language / locale
      </button>
    </div>
  </div>

  <div class="bx--row">
    <div class="mt-3 bx--col"
         style="display: flex; flex-direction: row; flex-wrap: wrap; margin-bottom: 32px; row-gap: 10px;">
      <div *ngFor="let language of multiLangList; let i = index" (click)="selectLanguage(language)"
           style="cursor: pointer" [attr.id]="'language-' + i">
        <div class="d-flex flex-row justify-content-center align-items-center mr-3"
             [ngClass]="language === currentLanguage? 'selectedLanguage' : 'notSelectedLanguage'"
             *ngIf="product.default_language === language">
          <span>{{ languageNames[language] }} (default)</span>
          <div style="margin-left: 1em" class="dropdown">
            <button *ngIf="multiLangList.length > 1 " style="background-color: transparent; border: none"
                    data-toggle="dropdown">
              <i class="fas fa-ellipsis-v" style="color: #52565b;"></i>
            </button>
            <div class="dropdown-menu multiLang-dropdown" style="width: 167px;right: 0px;left: auto;"
                 aria-labelledby="dropdownMenuButton">
              <div *ngIf="hasWriteAccess" class="place-delete"
                   [ngClass]="multiLangList.length === 1 ? 'disableDiv' : ''"
                   (click)="onMultiLanguageContentLanguageRemoved(language)">
                Delete
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center mr-3"
             [ngClass]="language === currentLanguage? 'selectedLanguage' : 'notSelectedLanguage'"
             *ngIf="product.default_language !== language">
          <span>{{ languageNames[language] }}</span>
          <div style="margin-left: 1em" class="dropdown">
            <button *ngIf="multiLangList.length > 1" style="background-color: transparent; border: none"
                    data-toggle="dropdown">
              <i class="fas fa-ellipsis-v" style="color: #52565b;"></i>
            </button>
            <div class="dropdown-menu multiLang-dropdown" style="width: 167px;right: 0px;left: auto;"
                 aria-labelledby="dropdownMenuButton">
              <div (click)="makeDefaultLanguage(language)">
                Make default
              </div>
              <div *ngIf="hasWriteAccess" class="place-delete"
                   (click)="onMultiLanguageContentLanguageRemoved(language)">
                Delete
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="bg-white mt-4 box-border">
    <div class="d-flex justify-content-between py-3 px-4"
         (click)="updateCollapseMultilanguage(COLLAPSE.PERSONAL_INFO)">
      <div class="block-title">PERSONAL INFO</div>
      <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                         [icon]="collapseSection.personalInfo ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                         [style]="BUTTON_STYLES.TEXT">
      </beaconstac-button>
    </div>

    <hr *ngIf="!collapseMultilanguage.personalInfo" class="block-border-bottom">

    <div class="px-4 pb-4 pt-3" *ngIf="!collapseMultilanguage.personalInfo" @collapse>
      <div class="bx--row">
        <div class="bx--col-lg-8">
          <beaconstac-textfield name="Prefix" [label]="true"
                                [placeholder]="''"
                                [title]="'Prefix'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.language_data[currentLanguage].prefix"
                                [required]="false"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PREFIX"
                                (input)="onVCardContentChange()"
                                [labelIcon]="cardSetting.prefix | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PREFIX: cardSetting: orgCardSetting"
                                [maxWidth]="'300px'"
          ></beaconstac-textfield>
        </div>
        <div class="bx--col-lg-8" id="first_name_ml" [ngClass]="isMobileScreen ? 'mt-2' : ''">
          <beaconstac-textfield name="First_name" [label]="true"
                                [placeholder]="''"
                                [title]="'First name*'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.language_data[currentLanguage].first_name"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.FIRST_NAME"
                                [errorMessage]="product.language_data[currentLanguage].first_name > 255 ? 'Name cannot be more than 255 characters' : 'First name is required'"
                                (textChange)="firstNameValidationMultiLang($event)"
                                [maxWidth]="'300px'"
                                [valid]="validationsMultiLang[currentLanguage].first_name_ml ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR"
                                [labelIcon]="cardSetting.first_name | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.FIRST_NAME: cardSetting: orgCardSetting"
          ></beaconstac-textfield>
        </div>
      </div>

      <div class="bx--row pt-2">
        <div class="bx--col-lg-8">
          <beaconstac-textfield name="Last_name" [label]="true"
                                [placeholder]="''"
                                [title]="'Last name'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.language_data[currentLanguage].last_name"
                                [required]="false"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LAST_NAME"
                                (input)="onVCardContentChange()"
                                [labelIcon]="cardSetting.last_name | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LAST_NAME: cardSetting: orgCardSetting"
                                [maxWidth]="'300px'"
          ></beaconstac-textfield>
        </div>
        <div class="bx--col-lg-8" [ngClass]="isMobileScreen ? 'mt-2' : ''">
          <beaconstac-textfield name="Suffix" [label]="true"
                                [placeholder]="''"
                                [title]="'Suffix'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.language_data[currentLanguage].suffix"
                                [required]="false"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SUFFIX"
                                (input)="onVCardContentChange()"
                                [labelIcon]="cardSetting.suffix | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SUFFIX: cardSetting: orgCardSetting"
                                [maxWidth]="'300px'"
          ></beaconstac-textfield>
        </div>
      </div>
      <div class="pt-2">
        <beaconstac-textfield name="Pronouns" [label]="true"
                              [placeholder]="''"
                              [title]="'Pronouns'"
                              [type]="TEXT_FIELD_TYPES.TEXT"
                              [(text)]="product.language_data[currentLanguage].pronouns_v2"
                              [required]="false"
                              (input)="onVCardContentChange()"
                              [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PRONOUNS"
                              [maxWidth]="'300px'"
                              [labelIcon]="cardSetting.pronouns | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PRONOUNS: cardSetting: orgCardSetting"
        ></beaconstac-textfield>
      </div>
    </div>
  </div>

  <!-- multi language company details start -->
  <div class="bg-white mt-4 box-border">
    <div class="d-flex justify-content-between py-3 px-4"
         (click)="updateCollapseMultilanguage(COLLAPSE.COMPANY_DETAILS)">
      <div class="block-title">COMPANY DETAILS</div>
      <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                         [icon]="collapseMultilanguage.companyDetails ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                         [style]="BUTTON_STYLES.TEXT">
      </beaconstac-button>
    </div>

    <hr class="block-border-bottom" *ngIf="!collapseMultilanguage.companyDetails">

    <div class="px-4 pb-4 pt-3" *ngIf="!collapseMultilanguage.companyDetails" @collapse>
      <beaconstac-textfield name="Company" [label]="true"
                              [placeholder]="''"
                              [title]="'Company name'"
                              [type]="TEXT_FIELD_TYPES.TEXT"
                              [(text)]="product.language_data[currentLanguage].company"
                              [required]="false"
                              (input)="onVCardContentChange()"
                              [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.COMPANY"
                              [maxWidth]="'300px'"
                              [labelIcon]="cardSetting.company | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.COMPANY: cardSetting: orgCardSetting"
        ></beaconstac-textfield>

      <div class="bx--row pt-2">
        <div class="bx--col">
          <beaconstac-textfield name="Department" [label]="true"
                                [placeholder]="''"
                                [title]="'Department'"
                                [type]="TEXT_FIELD_TYPES.TEXT"
                                [(text)]="product.language_data[currentLanguage].department"
                                [required]="false"
                                (input)="onVCardContentChange()"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.DEPARTMENT"
                                [maxWidth]="'300px'"
                                [labelIcon]="cardSetting.department | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.DEPARTMENT: cardSetting: orgCardSetting"
          ></beaconstac-textfield>
        </div>
      </div>
      <div class="bx--row pt-2">
        <div class="bx--col" [ngClass]="isMobileScreen ? 'mt-2' : ''">
          <beaconstac-textfield name="Designation" [label]="true"
                                [placeholder]="''"
                                [title]="'Job title'"
                                [type]="TEXT_FIELD_TYPES.TEXTAREA"
                                [(text)]="product.language_data[currentLanguage].designation"
                                [required]="false"
                                (input)="onVCardContentChange()"
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.DESIGNATION"
                                [maxWidth]="'300px'"
                                [labelIcon]="cardSetting.designation | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.DESIGNATION: cardSetting: orgCardSetting"
          ></beaconstac-textfield>
        </div>

      </div>

      <div class="pt-2">
        <beaconstac-textfield name="Summary" [label]="true"
                              [placeholder]="''"
                              [title]="'Bio'"
                              [type]="TEXT_FIELD_TYPES.TEXTAREA"
                              [(text)]="product.language_data[currentLanguage].summary"
                              [required]="false"
                              (input)="onVCardContentChange()"
                              [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SUMMARY"
                              [maxWidth]="'100%'"
                              [labelIcon]="cardSetting.summary | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SUMMARY: cardSetting: orgCardSetting"
        ></beaconstac-textfield>
      </div>

      <div class="company-logo py-2">
        <div class="beaconstac-label">Company logo
          <span
            [ngClass]="cardSetting.logo_url | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LOGO_URL: cardSetting: orgCardSetting"
            (click)="handleIconClick(CARD_PROPERTY_TYPES.LOGO_URL)">
                  </span>
        </div>
        <beaconstac-upload #logoDropVCard
                           [width]="'webkit-fill-available'"
                           (fileChange)="onMultiLangDBCLogoAdded($event)" [isUploading]="isIconUploading"
                           [acceptTypes]="['image/png', 'image/jpeg', 'image/svg+xml']" [maxSize]="5e+7"
                           [height]="'auto'"
                           [preview]="product.language_data[currentLanguage].logo_url" [overrideClick]="hasWriteAccess"
                           (onClick)="updateLogo(IMAGE_TYPE.DBCLogo)"
                           [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LOGO_URL"
                           [showRemoveButton]="true"
                           (onFileRemove)="onMultiLangDBCLogoRemoved()">
        </beaconstac-upload>
      </div>

      <div class="location pt-4">

        <div class=" beaconstac-label">Location
          <span
            [ngClass]="cardSetting.address | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS: cardSetting: orgCardSetting"
            (click)="handleIconClick(CARD_PROPERTY_TYPES.ADDRESS)"></span>
        </div>
        <div class="d-flex">
          <input placeholder="Search Google Maps"
                 style="max-width: 100%; font-family:Arial, FontAwesome"
                 [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS"
                 [attr.disabled]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS) ? 'disabled' : null"
                 class="form-control mb-4 fa search-box" type="text" id="vcard-address-multi-lang"
                 name="vcard-address-multi-lang" #searchMultiLang
                 [formControl]="searchControlMultiLang[currentLanguage]"
                 (click)="initializeAutoCompleteMultiLang(currentLanguage)">
          <beaconstac-button *ngIf="product.language_data[currentLanguage].address_url" [label]="''"
                             [type]="BUTTON_TYPES.DANGER" [style]="BUTTON_STYLES.TEXT"
                             (onClick)="removeLocationMultiLang()"
                             [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS"
                             [icon]="'ml-2 mt-1 fa fa-times'"></beaconstac-button>
        </div>
        <div style="max-width: 100%; border: solid thin #888;">
          <app-map class="agm-map-container" [center]="centerMultiLang[currentLanguage]"
                   [markerOptions]="markerOptions"
                   [locationData]="locationDataMultiLang[currentLanguage]"
                   [addMarkerCluster]="addMarkerCluster"
                   [zoomValue]="mapZoomValue"
                   (onMapDragEnd)="(hasWriteAccess && !cardSetting.address) ? setVCardLocationURLMultiLang($event) : null"
                   (onMapClick)="(hasWriteAccess && !cardSetting.address)? setVCardLocationURLMultiLang($event): null">

          </app-map>
        </div>

      </div>

      <div class="pt-2">
        <beaconstac-textfield name="Address" [label]="true"
                              [placeholder]="'Enter address or search on Google Maps above'"
                              [title]="'Address'"
                              [type]="TEXT_FIELD_TYPES.TEXTAREA"
                              [(text)]="product.language_data[currentLanguage].address_v2"
                              [required]="false"
                              (input)="onVCardContentChange()"
                              [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS"
                              [maxWidth]="'698px'"
                              [labelIcon]="cardSetting.address | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.ADDRESS: cardSetting: orgCardSetting"
                              (labelIconClick)="handleIconClick(CARD_PROPERTY_TYPES.ADDRESS)"
        ></beaconstac-textfield>
      </div>

    </div>

  </div>

  <!-- multi language contact details -->
  <div class="bg-white mt-4 box-border">
    <div class="d-flex justify-content-between py-3 px-4"
         (click)="updateCollapseMultilanguage(COLLAPSE.CONTACT_DETAILS)">
      <div class="block-title">CONTACT DETAILS</div>
      <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                         [icon]="collapseMultilanguage.contactDetails ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                         [style]="BUTTON_STYLES.TEXT">
      </beaconstac-button>
    </div>

    <hr *ngIf="!collapseMultilanguage.contactDetails" class="block-border-bottom">

    <div *ngIf="!collapseMultilanguage.contactDetails" cdkDropList (cdkDropListDropped)="updateContactDetailsOrderingForMultiLang($event)" [cdkDropListDisabled]="dragdropState" @collapse>

        <div *ngFor="let contact of product.language_data[currentLanguage].__contact_info_list__; let i = index" [cdkDragLockAxis]="'y'" cdkDrag>
            <div [ngSwitch]="contact.key">
                <ng-container *ngSwitchCase="'phone_v2'">
                    <!-- Phone details -->
                    <div class="phone-ml desktop-screen-border" [ngClass]="!isMobileScreen ? 'm-4' : 'm-3'">
                        <div class="d-flex bg-white" [ngClass]="!isMobileScreen ? 'p-3' : 'p-2'" style="border-radius:8px">
                            <img class="group-icon pr-3 cursor-pointer" src="../../../../assets/img/group-icon.png" alt="group_icon" height="27" [ngClass]="isMobileScreen ? 'pr-2' : 'pr-3'">
                            <div class="phone-ml-group w-100">
                                <div class="mb-2 beaconstac-label" id="phone_v2_ml">Phone
                                    <span
                                        [ngClass]="cardSetting.phone | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PHONE: cardSetting: orgCardSetting"
                                        (click)="handleIconClick(CARD_PROPERTY_TYPES.PHONE)"></span>
                                </div>
                                <beaconstac-input-group [childComponent]="multiLanguagePhoneCompo"></beaconstac-input-group>
                                <ng-template #multiLanguagePhoneCompo>
                                    <div class="d-flex flex-nowrap mb-2" style="border: 1px solid #DBDFE3;"
                                         *ngFor="let phone of this.product.language_data[currentLanguage].phone_v2 ; let i = index">

                                        <beaconstac-filter-dropdown
                                            [ngStyle]="{'flex':'3'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            title="contact-section-phone-{{i}}"
                                            [value]="{'name' : phone.label , 'value' : phone.label }"
                                            placeholder="Select Label"
                                            [listItems]="labelsForPhone"
                                            optionLabelField="name"
                                            optionValueField="value"
                                            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PHONE"
                                            (valueChange)="setLabelMultiLang('phone',$event,i);"
                                        ></beaconstac-filter-dropdown>
                                        <beaconstac-textfield
                                            [ngStyle]="{'flex':'6'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            type="text"
                                            [(text)]="phone.value"
                                            placeholder="Phone number"
                                            [changeButtonVisible]="false"
                                            maxWidth="300px"
                                            (textChange)="onVCardContentChange()"
                                            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PHONE"
                                        ></beaconstac-textfield>
                                        <beaconstac-button
                                            [ngStyle]="{'flex':'1'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            [style]="BUTTON_STYLES.STROKE"
                                            type="danger"
                                            [disabled]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PHONE ) || this.product.language_data[currentLanguage].phone_v2.length <= 1"
                                            label=""
                                            icon="fas fa-trash-alt"
                                            iconPosition="left"
                                            (onClick)="removeFieldMultiLang('phone',i)"
                                        ></beaconstac-button>
                                    </div>
                                </ng-template>

                                <div class="mt-2 mt-lg-0">
                                    <beaconstac-button
                                        [style]="BUTTON_STYLES.TEXT"
                                        type="primary"
                                        [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PHONE"
                                        label="Add Phone Number"
                                        icon="fa fa-plus"
                                        [iconSize]="'12px'"
                                        iconPosition="left"
                                        (onClick)="addNewFieldMultiLang('phone')"
                                    ></beaconstac-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'email_v2'">
                    <!-- Email -->
                    <div class="email-ml desktop-screen-border" [ngClass]="!isMobileScreen ? 'm-4' : 'm-3'">
                        <div class="d-flex bg-white" [ngClass]="!isMobileScreen ? 'p-3' : 'p-2'" style="border-radius:8px">
                            <img class="group-icon pr-3 cursor-pointer" src="../../../../assets/img/group-icon.png" alt="group_icon" height="27" [ngClass]="isMobileScreen ? 'pr-2' : 'pr-3'">

                            <div class="phone_group_ml w-100">

                                <div class="mb-2 beaconstac-label" id="email_v2_ml">Email
                                    <span
                                        [ngClass]="cardSetting.email | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.EMAIL: cardSetting: orgCardSetting"
                                        (click)="handleIconClick(CARD_PROPERTY_TYPES.EMAIL)"></span>
                                </div>

                                <beaconstac-input-group [childComponent]="multiLanguageEmailCompo">
                                </beaconstac-input-group>

                                <ng-template #multiLanguageEmailCompo>
                                    <div class="d-flex flex-nowrap mb-2" style="border: 1px solid #DBDFE3;"
                                         *ngFor="let email of this.product.language_data[currentLanguage].email_v2 ; let i = index">

                                        <beaconstac-filter-dropdown
                                            [ngStyle]="{'flex':'3'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            [value]="{'name' : email.label , 'value' : email.label }"
                                            title="contact-section-email-{{i}}"
                                            placeholder="Select Label"
                                            [listItems]="labelsForEmail"
                                            optionLabelField="name"
                                            optionValueField="value"
                                            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.EMAIL"
                                            (valueChange)="setLabelMultiLang('email',$event,i);"
                                        ></beaconstac-filter-dropdown>
                                        <beaconstac-textfield
                                            [ngStyle]="{'flex':'6'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            type="text"
                                            [(text)]="email.value"
                                            placeholder="Email"
                                            [changeButtonVisible]="false"
                                            maxWidth="300px"
                                            (textChange)="inputChangeMultiLang('email',$event,i)"
                                            [valid]="email.valid"
                                            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.EMAIL"
                                            [errorMessage]="'Please enter a valid email address.'"
                                        ></beaconstac-textfield>
                                        <beaconstac-button
                                            [ngStyle]="{'flex':'1'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            [style]="BUTTON_STYLES.STROKE"
                                            type="danger"
                                            [disabled]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.EMAIL ) || this.product.language_data[currentLanguage].email_v2.length <= 1"
                                            label=""
                                            icon="fas fa-trash-alt"
                                            iconPosition="left"
                                            (onClick)="removeFieldMultiLang('email',i)"
                                        ></beaconstac-button>
                                    </div>

                                </ng-template>

                                <beaconstac-button
                                    [style]="BUTTON_STYLES.TEXT"
                                    type="primary"
                                    [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.EMAIL"
                                    label="Add Email"
                                    icon="fa fa-plus"
                                    [iconSize]="'12px'"
                                    iconPosition="left"
                                    (onClick)="addNewFieldMultiLang('email')"
                                ></beaconstac-button>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'website_v2'">
                    <!-- website -->
                    <div class="website-ml desktop-screen-border" [ngClass]="!isMobileScreen ? 'm-4' : 'm-3'">
                        <div class="d-flex bg-white" [ngClass]="!isMobileScreen ? 'p-3' : 'p-2'" style="border-radius:8px">
                            <img class="group-icon pr-3 cursor-pointer" src="../../../../assets/img/group-icon.png" alt="group_icon" height="27" [ngClass]="isMobileScreen ? 'pr-2' : 'pr-3'">

                            <div class="phone_group w-100">
                                <div class="mb-2 beaconstac-label" id="website_v2_ml">Website
                                    <span
                                        [ngClass]="cardSetting.website | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.WEBSITE: cardSetting: orgCardSetting"
                                        (click)="handleIconClick(CARD_PROPERTY_TYPES.WEBSITE)"></span>
                                </div>

                                <beaconstac-input-group [childComponent]="multiLanguageWebsiteCompo"></beaconstac-input-group>
                                <ng-template #multiLanguageWebsiteCompo>
                                    <div class="d-flex flex-nowrap mb-2" style="border: 1px solid #DBDFE3;"
                                         *ngFor="let website of this.product.language_data[currentLanguage].website_v2 ; let i = index">
                                        <beaconstac-filter-dropdown
                                            [ngStyle]="{'flex':'3'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            [value]="{'name' : website.label , 'value' : website.label }"
                                            title="contact-section-website-{{i}}"
                                            placeholder="Select Label"
                                            [listItems]="labelsForWebsite"
                                            optionLabelField="name"
                                            optionValueField="value"
                                            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.WEBSITE"
                                            (valueChange)="setLabelMultiLang('website',$event,i);"
                                        ></beaconstac-filter-dropdown>
                                        <beaconstac-textfield
                                            [ngStyle]="{'flex':'6'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            [(text)]="website.value"
                                            placeholder="Website link"
                                            [changeButtonVisible]="false"
                                            (textChange)="inputChangeMultiLang('website',$event,i)"
                                            [valid]="website.valid"
                                            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.WEBSITE"
                                            [errorMessage]="'Please enter a valid URL'"
                                        ></beaconstac-textfield>
                                        <beaconstac-button
                                            [ngStyle]="{'flex':'1'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            [style]="BUTTON_STYLES.STROKE"
                                            type="danger"
                                            [disabled]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.PHONE ) || this.product.language_data[currentLanguage].website_v2.length <= 1"
                                            label=""
                                            icon="fas fa-trash-alt"
                                            iconPosition="left"
                                            (onClick)="removeFieldMultiLang('website',i)"
                                        ></beaconstac-button>
                                    </div>
                                </ng-template>

                                <beaconstac-button
                                    [style]="BUTTON_STYLES.TEXT"
                                    type="primary"
                                    [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.WEBSITE"
                                    label="Add Website"
                                    icon="fa fa-plus"
                                    [iconSize]="'12px'"
                                    iconPosition="left"
                                    (onClick)="addNewFieldMultiLang('website')"
                                ></beaconstac-button>
                            </div>
                        </div>

                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'custom_fields'">
                    <div class="custom-field-ml desktop-screen-border" [ngClass]="!isMobileScreen ? 'm-4' : 'm-3'">
                        <div class="d-flex bg-white" [ngClass]="!isMobileScreen ? 'p-3' : 'p-2'" style="border-radius:8px">

                            <img class="group-icon pr-3 cursor-pointer" src="../../../../assets/img/group-icon.png" alt="group_icon" height="27" [ngClass]="isMobileScreen ? 'pr-2' : 'pr-3'">

                            <div class="phone_group w-100">
                                <div class="mb-2 beaconstac-label">Custom field
                                    <span
                                        [ngClass]="cardSetting.custom_fields | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOM_FIELDS: cardSetting: orgCardSetting"
                                        (click)="handleIconClick(CARD_PROPERTY_TYPES.CUSTOM_FIELDS)"></span>
                                </div>

                                <beaconstac-input-group [childComponent]="multiLanguageCustomField"></beaconstac-input-group>

                                <ng-template #multiLanguageCustomField>
                                    <div class="d-flex flex-nowrap mb-2" style="border: 1px solid #DBDFE3;"
                                         *ngFor="let custom_field of this.product.language_data[currentLanguage].custom_fields ; let i = index">

                                        <beaconstac-textfield
                                            [ngStyle]="{'flex':'3'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            [(text)]="custom_field.label"
                                            placeholder="Label"
                                            [changeButtonVisible]="false"
                                            maxWidth="300px"
                                            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOM_FIELDS"
                                            (textChange)="inputChangeMultiLang('custom_fields',$event,i)"
                                        ></beaconstac-textfield>

                                        <beaconstac-textfield
                                            [ngStyle]="{'flex':'6'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            [(text)]="custom_field.value"
                                            placeholder="Value"
                                            [changeButtonVisible]="false"
                                            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOM_FIELDS"
                                            (textChange)="inputChangeMultiLang('custom_fields',$event,i)"
                                        ></beaconstac-textfield>

                                        <beaconstac-button
                                            [ngStyle]="{'flex':'1'}"
                                            [isInputGroup]="true"
                                            [class]="'input-group-selector'"
                                            [style]="BUTTON_STYLES.STROKE"
                                            type="danger"
                                            [disabled]="(hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOM_FIELDS ) || this.product.language_data[currentLanguage].custom_fields.length <= 1"
                                            label=""
                                            icon="fas fa-trash-alt"
                                            iconPosition="left"
                                            (onClick)="removeFieldMultiLang('custom_fields', i)"
                                        ></beaconstac-button>
                                    </div>
                                </ng-template>

                                <beaconstac-button
                                    [style]="BUTTON_STYLES.TEXT"
                                    type="primary"
                                    [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOM_FIELDS"
                                    label="Add Custom Field"
                                    icon="fa fa-plus"
                                    [iconSize]="'12px'"
                                    iconPosition="left"
                                    (onClick)="addNewFieldMultiLang('custom_fields')"
                                ></beaconstac-button>
                            </div>

                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

    </div>

  </div>

  <!-- multi language social Links -->

  <div class="bg-white mt-4 box-border">
    <div class="d-flex justify-content-between py-3 px-4" (click)="updateCollapseMultilanguage(COLLAPSE.SOCIAL_LINKS)">
        <div class="block-title">SOCIAL LINKS
          <span [ngClass]="cardSetting.social_links | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS: cardSetting: orgCardSetting" (click)="handleIconClick(CARD_PROPERTY_TYPES.SOCIAL_LINKS)"></span>
        </div>
      <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                         [icon]="collapseMultilanguage.socialLinks ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                         [style]="BUTTON_STYLES.TEXT">
      </beaconstac-button>
    </div>

    <hr class="block-border-bottom" *ngIf="!collapseMultilanguage.socialLinks">

    <div class="px-4 pb-4 pt-3" *ngIf="!collapseMultilanguage.socialLinks" @collapse>
      <div *ngIf="!isMobileScreen">
          <ng-container *ngIf="!showSvgSocialIcons">
            <button *ngFor="let media of socialMediaMetaData; let i = index;" type="button" class="btn"
                    (click)="addSocialFieldMultiLang(media.id)"
                    [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS"
                    data-toggle="tooltip"
                    style="margin-right: 10px; margin-bottom: 14px; min-height: 50px; background-size:contain;
                            width: 50px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-color: transparent;"
                    [style.border]="isMediaSelectedMultiLang(media.id) ? '2px solid #2595FF' : '0.5px solid #e7ecec'"
                    data-placement="right"
                    [style.background-image]="'url('+media.logo+')'"
                    container="body" title="{{media.title}}"></button>
          </ng-container>

          <ng-container *ngIf="showSvgSocialIcons">
              <button *ngFor="let media of socialMediaMetaData; let i = index;" class="social-icons-btn"
                      (click)="addSocialFieldMultiLang(media.id)"
                      [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS"
              >
                  <i *ngIf="media['font_aweomse_class']" [ngClass]="media['font_aweomse_class']" style="color: white;"></i>
                  <div *ngIf="!media['font_aweomse_class']" class="social-icon-image d-flex justify-content-center" [innerHtml]="media | getSocialIconSvg">
                  </div>
              </button>
          </ng-container>
      </div>

      <div *ngIf="isMobileScreen" class="social-icons-container mb-3">
        <div class="form-group has-search mb-0">
          <span class="fas fa-search form-control-feedback"></span>
          <input type="text" id="search-input" placeholder="Search" [value]="searchedIconInput" (input)="onIconSearch($event)" class="form-control">
        </div>
        <div *ngIf="socialMediaMetaMobileList.length" class="list-container mt-3">
          <div class="icon-list-item" *ngFor="let icon of socialMediaMetaMobileList; let i = index" >
              <ng-container *ngIf="!showSvgSocialIcons">
                  <button class="cursor-pointer"
                    style="margin-right: 10px; margin-bottom: 14px; min-height: 50px; background-size:contain;
                        width: 50px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-color: transparent;"
                    [style.border]="isMediaSelectedMultiLang(icon.id) ? '2px solid #2595FF' : '0.5px solid #e7ecec'" (click)="addSocialFieldMultiLang(icon.id)"
                    [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS">
                      <img class="icon-image" src="{{icon.logo}}">
                    </button>
              </ng-container>

              <ng-container *ngIf="showSvgSocialIcons">
                  <button class="social-icons-btn"
                          (click)="addSocialFieldMultiLang(icon.id)"
                          [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS"
                  >
                      <i *ngIf="icon['font_aweomse_class']" [ngClass]="icon['font_aweomse_class']" style="color: white;"></i>
                      <div *ngIf="!icon['font_aweomse_class']" class="social-icon-image d-flex justify-content-center" [innerHtml]="icon | getSocialIconSvg">
                      </div>
                  </button>
              </ng-container>
          </div>
        </div>
        <div *ngIf="!socialMediaMetaMobileList.length" class="mt-2">
          <div class="text-center w-100 d-flex flex-column social-icon-search">
            <span>Uh-oh! No results found</span>
            <span *ngIf="!socialMediaMetaMobileList.length && showCustomIcon">Add a custom social media link</span>
          </div>
        </div>
        <div *ngIf="!socialMediaMetaMobileList.length && showCustomIcon" class="mt-3">
          <div class="icon-list-item">
              <div *ngIf="!showSvgSocialIcons" class="cursor-pointer" style="width: 50px; height:50px" (click)="addSocialField(24)">
                  <img class="icon-image" src="../../../assets/img/vcard-plus-social-icons/URL.png">
              </div>
              <div *ngIf="showSvgSocialIcons" class="ml-4 social-icons-box d-flex justify-content-center align-items-center"
                   (click)="addSocialFieldMultiLang(24)"
                    [innerHtml]="socialMediaMetaData[23] | getSocialIconSvg"
              ></div>
          </div>
        </div>
      </div>

      <div *ngFor="let field of socialFieldDetailsMultiLang[currentLanguage]; let i = index" class="d-flex my-2 social-icon-input" style="column-gap: 12px;">
        <div class="d-flex align-items-center" style="max-width: 42px; width:100%; height:36px">
            <img *ngIf="!showSvgSocialIcons" class="icon-image" src="{{field.image}}">
            <div *ngIf="showSvgSocialIcons" class="social-icons-box d-flex justify-content-center align-items-center">
                <i *ngIf="field['font_aweomse_class']" [ngClass]="field['font_aweomse_class']" style="color: white;"></i>
                <div *ngIf="!field['font_aweomse_class']" class="social-icon-image d-flex justify-content-center" [innerHtml]="field | getSocialIconSvg"></div>
            </div>
        </div>
        <div class="d-flex social-icon-label" style="max-width: 200px; max-height:50px; min-width: 100px">
          <h5 style="font-weight: bold; font-size:15px" class="pt-2">{{field.title}}</h5>
        </div>
        <div class="social-url-input" style="max-width: 300px; max-height: fit-content;">
          <input id="vcardPlusForm-social" name="vcardPlusForm-social" #vcardPlusFormSocial="ngModel" class="form-control"
                 (input)="onVCardContentChange(); onChangeSocialLinkMultiLang($event, field.pos);"
                 [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS"
                 placeholder="{{field.placeholder}}"
                 [(ngModel)]="field.model" required>
          <div *ngIf="vcardPlusFormSocial.invalid && (vcardPlusFormSocial.dirty || vcardPlusFormSocial.touched)"
               class="alert alert-danger px-1">

            <div *ngIf="vcardPlusFormSocial.errors.required && field.title==='Whatsapp'"
                 style="vertical-align: middle; line-height:16px;">
              Please enter a valid phone number.
            </div>
            <div *ngIf="vcardPlusFormSocial.errors.required && field.title!=='Whatsapp'"
                 style="vertical-align: middle; line-height:16px;">
              Please enter a valid url.
            </div>
          </div>
          <div
            *ngIf="(!(vcardPlusFormSocial.invalid && (vcardPlusFormSocial.dirty || vcardPlusFormSocial.touched)) && !(field.model | isLinkValid) && (field.title!=='Whatsapp'))"
            class="alert alert-danger px-1">
            <div *ngIf="!(vcardPlusFormSocial.value | isLinkValid)" style="vertical-align: middle; line-height:16px;">
              Please enter a valid url.
            </div>
          </div>
        </div>
        <button *ngIf="hasWriteAccess" class="btn btn-outline-danger" [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.SOCIAL_LINKS"
                (click)="removeSocialFieldMultiLang(i)" style="max-width: 40px; max-height: 35px;">
          <i class="fas fa-trash-alt"></i>
        </button>

      </div>
    </div>



  </div>

</div>


<!--Extras-->
<div bsModal #imageSelectModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}"
     (onShow)="setupImageSelectModal()" (onHide)="imageSelectGallery.onHide(); isLogoUploading = false">
  <div class="modal-dialog modal-info modal-lg" role="document">
    <div class="modal-content modal-content-kz">

      <div class="modal-header-kz">
        <h4 class="modal-title">Select {{ isLogoUploading ? 'a Logo' : 'an image' }}</h4>
      </div>

      <div class="modal-body modal-body-kz">
        <div class="form-group">
          <app-media-gallery #imageSelectGallery (fileUploaded)="loadCropper($event)"
                             [id]="'imageSelectGallerySetupStep'"
                             [dbcId]="product?.id" [newCard]="newVcard"
                             [imageSelectModel]="imageSelectModel"
                             (mediaSelector)="imageSelectedInGallery($event)" [addMedia]="updatedMedia"
                             [dimensions]="imageSelectModel.type === IMAGE_TYPE.DBCLogo ? '192x192' : '1024x1024'"
                             [isLogo]="imageSelectModel.type === IMAGE_TYPE.DBCLogo"
                             (uploadToFolderId)="setUploadToFolder($event)"
                             [searchParams]="{'content_type__nin':'image/gif'}"
                             [acceptedTypes]="['image/jpeg', 'image/png', 'image/svg+xml']"
                             [isFooterVisible]="true"
                             [maxSize]="5e+7"
                             [isSelectDisabled]="!imageSelectModel.media"
                             (onSelect)="selectImage()"
                             (onClose)="imageSelectModal.hide()"></app-media-gallery>
        </div>
      </div>
    </div>
  </div>
</div>

<app-image-cropper #imageCropper *ngIf="imageCropperFile" [imageFile]="imageCropperFile"
                   (cropperClose)="closeImageCropperModal()" [squareDragMode]="true" [folderId]="uploadToFolder"
                   (imageUpdated)="updateMedia($event)"
                   [imageAspectRatio]="imageSelectModel.type === IMAGE_TYPE.DBCLogo?'': '1'"
                   [showUploadOriginal]="imageSelectModel.type === IMAGE_TYPE.DBCLogo"></app-image-cropper>

<div bsModal #imageUpdateModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}"
     *ngIf="product">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="width: 400px; height: 340px;">

      <div class="modal-header d-flex flex-column">
        <div>
          <h4 class="modal-title">Profile Pic</h4>
        </div>
        <div
          style="height: 168px; width: 168px; align-content: center; position: relative; margin-left: 28%; margin-top: 24px; margin-bottom: 5px;"
          class="img-avatar profile-name-holder">
          <span>
            <img style="border-radius: 50%; width:100%; height:100%; object-fit: cover;" [src]="this.isMultiLanguageContent ? (product.language_data[currentLanguage].user_image_url? product.language_data[currentLanguage].user_image_url: environment.imagesEndpoint + 'vcard_plus/default_vcard_image.png'):
            (product.user_image_url? product.user_image_url: environment.imagesEndpoint + 'vcard_plus/default_vcard_image.png')">
          </span>
        </div>
      </div>

      <div class="modal-body">
        <div class="mt-2">
          <span class="cursor-pointer" style="color: #5E6980;" (click)="closeProfileModal()">Close</span>
          <span style="float: right; display: flex; flex-direction: row; margin-top: -6px;">
            <span class="pt-0">
              <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" (onClick)="onVcardPlusUserImageRemoved()"
                                 [label]="'Remove'"
                                 [disabled]="isMultiLanguageContent? (product.language_data[currentLanguage].user_image_url? false: true): (product.user_image_url? false: true)"></beaconstac-button>
            </span>
            <span class="pt-0" style="padding-left: 16px">
              <beaconstac-button (onClick)="changeProfilePic()" [label]="'Change'"></beaconstac-button>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Multi Language Support Extras-->
<beaconstac-notification-language #languageModal
                                  [noTitle]="true"
                                  (onLanguageAdded)="onMultiLanguageContentLanguageAdded($event)"></beaconstac-notification-language>

