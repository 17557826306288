import {BaseModel} from '../global-services/base.model';
import {DbcPermissions, Permissions} from './organization-permission-model';
import {AccountLimits} from './organization-account-limits-model';

export interface ReducedOrganization {
    id: number,
    name: string,
    master: boolean,
}

export enum FORM_SERVICE_CHOICES {
    FORM_SERVICE_TYPEFORM = 1,
    FORM_SERVICE_BFORM = 2,
}

export enum DBC_ORG_PERMISSION_OPTIONS {
    OPTIONAL= 'optional',
    YES= 'true',
    NO= 'false'
}

export interface WalletPassCustomizations {
    enforce_wallet_pass_template: boolean,
    apple_wallet_pass_background: string,
    apple_wallet_pass_foreground: string,
    apple_wallet_pass_logo: string,
    apple_wallet_pass_logo_id: string,
    google_wallet_pass_background: string
    google_wallet_pass_logo: string,
    google_wallet_pass_hero_image: string,
    google_wallet_pass_logo_id: number,
    google_wallet_pass_hero_image_id: number
}

export class Organization extends BaseModel {

    id: number;
    name: string;
    created: string;
    reseller_access: boolean;
    whitelabel_access: boolean;
    whitelabel_logo: string;
    whitelabel_logo_small: string;
    cname: string;
    ga_code: string;
    fb_pixel_id: string;
    fb_pixel_event: string;
    google_conversion_id: string;
    meta: object;
    physical_web_active: boolean;
    wallet_active: boolean;
    custom_domain: string;
    default_domain: object;
    feature_permissions: Permissions;
    dbc_feature_permissions: DbcPermissions;
    account_limits: AccountLimits;
    brand_logos: Array<string>;
    parent: number;
    domains: Array<number> = [];
    sso_connection: any = {};
    enforce_qr_templates: boolean;
    copy_qr_templates: boolean;
    copy_logos: boolean;
    email_wallet_pass: boolean;
    org_user_group: string;
    allow_analytics_export: boolean;
    check_response_limits: boolean;
    wallet_pass_template: WalletPassCustomizations = {
        enforce_wallet_pass_template: false,
        apple_wallet_pass_background: '16212F',
        apple_wallet_pass_foreground: 'FFFFFF',
        apple_wallet_pass_logo: '',
        apple_wallet_pass_logo_id: '',
        google_wallet_pass_background: '16212F',
        google_wallet_pass_logo: '',
        google_wallet_pass_hero_image: '',
        google_wallet_pass_logo_id: null,
        google_wallet_pass_hero_image_id: null
    }
    form_service: FORM_SERVICE_CHOICES = FORM_SERVICE_CHOICES.FORM_SERVICE_TYPEFORM;
    qr_ai_analytics_consent: boolean;

    // This needs to be moved to the productUsage model once it is created
    count: number;
    limit: number;
    owner: string;

    constructor(json: any) {
        super();
        if (!json) {
            return;
        }
        this.setup(json);
    }

    private setup(json: any) {
        this.id = json['id'];
        this.name = json['name'];
        this.created = json['created'];
        this.reseller_access = !!json['reseller_access'];
        this.whitelabel_access = !!json['whitelabel_access'];
        this.whitelabel_logo = json['whitelabel_logo'];
        this.whitelabel_logo_small = json['whitelabel_logo_small'];
        this.cname = json['cname'];
        this.ga_code = json['ga_code'] || '';
        this.meta = json['meta'] || {};
        this.physical_web_active = json['physical_web_active'] || false;
        this.wallet_active = json['wallet_active'] || false;
        this.custom_domain = json['custom_domain'];
        this.default_domain = json['default_domain'];
        this.feature_permissions = new Permissions(json['feature_permissions']);
        this.dbc_feature_permissions = new DbcPermissions(json['dbc_feature_permissions']);
        this.account_limits = new AccountLimits(json['account_limits'])
        this.brand_logos = json['brand_logos'] || [];
        this.parent = json['parent'];
        this.domains = json['domains'];
        this.check_response_limits = json['check_response_limits'] || false;
        this.sso_connection = json['sso_connection'] && Object.keys(json['sso_connection']).length > 0 ? json['sso_connection'] : null;
        this.enforce_qr_templates = json['enforce_qr_templates'] || false;
        this.copy_qr_templates = json['copy_qr_templates'] || false;
        this.copy_logos = json['copy_logos'] || false;
        this.fb_pixel_id = json['fb_pixel_id'] || '';
        this.google_conversion_id = json['google_conversion_id'] || '';
        this.email_wallet_pass = json['email_wallet_pass'] || false;
        this.org_user_group = json['org_user_group'];
        this.allow_analytics_export = json['allow_analytics_export'] || false;
        this.wallet_pass_template = json['wallet_pass_template'] || this.wallet_pass_template;
        this.form_service = json['form_service'] || this.form_service;
        this.count = json['count'] || 0;
        this.limit = json['limit'] || 0;
        this.owner = json['owner'] || '';
        this.qr_ai_analytics_consent = json['qr_ai_analytics_consent'] || false;
    }

    toJSON(): object {
        return {
            id: this.id,
            name: this.name,
            created: this.created,
            reseller_access: this.reseller_access,
            whitelabel_access: this.whitelabel_access,
            whitelabel_logo: this.whitelabel_logo,
            whitelabel_logo_small: this.whitelabel_logo_small,
            cname: this.cname,
            ga_code: this.ga_code,
            physical_web_active: this.physical_web_active,
            wallet_active: this.wallet_active,
            feature_permissions: this.feature_permissions,
            brand_logos: this.brand_logos,
            parent: this.parent,
            domains: this.domains,
            sso_connection: this.sso_connection,
            meta: this.meta,
            enforce_qr_templates: this.enforce_qr_templates,
            copy_qr_templates: this.copy_qr_templates,
            copy_logos: this.copy_logos,
            fb_pixel_id: this.fb_pixel_id,
            google_conversion_id: this.google_conversion_id,
            email_wallet_pass: this.email_wallet_pass,
            check_response_limits: this.check_response_limits,
            allow_analytics_export: this.allow_analytics_export,
            account_limits: this.account_limits,
            default_domain: this.default_domain,
            custom_domain: this.custom_domain,
            org_user_group: this.org_user_group,
            wallet_pass_template: this.wallet_pass_template,
            dbc_feature_permissions: this.dbc_feature_permissions,
            form_service: this.form_service,
            owner: this.owner,
            qr_ai_analytics_consent: this.qr_ai_analytics_consent
        };
    }

    toString(): string {
        return JSON.stringify(this.toJSON());
    }

    get hasOnlyQrAccess(): boolean {
        return this.feature_permissions.hasOnlyQrAccess
    }

    hasAnalyticsExportAccess(): boolean {
        return this.allow_analytics_export;
    }

    get maxCurrentDbcSeats(): number {
        return this.account_limits.dbc_user_seats;
    }
    get isBFromService(): boolean {
        return this.form_service === FORM_SERVICE_CHOICES.FORM_SERVICE_BFORM;
    }

    get dbcSeatsEarned(): number {
        return this.account_limits.dbc_user_seats_earned;
    }

    get currentAndEarnedDbcSeats(): number {
        // This returns current seats + free earned incentive seats
        return this.maxCurrentDbcSeats + this.dbcSeatsEarned;
    }

}

export class CardsOrgDepricatedRestrictedFields {
    first_name: boolean = undefined;
    last_name: boolean = false;
    phone: boolean = false
    email: boolean = false;
    website: boolean = false;
    address: boolean = false;
    summary: boolean = false;
    logo_url: boolean = false;
    designation: boolean = false;
    company: boolean = false;
    social_links: boolean = false;
    customizations: boolean = false;
    user_image_url: boolean = false;
    pronouns: boolean = false;
    prefix: boolean = false;
    suffix: boolean = false;
    department: boolean = false;
    custom_fields: boolean = false;
    layout: boolean = false;
    location_enabled: boolean = false;
    lead_collection: boolean = false;
    custom_labels: Array<String> = [];
    template: boolean = false;

    constructor(json?) {
        if (!json) {
            return;
        }
        this.first_name = json['first_name'] || false;
        this.last_name = json['last_name'] || false;
        this.phone = json['phone'] || false;
        this.email = json['email'] || false;
        this.website = json['website'] || false;
        this.address = json['address'] || false;
        this.summary = json['summary'] || false;
        this.logo_url = json['logo_url'] || false;
        this.designation = json['designation'] || false;
        this.company = json['company'] || false;
        this.customizations = json['customizations'] || false;
        this.user_image_url = json['user_image_url'] || false;
        this.social_links = json['social_links'] || false;
        this.pronouns = json['pronouns'] || false;
        this.custom_fields = json['custom_fields'] || false;
        this.prefix = json['prefix'] || false;
        this.suffix = json['suffix'] || false;
        this.department = json['department'] || false;
        this.layout = json['layout'] || false;
        this.location_enabled = json['location_enabled'] || false;
        this.lead_collection = json['lead_collection'] || false;
        this.custom_labels = json['custom_labels'] || [];
        this.template = json['template'] || false;
    }
}

export class CardsOrganizationRestrictedFields extends CardsOrgDepricatedRestrictedFields {
    id: number = null;
    organization: number = null;
    enforce_card_template: boolean = false;
    email_wallet_pass: boolean = false;
    location_enabled_v2: DBC_ORG_PERMISSION_OPTIONS
    lead_collection_v2: DBC_ORG_PERMISSION_OPTIONS
    autodownload_v2: DBC_ORG_PERMISSION_OPTIONS
    ip_location_enabled: DBC_ORG_PERMISSION_OPTIONS
    constructor(json?) {
        super(json)
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.organization = json['organization'];
        this.enforce_card_template = json['enforce_card_template']
        this.location_enabled_v2 = json['location_enabled_v2']
        this.lead_collection_v2 = json['lead_collection_v2']
        this.autodownload_v2 = json['autodownload_v2']
        this.email_wallet_pass = json['email_wallet_pass']
        this.ip_location_enabled = json['ip_location_enabled']
    }
}
