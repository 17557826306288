import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {DBCCardTypes, Utils} from '../../../shared/utils';
import {BUTTON_STYLES, BUTTON_TYPES, TEXT_FIELD_VALIDATIONS} from 'kaizen-design-system';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {DigitalBusinessCard} from '../../digital-business-card.model';
import {CARD_PROPERTY_TYPES} from '../../digital-business-card-setting/digital-business-card-setting.model';
import {CardsOrganizationRestrictedFields} from '../../../user-account/user-organization.model';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';
import {OverlayService} from '../../../global-services/overlay.service';
import {DigitalBusinessCardTemplateService} from '../../digital-business-cards.service';
import {MessageModalService} from '../../../shared/message-modal/message-modal.service';
import {AuthService} from 'app/global-services/auth.service';
import {FEATURES} from 'app/user-account/organization-permission-model';
import {environment} from 'environments/environment';
import {DBC_LAYOUTS, TEMPLATE_NAMES_MAP} from '../../templates-utils';
import {DbcRbacService} from '../../../global-services/dbc-rabc/dbc.rbac.service';
import {UpsellService} from '../../../global-services/upsell.service';
import {DbcFormUpsellTouchpointsPermission} from '../../../global-services/dbc-rabc/dbc.rbac.model';
import {DbcLDFlagService} from '../../../global-services/dbc-ld-flag/dbc-ld-flag.service';
import {
    AMPLITUDE_EVENT_CATEGORIES,
    AMPLITUDE_EVENTS,
    AmplitudeService
} from '../../../global-services/amplitude.service';

export enum DBC_DESIGN_TYPE {
    LAYOUTS,
    TEMPLATES
}

export enum TEMPLATE_OPERATIONS {
    EDIT,
    RENAME,
    DUPLICATE,
    DELETE
}

@Component({
    selector: 'app-dbc-designs-grid-view',
    templateUrl: './dbc-designs-grid-view.component.html',
    styleUrls: ['./dbc-designs-grid-view.component.scss', '../../../../scss/product.scss', '../../../qr/qr.scss']
})
export class DbcDesignsGridViewComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input() type: DBC_DESIGN_TYPE;
    @Input() digitalBusinessCardTemplates: any = [];
    @Input() hasLayoutsAccess: boolean = true;
    @Input() cardType: DBCCardTypes;
    @Input() orgCardSettings: CardsOrganizationRestrictedFields;
    @Input() dbcFormUpsellTouhPointsPermission: DbcFormUpsellTouchpointsPermission;

    @Output() selectedTemplate: EventEmitter<any> = new EventEmitter<any>(); // Change type to DigitalBusinessCard
    @Output() selectedLayout: EventEmitter<any> = new EventEmitter<any>(); // Change type to DigitalBusinessCard
    @Output() cardOperation: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

    private pageChangeSubject: Subject<any> = new Subject<{page: 1, pageSize: 11}>();

    selectedCardTempalte: any = null;
    DBC_LAYOUTS = DBC_LAYOUTS;

    layoutData = DBC_LAYOUTS
    firstFlowLayoutsData = [];

    isMobileScreen: boolean = false;
    timesClicked: number = 1;
    currentPage: number  = 1;
    totalResults: number = null;
    totalPages: number = null;
    fetchedAll: boolean = false;

    $resize: Subject<any> = new Subject<void>();
    ngUnsubscribe: Subject<any> = new Subject<void>();
    DBC_DESIGN_TYPE = DBC_DESIGN_TYPE;
    TEMPLATE_OPERATIONS = TEMPLATE_OPERATIONS
    hasDBCAdvancedCustomization: boolean = false;
    showFirstFlowLayouts: boolean = false;


    templateOperationList = [
        {
            name: 'Edit', value: TEMPLATE_OPERATIONS.EDIT
        },
        {
            name: 'Rename', value: TEMPLATE_OPERATIONS.RENAME
        },
        {
            name: 'Duplicate', value: TEMPLATE_OPERATIONS.DUPLICATE
        },
        {
            name: 'Delete', value: TEMPLATE_OPERATIONS.DELETE,  overrideColor: '#FF445E'
        },
    ]

    bulk: boolean = false;
    getMoreDesignsText: string = 'Unlock More Designs'
    showLockedTemplate: boolean = false;
    premiumBlurredLayoutImgAddress: string = `${environment.imagesEndpoint}dbc/layouts/layout_9.png?v=1.1`;

    constructor(private overlayService: OverlayService, private route: ActivatedRoute,
        private digitalBusinessCardTemplateService: DigitalBusinessCardTemplateService,
        private messageModal: MessageModalService, private authService: AuthService,
        private readonly dbcRbacService: DbcRbacService, private readonly upsellService: UpsellService,
        private readonly dbcLDFlagService: DbcLDFlagService, private readonly cdRef: ChangeDetectorRef, private readonly amplitude: AmplitudeService, private router: Router) {
        this.showLockedTemplate = this.authService.getUser().isOnDBCFreemiumPlan() &&
                                  !this.authService.getUser().hasDbcPermissionFor(FEATURES.advanced_customization_options);

        this.updateLayoutDataConfiguration();
    }
    ngOnInit() {
        this.firstFlowLayoutsData = this.layoutData.filter((layout) => Utils.FIRST_FLOW_LAYOUTS_LIST.includes(parseInt(layout.name, 10)));
        this.hasDBCAdvancedCustomization = this.authService.getUser().hasDbcPermissionFor(FEATURES.advanced_customization_options);

        this.dbcRbacService.layoutsPermission$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((layoutsPermission) => {
            this.layoutData = this.layoutData
                .filter(layout => {
                    const layoutNumber = parseInt(layout.name, 10);
                    return !isNaN(layoutNumber) && layoutsPermission[layoutNumber]; // Filter for valid and permitted layouts
                })
                .map(layout => {
                    const layoutNumber = parseInt(layout.name, 10);
                    if (Utils.PREMIUM_LAYOUTS_LIST.includes(layoutNumber)) {
                        return {
                            ...layout,
                            imageAddress: this.getImageAddressForLayout(layout.name),
                        };
                    }
                    return layout;
                });
        });

        this.setDeviseType()
        this.$resize.pipe(debounceTime(300)).subscribe(() => {
            this.setDeviseType()
        })

        this.route.queryParamMap.subscribe((map: ParamMap) => {
            this.bulk = map['params']['destination'] === 'bulk-upload';
            const isFirstFlow = map.get('TrialSignUp')?.toLowerCase() === 'true';
            this.showLockedTemplate = this.showLockedTemplate && !isFirstFlow;

            this.dbcLDFlagService.isDbcActivationExperiment1.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                this.showFirstFlowLayouts = res && (isFirstFlow);
            });
        });


        this.pageChangeSubject.pipe(debounceTime(200)).subscribe(event => {
            this.currentPage = event.page;
            this.fetchCardTemplates(this.currentPage, event.pageSize);
        })

        if (!this.isMobileScreen && this.digitalBusinessCardTemplates?.length >= 11){
            this.fetchCardTemplates(this.timesClicked, 11)
        }

        if (this.totalPages === 1) {
            const loadMoreButton = document.getElementById('load-more-button');
            if (loadMoreButton) {
                loadMoreButton.style.display = 'none';
            }
        }

        if (this.router.url.includes('my-cards/design-library') && this.type === DBC_DESIGN_TYPE.LAYOUTS) {
            this.logAmplitudeEventOnLanding()
        }
    }

    updateLayoutDataConfiguration() {
        // Add extra properties required to each layout object
        this.layoutData = this.layoutData.map(layout => {
            return {
                ...layout,
                isLoading: true
            }
        });
    }

    getImageAddressForLayout(layoutNumber: string): string {
        switch (layoutNumber) {
            case '7':
                return this.hasDBCAdvancedCustomization
                    ? `${environment.imagesEndpoint}dbc/layouts/layout_7.png?v=1.1`
                    : `${environment.imagesEndpoint}dbc/layouts/layout_7_upsell.png?v=1.1`;
            case '8':
                return this.hasDBCAdvancedCustomization
                    ? `${environment.imagesEndpoint}dbc/layouts/layout_8.png?v=1.1`
                    : `${environment.imagesEndpoint}dbc/layouts/layout_8_upsell.png?v=1.1`;
            case '9':
                return this.hasDBCAdvancedCustomization
                    ? `${environment.imagesEndpoint}dbc/layouts/layout_9.png?v=1.1`
                    : `${environment.imagesEndpoint}dbc/layouts/layout_9_upsell.png?v=1.1`;
            default:
                return `${environment.imagesEndpoint}dbc/layouts/layout_7.png?v=1.1`;
        }
    }
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.$resize.next();
        this.setDeviseType();
        this.setupPreview();
    }

    ngAfterViewInit() {
        this.setupPreview()
    }

    ngOnChanges(changes: SimpleChanges) {
        setTimeout(() => {
            this.setupPreview();
        }, 1000)
    }

    setupPreview(repeat: number = 20) {
        if (repeat === 0 || !this.digitalBusinessCardTemplates) {
            return;
        }
        for (const template of this.digitalBusinessCardTemplates) {
            const iframe = document.getElementById(`preview-template-frame-${template.id}`);
            if (!iframe) {
                setTimeout(() => {
                    this.setupPreview(repeat - 1);
                }, 100);
                return;
            }
            // @ts-ignore
            const iframedoc = iframe.contentWindow || iframe.contentDocument.document

            iframedoc.document.open();

            iframedoc.document.write(DigitalBusinessCard.getVcardPreviewHTML(template, false));
            iframedoc.document.close();
        }
    }

    selectCardTemplate(event?) {
        this.selectedTemplate.emit(event);
    }
    selectCardLayout(event?) {
        this.selectedLayout.emit(event);
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.CARD_DESIGN_SELECTED, {
            organization_id : this.authService.getCurrentOrgId(),
            layout_id : event.name,
            layout_name: TEMPLATE_NAMES_MAP[event.name]
        })
    }

    selectTemplateOperation(event: any, cardTempalte: any) {
        this.selectedCardTempalte = cardTempalte;
        switch (event.value) {
            case TEMPLATE_OPERATIONS.EDIT:
                this.editCardTemplate();
                break;
            case TEMPLATE_OPERATIONS.RENAME:
                this.onTempalteRename()
                break;
            case TEMPLATE_OPERATIONS.DUPLICATE:
                this.duplicateCardTempalte();
                break;
            case TEMPLATE_OPERATIONS.DELETE:
                this.deleteCardTemplate();
                break;
            default:
                break;
        }

    }

    onTempalteRename() {
        // Do something on rename
        this.cardOperation.emit({
            type: TEMPLATE_OPERATIONS.RENAME,
            value: this.selectedCardTempalte
        })
        this.selectedCardTempalte = null;
    }

    duplicateCardTempalte() {
        this.cardOperation.emit({
            type: TEMPLATE_OPERATIONS.DUPLICATE,
            value: this.selectedCardTempalte
        })
    }

    deleteCardTemplate() {
        this.cardOperation.emit({
            type: TEMPLATE_OPERATIONS.DELETE,
            value: this.selectedCardTempalte
        })
    }

    editCardTemplate() {
        this.cardOperation.emit({
            type: TEMPLATE_OPERATIONS.EDIT,
            value: this.selectedCardTempalte
        })
    }

    setDeviseType(): void {
        this.isMobileScreen = (window.innerWidth <= 700);
    }

    protected readonly TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS;
    protected readonly BUTTON_STYLES = BUTTON_STYLES;
    protected readonly BUTTON_TYPES = BUTTON_TYPES;
    protected readonly DBCCardTypes = DBCCardTypes;
    protected readonly CARD_PROPERTY_TYPES = CARD_PROPERTY_TYPES;

    onLayoutScroll(): void {
        const container = document.getElementById('scroll-container');
        const elements = document.getElementsByClassName('scroll-item');
        const scrollLeft = container.scrollLeft;
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            const elementLeft = element.offsetLeft;
            const elementRight = elementLeft + element.offsetWidth;
            const scrollItem = document.getElementById(`scroll-item-${index}`)

            /* Check if the element is currently visible in the container */
            if (elementLeft >= scrollLeft && elementRight <= scrollLeft + container.offsetWidth + 32) {
                scrollItem.style.backgroundColor = '#2595FF'
            } else {
                scrollItem.style.backgroundColor = '#D9D9D9'
            }
        }
    }

    OnTemplateLoadClick(): void{
        const nextPage = this.currentPage + 1; // Increment the current page number
        this.onPageChange(nextPage);
        const loadMoreButton = document.getElementById('load-more-button');
        if (loadMoreButton) {
            loadMoreButton.classList.add('hover-move-up');
        }
    }

    fetchCardTemplates(page: number, pageSize: number) {
        if (page === 1) {
            this.digitalBusinessCardTemplates = [];
        }

        this.digitalBusinessCardTemplateService.getList(page, pageSize).pipe(
            tap(templates => {
                this.digitalBusinessCardTemplates.push(...templates.objects);
                this.setupPreview(this.digitalBusinessCardTemplates);
                this.totalResults = templates.totalCount;
                this.overlayService.isLoading(false)
                if (this.digitalBusinessCardTemplates.length === this.totalResults){
                    this.fetchedAll = true;
                }
                if (this.digitalBusinessCardTemplates.length >= 15) {
                    const scrollBar = document.getElementById('scroll-bar');
                    if (scrollBar) {
                        scrollBar.style.display = 'none';
                    }
                }

                this.totalPages = Math.ceil(this.totalResults / pageSize);


                if (this.totalPages === 1){
                    const loadMoreButton = document.getElementById('load-more-button');
                    if (loadMoreButton) {
                        loadMoreButton.style.display = 'none';
                    }
                }

                if ( this.digitalBusinessCardTemplates.length >= 15){
                    const scrollBar = document.getElementById('scroll-bar')
                    if (scrollBar){
                        scrollBar.style.display = 'none' ;
                    }
                }

                if (page >= this.totalPages) {
                    const loadMoreButton = document.getElementById('load-more-button');
                    if (loadMoreButton) {
                        loadMoreButton.style.display = 'none';
                    }
                }

                const loadMoreButton = document.getElementById('load-more-button');
                if (loadMoreButton) {
                    loadMoreButton.classList.remove('hover-move-up');
                }
            },
            error => {
                this.overlayService.isLoading(false);
                this.messageModal.show('Error fetching digital business card Templates', 'danger');
            }
            )
        ).subscribe();
    }

    onPageChange(event) {
        this.pageChangeSubject.next({
            page: event,
            pageSize: 11
        })
    }

    totalDBCCount(): number {
        return this.digitalBusinessCardTemplates.length;
    }

    onTemplateScroll(): void {
        const container = document.getElementById('scroll-container');
        const elements = document.getElementsByClassName('scroll-item');

        const containerWidth = window.innerWidth ;
        const containerScrollLeft = container.scrollLeft;

        let maxVisibleIndex = 0;
        let maxVisibleArea = 0;

        // Find the card that occupies the maximum visible area in the container
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            const elementLeft = element.offsetLeft;
            const elementRight = elementLeft + element.offsetWidth;

            const visibleLeft = Math.max(elementLeft, containerScrollLeft);
            const visibleRight = Math.min(elementRight, containerScrollLeft + containerWidth);
            const visibleWidth = visibleRight - visibleLeft;

            if (visibleWidth >= maxVisibleArea) {
                maxVisibleArea = visibleWidth;
                maxVisibleIndex = index;
            }
        }


        let startFromScratchHighlighted = false;
        if (containerScrollLeft === 0) {
            startFromScratchHighlighted = true;
        }


        let previouslyHighlightedDot = null;


        for (let index = 0; index < elements.length; index++) {
            const dot = document.getElementById(`scroll-item-${index}`);
            if (dot) {
                if (index === maxVisibleIndex && !startFromScratchHighlighted) {

                    dot.style.backgroundColor = '#2595FF';
                } else if (startFromScratchHighlighted && index === 0) {

                    dot.style.backgroundColor = '#2595FF';
                } else {

                    dot.style.backgroundColor = '#D9D9D9';
                }


                if (dot === previouslyHighlightedDot) {
                    previouslyHighlightedDot.style.backgroundColor = '#D9D9D9';
                }


                if (dot.style.backgroundColor === '#2595FF') {
                    previouslyHighlightedDot = dot;
                }
            }
        }
    }

    showUpsell() {
        const upsellData = {feature: 'dbc_premium_layouts', source: 'lock_layouts_upsell'};
        if (!this.isMobileScreen) {
            this.upsellService.showV2(upsellData);
        } else {
            this.upsellService.showUpsellModalOnMobile(upsellData);
        }
    }

    setImageLoadingState(index: number) {
        this.layoutData[index]['isLoading'] = false;
    }

    setFirstFlowImageLoadingState(index: number) {
        this.firstFlowLayoutsData[index]['isLoading'] = false
    }

    onShowMoreLayoutsClick() {
        this.showFirstFlowLayouts = !this.showFirstFlowLayouts;
        if (!this.showFirstFlowLayouts) {
            this.autoScrollToThirdLayout();
        }
    }

    autoScrollToThirdLayout() {
        if (this.scrollContainer && this.isMobileScreen) {
            // Using manual change detection to immediately detect and apply any changes
            // to the DOM before executing the scroll logic.
            this.cdRef.detectChanges();
            const container = this.scrollContainer.nativeElement;
            const elements = container.getElementsByClassName('scroll-item');
            if (elements && elements[2]) {
                const element = elements[2] as HTMLElement;
                container.scrollTo({
                    left: element.offsetLeft - 85,
                    behavior: 'smooth' // Enables smooth scrolling
                });
            }

            // Calling below method to highlight the visible layout's bottom dot ellipses
            this.onTemplateScroll();
        }
    }

    logAmplitudeEventOnLanding() {
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.PAGE_VIEWED, {
            page_name : 'cards_design_library_page'
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
