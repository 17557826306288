import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Media} from '../../places/media.model';
import {MediaService} from '../../places/media-service';
import {BeaconstacFileDropComponent} from '../beaconstac-file-drop/beaconstac-file-drop.component';
import {SearchParams} from '../../global-services/base-backend.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Folder, SCOPE_TYPES} from '../../media-folders/media-folder.model';
import {AuthService} from '../../global-services/auth.service';
import {User} from '../../user-account/user.model';
import {MediaFolderService} from '../../media-folders/media-folder.service';
import {Organization} from '../../user-account/user-organization.model';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import {BUTTON_STYLES, BUTTON_TYPES} from 'kaizen-design-system';
import {UpsellService} from '../../global-services/upsell.service';
import {FEATURES} from '../../user-account/organization-permission-model';
import {DASHBOARD_MENU} from '../../shared/utils';
import {DashboardService} from '../../global-services/dashboard.service';
import {AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService} from '../../global-services/amplitude.service';
import {IMAGE_TYPE} from '../../digital-business-card/digital-business-card-detail/digital-business-card-setup/digital-business-card-setup.component';

enum ActiveTabs {
    MY_FOLDER = 'myFolder',
    SHARED = 'shared',
    UPLOAD = 'upload',
}

@Component({
    selector: 'app-media-gallery',
    templateUrl: './media-gallery.component.html',
    styleUrls: ['./media-gallery.component.scss']
})
export class MediaGalleryComponent implements OnInit, OnDestroy {

    @ViewChild('fileDrop', { static: false }) fileDrop: BeaconstacFileDropComponent;
    @Output() mediaSelector = new EventEmitter<{media: Media|null, clicked?: boolean}>();
    @Output() fileUploaded = new EventEmitter<File>();
    @Output() uploadToFolderId = new EventEmitter<number>();
    @Output() onClose = new EventEmitter<any>();
    @Output() onSelect = new EventEmitter<any>();
    @Input() isFooterVisible: boolean = true;
    @Input() isSelectDisabled: boolean = true;
    @Input() isUploadingToLogos: boolean = false;
    @Input() showSizeExceedToastMessage: boolean = true;
    @Input() imageSelectModel: any = null;
    @Input() id: string
    @Input() dbcId: any
    @Input() newCard: boolean

    private redirectToFolder: boolean;
    redirectFolderId: number;
    redirectFolder: Folder;
    uncategorizedFolder: Folder;
    logosFolder: Folder;
    BUTTON_STYLES = BUTTON_STYLES;
    BUTTON_TYPES = BUTTON_TYPES;

    @Input()
    set addMedia(media: Media) {
        if (media) {
            this.selectedMedia = media;
            this.mediaSelector.emit({media});
        }
        if (this.fileDrop) {
            this.fileDrop.clear();
        }
    }

    @Input() acceptedTypes: Array<string>;
    @Input() searchParams: SearchParams;
    @Input() maxSize: number = 5e+6;
    @Input() dimensions: string = '';
    @Input() title: string = 'Upload or select one from Asset library';
    @Input() isLogo: boolean = false;
    @Input() mediaType: string = 'image';
    @Output() rejectionError: EventEmitter<null> = new EventEmitter();

    folders: Array<Folder> = [];
    fetchedFolderPageNumber = 0;
    fetchedSharedFolderPageNumber = 0;
    fetchingFolders = false;
    fetchedAllFolders = false;
    fetchedAllSharedFolders = false;
    medias: Array<Media> = [];
    fetchedMediasPageNumber = 0;
    fetchingMedias = false;
    fetchedAllMedias = false;
    deleteMediaId: number;
    selectedMedia;
    selectedFolderId: number;
    selectedFolder: Folder;
    initialFolderCount = 0;
    SCOPE_TYPES = SCOPE_TYPES;

    activeTab: string = ActiveTabs.MY_FOLDER;
    ActiveTabs = ActiveTabs;
    hasSharedFolders: boolean = true;

    imageUploadModel = {
        file: <File>null,
        isUploading: false
    };
    ngUnsubscribe: Subject<any> = new Subject();
    user: User;
    isAdmin: boolean;
    isOwner: boolean;
    currentOrganization: Organization;
    currentOrganizationId: number;
    isFileUploading: boolean;
    isOnEnterprisePlan: boolean;
    hasShareFolderAccess: boolean;
    currentDashboard: DASHBOARD_MENU;

    @Input() onHide() {
        this.clearImageGalleryFileDrop();
    };

    constructor(private mediaFolderService: MediaFolderService, private mediaService: MediaService,
        private messageModal: MessageModalService,
        private authService: AuthService, private changeDetectorRef: ChangeDetectorRef, private upsellServicee: UpsellService,
        private dashboardService: DashboardService, private readonly amplitude: AmplitudeService) {
        this.authService.currentOrgId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((orgID) => {
            this.currentOrganizationId = orgID;
        });
        this.user = this.authService.getUser();
        this.isAdmin = this.user.isAdmin(this.authService.getCurrentOrgId());
        this.isOwner = this.user.isOwner();
        this.currentOrganization = this.authService.getCurrentOrganization();
        this.isOnEnterprisePlan = this.user.isOnEnterprisePlan();
        this.hasShareFolderAccess = this.upsellServicee.isSupportedOnPlan(FEATURES.share_folder);
    }

    ngOnInit() {
        this.dashboardService.currentDashboard$.subscribe(res => {
            if (res === DASHBOARD_MENU.CARDS) {
                this.hasShareFolderAccess = true;
            }
            this.currentDashboard = res;
        });
    }

    selectFolder(folderId: number, folder?: Folder) {
        this.redirectToFolder = false;
        this.medias = [];
        this.selectedFolderId = folderId;
        if (folder) {
            this.selectedFolder = folder;
        }
        this.fetchMedias(true)
    }

    fetchMedias(refetch?: boolean) {
        if (this.activeTab === ActiveTabs.UPLOAD) {
            this.activeTab = ActiveTabs.MY_FOLDER
        }
        if (refetch === true) {
            this.fetchedAllMedias = false;
            this.fetchedMediasPageNumber = 0;
            this.medias = [];
        }
        if (this.fetchingMedias || this.fetchedAllMedias) {
            return
        }
        const pageNumber = this.fetchedMediasPageNumber + 1;
        this.onPageChange(pageNumber);
    }

    setActiveTab(tab: ActiveTabs) {
        this.activeTab = tab;
        this.selectedFolderId = undefined
    }

    getFolderPermission(data) {
        let hashasSharedFoldersPermission = false;
        switch (data.scope) {
            case SCOPE_TYPES.RESTRICTED:
                hashasSharedFoldersPermission = true
                break;
            case SCOPE_TYPES.ORGANIZATION:
                if (this.isAdmin || this.isOwner) {
                    hashasSharedFoldersPermission = true
                } else {
                    hashasSharedFoldersPermission = false
                }
                break;
            case SCOPE_TYPES.ACCOUNT:
                if ((data.organization === this.currentOrganizationId) && (this.isAdmin || this.isOwner)) {
                    hashasSharedFoldersPermission = true
                } else if ((data.organization === this.currentOrganizationId) && !(this.isAdmin || this.isOwner)) {
                    hashasSharedFoldersPermission = false
                }
                hashasSharedFoldersPermission = false
                break;
        }
        if (hashasSharedFoldersPermission) {
            this.hasSharedFolders = true;
            return true;
        }
        this.hasSharedFolders = false
        return false
    }

    onUploadFile(file: any) {
        if (!this.acceptedTypes.some(type => type === file[0]?.type)) {
            this.messageModal.show(`Please upload files from ${this.acceptedTypes.join(',')} file types`, 'warning');
            return;
        }
        if (file[0]?.size > this.maxSize) {
            if ( this.showSizeExceedToastMessage ) {
                this.messageModal.show(`File too large: exceeds ${this.maxSize / 1000000}MB limit.`, 'warning');
            }
            this.rejectionError.emit();
            return;
        }

        this.imageUploadModel.file = file[0];
        this.loadCropper();
    }

    fetchFolders(refetch: boolean = true) {
        if (this.redirectToFolder && this.redirectFolderId) {
            for (const folder of this.folders) {
                if (this.redirectFolderId === folder.id) {
                    this.redirectFolder = { ...folder };
                    this.selectFolder(this.redirectFolderId, this.redirectFolder);
                    return;
                }
            }
            this.redirectToFolder = false;
        } else {
            this.redirectFolderId = null;
            this.selectedFolderId = null;
            if (refetch === true) {
                this.fetchedAllFolders = false;
                this.fetchedAllSharedFolders = false;
                this.fetchedFolderPageNumber = 0;
                this.folders = [];
                this.initialFolderCount = 0;
                this.fetchedSharedFolderPageNumber = 0
            }
            if (this.fetchingFolders || this.fetchedAllFolders) {
                return
            }
            const pageNumber = this.fetchedFolderPageNumber + 1;
            this.onPageChange(pageNumber);
        }
    }

    loadCropper() {
        const { selectedFolderId, selectedFolder, isLogo, logosFolder, uncategorizedFolder } = this;
        this.redirectFolderId = selectedFolderId ? selectedFolderId : isLogo ? logosFolder?.id : uncategorizedFolder?.id;
        this.redirectFolder = selectedFolder ? selectedFolder : isLogo ? logosFolder : uncategorizedFolder;

        this.uploadToFolderId.emit(this.redirectFolderId);
        this.redirectToFolder = true;
        if (this.mediaType.includes('image')) {
            this.fileUploaded.emit(this.imageUploadModel.file);
        } else {
            this.isFileUploading = true;
            this.onFileAdded(this.imageUploadModel.file, this.redirectFolderId).then(res => {
                for (const folder of this.folders) {
                    if (this.redirectFolderId === folder.id) {
                        this.redirectFolder = { ...folder };
                        this.selectFolder(this.redirectFolderId, this.redirectFolder);
                        return;
                    }
                }
            }).catch(e => {
                this.changeDetectorRef.detectChanges();
                console.error(e)
            })
        }
        this.clearImageGalleryFileDrop();
    }

    selectMedia(media, clicked= false) {
        this.selectedMedia = media;
        this.redirectToFolder = false;
        this.mediaSelector.emit({media, clicked});
    }

    onPageChange(page: number) {
        if (this.selectedFolderId) {
            if (this.fetchingMedias || this.fetchedAllMedias || page < 0) {
                return
            }
            this.fetchingMedias = true;
            if (this.mediaType !== 'all') {
                this.searchParams['content_type__icontains'] = this.mediaType;
            } else {
                this.searchParams['content_type__icontains'] = ''
            }
            this.searchParams['folder__id'] = this.selectedFolderId;
            this.mediaService.getList(page, 25, this.searchParams, !this.user.isSuperAdmin(), null, null, '', this.user.isSuperAdmin())
                .pipe(takeUntil(this.ngUnsubscribe)).subscribe(mediaList => {
                    const medias = mediaList.objects;
                    for (const media of medias) {
                        this.medias.push(media);
                    }
                    this.fetchedAllMedias = mediaList.totalCount === this.medias.length;
                    this.fetchedMediasPageNumber = page;
                    this.fetchingMedias = false;
                    this.changeDetectorRef.detectChanges();
                }, (error: any) => {
                    console.error(error);
                    this.fetchingMedias = false;
                    this.changeDetectorRef.detectChanges();
                    this.onBackPressed();
                });
        } else {
            if (this.fetchingFolders || page < 0) {
                return
            }
            this.fetchingFolders = true;

            if (!this.fetchedAllFolders || !this.fetchedAllSharedFolders) {
                this.mediaFolderService.getList(page, 100, this.searchParams)
                    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(folderList => {
                        const folders = folderList.objects.filter((item) => !this.folders.includes(item));
                        const fetchedFolders = [];
                        for (const folder of folders) {
                            fetchedFolders.push(folder);
                            if (folder.uncategorized) {
                                this.uncategorizedFolder = folder
                            }
                            if (folder.logos) {
                                this.logosFolder = folder
                            }
                        }
                        this.fetchedAllFolders = folderList.totalCount === this.folders.length;
                        if (!this.fetchedAllFolders) {
                            this.fetchedFolderPageNumber = page;
                        }
                        this.changeDetectorRef.detectChanges();
                        if (this.hasShareFolderAccess) {
                            if (!this.fetchedAllSharedFolders) {
                                this.mediaFolderService.getList(page, 100, { 'shared_folders': 'true' })
                                    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(sharedFolderList => {
                                        const sharedFolder = sharedFolderList.objects.filter((item) => {
                                            for (const folder of fetchedFolders) {
                                                if (item.id === folder.id) {
                                                    return false;
                                                }
                                            }
                                            return true
                                        });
                                        for (const media of sharedFolder) {
                                            fetchedFolders.push(media);
                                        }
                                        this.fetchedAllSharedFolders = (sharedFolderList.totalCount + this.initialFolderCount) === this.folders.length;
                                        this.fetchedSharedFolderPageNumber = page;
                                        this.fetchingFolders = false;
                                        this.folders = [...fetchedFolders];
                                        this.changeDetectorRef.detectChanges();
                                    }, (error: any) => {
                                        console.error(error);
                                        this.fetchingFolders = false;
                                        this.changeDetectorRef.detectChanges();
                                    });
                            }
                        } else {
                            this.fetchingFolders = false;
                            this.folders = [...fetchedFolders];
                            this.changeDetectorRef.detectChanges();
                        }
                    }, (error: any) => {
                        console.error(error);
                        this.fetchingFolders = false;
                        this.changeDetectorRef.detectChanges();
                    });

            }

        }
    }

    clearImageGalleryFileDrop() {
        this.imageUploadModel = {
            file: <File>null,
            isUploading: false
        };
        this.selectedMedia = null;
        this.fileDrop?.clear();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onBackPressed() {
        this.selectedFolderId = null;
        this.selectedFolder = null;
        this.redirectToFolder = false;
        this.folders = [];
        this.fetchFolders(true)
    }

    onFileAdded(file: File, folderId) {
        return new Promise((resolve, reject) => {
            this.mediaService.uploadFile(file, file.type.split('/')[0], null, null, folderId).pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => {
                    this.isFileUploading = false
                })).subscribe(media => {
                resolve(media)
            }, e => {
                reject(e);
            });
        })
    }

    logEventOnProfilePicUpload() {
        if (this.id === 'imageSelectGallerySetupStep' && !this.isLogo && this.imageSelectModel.type === IMAGE_TYPE.DBCUserImage) {
            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.CARD_PHOTO_UPLOAD, {
                organization_id: this.authService.getCurrentOrgId(),
                new_card: this.newCard,
                card_id: this.dbcId
            })
        }
    }
}
