import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WaitLoaderComponent} from './wait-loader';



@NgModule({
    declarations: [WaitLoaderComponent],
    exports: [WaitLoaderComponent],
    imports: [
        CommonModule
    ]
})
export class WaitLoaderModule { }
