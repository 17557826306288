import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'app/global-services/auth.service';
import { FullLayoutComponentService } from 'app/global-services/full-layout-component.service';
import { TOOLTIP_POSITION, CARET_POSITION } from 'kaizen-design-system';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-collapsed-side-nav',
    templateUrl: './collapsed-side-nav.component.html',
    styleUrls: ['./collapsed-side-nav.component.scss'],
})
export class CollapsedSideNavComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject();
    private sideNavCollapsedStateSubscription: Subscription;

    isCollapsed: boolean = true;
    TOOLTIP_POSITION = TOOLTIP_POSITION;
    CARET_POSITION = CARET_POSITION;
    isOnTrialPlan: boolean;
    isOwner: boolean;
    isAdmin: boolean;
    currentOrganizationId: number;

    @Input() currentDashboard: any;
    @Input() isDbcDashboard: boolean;
    @Input() navBarItems: any;
    @Input() accessList: any;
    @Input() dbcNavItemsPermissions: any;
    @Input() hasTemplatePermission: boolean;
    @Input() hasCardsTemplateAccess: boolean;
    @Input() qrCreateCtaVariation: any;
    @Input() isAdminDashboard: any;
    @Input() showQrUpgradeNowButton: any;
    @Input() showCardsUpgradeNowButton: any;
    @Input() DASHBOARD_MENU: any;
    @Input() HelpMenuOptions: any;
    @Input() showModal: boolean;
    @Input() isMobileDevice: boolean;
    @Input() QR_CREATE_CTA_VARIATIONS: any;
    @Input() campaignTypes: any;
    @Input() dropdownIcon: any;

    @Output() toggleCollapsed = new EventEmitter<void>();
    @Output() startIntercom = new EventEmitter<void>();
    @Output() redirectHelp = new EventEmitter<string>();
    @Output() nudgeClick = new EventEmitter<boolean>();
    @Output() closeMycardsPopupEmitter = new EventEmitter<boolean>();
    @Output() openUpgradePageEmitter = new EventEmitter<string>();
    @Output() selectDropdownIconEvent = new EventEmitter<string | undefined>();
    @Output() createQRctaEvent = new EventEmitter<{ variation: string; category: string }>();
    @Output() logOverviewClicksEvent = new EventEmitter<void>();

    constructor(
        private authService: AuthService,
        private fullLayoutService: FullLayoutComponentService
    ) {}

    ngOnInit(): void {
        this.authService.currentOrgId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((orgID) => {
            this.currentOrganizationId = orgID;
        });
        const user = this.authService.getUser();
        this.isOnTrialPlan = user?.isOnTrialPlan();
        this.isOwner = user?.isOwner();
        this.isAdmin = user?.isAdmin(this.authService.getCurrentOrgId());
        this.sideNavCollapsedStateSubscription = this.fullLayoutService.isSideNavCollapsed$.subscribe((state: boolean) => {
            this.isCollapsed = state;
        });
    }

    toggleCollapsedState(): void {
        this.toggleCollapsed.emit();
    }

    startNewIntercomConversation(): void {
        this.startIntercom.emit();
    }

    redirectFromHelpSection(option: string): void {
        this.redirectHelp.emit(option);
    }

    handleNudgePopup(): void {
        this.nudgeClick.emit(true);
    }

    closePopupEmitter(): void {
        this.closeMycardsPopupEmitter.emit(true);
    }

    openUpgradePage(type: string): void {
        this.openUpgradePageEmitter.emit(type);
    }

    selectDropdownIcon(type: string): void {
        this.selectDropdownIconEvent.emit(type);
    }

    handleCreateQRctaClick(variation: string, category: string): void {
        this.createQRctaEvent.emit({ variation, category });
    }

    logOverviewClicks(){
        this.logOverviewClicksEvent.emit();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        if (this.sideNavCollapsedStateSubscription) {
            this.sideNavCollapsedStateSubscription.unsubscribe();
        }
    }
}
