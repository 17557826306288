import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FullLayoutComponentService {
    private isSideNavCollapsedSubject = new BehaviorSubject<boolean>(false);
    isSideNavCollapsed$: Observable<boolean> = this.isSideNavCollapsedSubject.asObservable();

    constructor() {}

    setSideNavCollapsedState(state: boolean): void {
        this.isSideNavCollapsedSubject.next(state);
    }

    getSideNavCollapsedState(): boolean {
        return this.isSideNavCollapsedSubject.value;
    }
}
