import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import Cropper from 'cropperjs';
import { ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { Media } from '../../places/media.model'
import { MediaService } from '../../places/media-service'
import { mergeMap, takeUntil } from 'rxjs/operators';
import { BUTTON_STYLES, BUTTON_TYPES } from 'kaizen-design-system';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit, OnDestroy, OnChanges {

    @Input() imageFile: File;
    @Input() squareDragMode: boolean = false;
    @Input() folderId: number = null;
    @Input() imageAspectRatio?: any = undefined;
    @Input() returnImageDataOnUpload: boolean = false;
    @Input() showUploadOriginal: boolean = true;
    @Input() modalConfig: ModalOptions = { backdrop: 'static' };
    @Output() imageUpdated: EventEmitter<Media | any> = new EventEmitter();
    @Output() cropperClose: EventEmitter<null> = new EventEmitter();

    @ViewChild('image', { static: false }) image: ElementRef;
    @ViewChild('imageCropModal', { static: false }) imageCropModal: ModalDirective;

    private _cropper: Cropper = null;
    imageSafeUrl: SafeUrl = null;
    shouldLoadCropper: boolean = true;
    BUTTON_STYLES = BUTTON_STYLES;
    BUTTON_TYPES = BUTTON_TYPES;

    loading = true;
    imageUploading = false;
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private _sanitizer: DomSanitizer, private mediaService: MediaService) { }

    ngOnInit() {
        this.setUpImageData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.imageFile && changes.imageFile.currentValue) {
            this.setUpImageData();
        }
    }

    setUpImageData() {
        if (!this.imageFile) {
            return;
        }
        const url = URL.createObjectURL(this.imageFile);
        this.imageSafeUrl = this._sanitizer.bypassSecurityTrustUrl(url);
        this.shouldLoadCropper = this.imageFile.type !== 'image/gif' && this.imageFile.type.indexOf('svg') === -1;
    }

    cropperLoad() {
        if (this.shouldLoadCropper) {
            const __this = this;
            setTimeout(() => {
                this._cropper = new Cropper(this.image.nativeElement, {
                    zoomable: true,
                    dragMode: 'crop',
                    aspectRatio: this.imageAspectRatio || undefined,
                    responsive: true,
                    ready: function () {
                        __this.loading = false;
                    }
                });
            }, 500);
        } else {
            this.loading = false;
        }
    }

    rotateLeft() {
        this._cropper.rotate(-90);
    }

    rotateRight() {
        this._cropper.rotate(90);
    }

    setDragMode() {
    // @ts-ignore
        if (this._cropper?.options.dragMode === 'move') {
            this._cropper.setDragMode('none');
        } else {
            this._cropper.setDragMode('move');
        }
    }

    flipHorizontal() {
        const data = this._cropper.getData();
        this._cropper.scale(-data.scaleX, data.scaleY);
    }

    flipVertical() {
        const data = this._cropper.getData();
        this._cropper.scale(data.scaleX, -data.scaleY);
    }

    reset() {
        this._cropper.reset();
    }

    zoomIn() {
        this._cropper.zoom(0.1);
    }

    zoomOut() {
        this._cropper.zoom(-0.1);
    }

    cropImage(shouldCrop: boolean): Observable<File> {
        const __this = this;
        return new Observable<File>(observer => {
            if (shouldCrop) {
                __this._cropper.getCroppedCanvas().toBlob((blob) => {
                    const name = __this.imageFile.name;
                    const type = __this.imageFile.type;
                    observer.next(new File([blob], name, { type: type }));
                    observer.complete();
                });
            } else {
                observer.next(__this.imageFile);
                observer.complete();
            }
        });
    }

    uploadImage(shouldCrop: boolean) {
        this.loading = true;
        this.imageUploading = true;
        if (this.returnImageDataOnUpload) {
            this.cropImage(shouldCrop).subscribe(file => {
                this.loading = false;
                this.imageUploading = false;
                this.imageUpdated.emit(file);
                this.imageCropModal.hide();
            });
            return;
        }
        this.cropImage(shouldCrop).pipe(
            takeUntil(this.ngUnsubscribe),
            mergeMap(file => {
                return this.mediaService.uploadFile(file, 'image', null, null, this.folderId);
            })).subscribe(media => {
            this.loading = false;
            this.imageUploading = false;
            this.imageUpdated.emit(media);
        }, (error: any) => {
            this.loading = false;
            this.imageUploading = false;
            this.imageUpdated.emit(error);
        });
    }

    show() {
        this.imageCropModal.show();
    }

    hide() {
        this.imageCropModal.hide();
    }

    modalHide() {
        this.cropperClose.emit();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
