import {BaseBackendService} from '../global-services/base-backend.service';
import {Injectable} from '@angular/core';
import {AuthService} from '../global-services/auth.service';
import {BaseModel} from '../global-services/base.model';
import {HttpClient} from '@angular/common/http';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {deepCopy} from 'app/shared/utils';
import {FORM_SERVICE_CHOICES} from '../user-account/user-organization.model';

export class FormData extends BaseModel {
    id: number;
    title: string;
    preview_url: string;
    form_structure: {
        theme: object;
        title: string;
        welcome_screens: any;
        fields: object;
        settings: object;
        thankyou_screens: any;
        logic: any;
        variables: object;
        hidden: any;
        theme_id: number;
    };
}

export class Form extends BaseModel {

    id: number;
    title: string;
    default: boolean;
    created: any;
    updated: any;
    description: Field[];
    organization: number;
    form_url: string;
    form_id: string;
    form_structure: any;
    responses: number;
    sms_structure: any;
    email_structure: any;
    meta: any;
    threat_active: boolean;
    _previewUrl: string;
    _previewUrlV2: string;

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.title = json['title'];
        this.description = json['description'];
        this.default = json['default'];
        this.created = json['created'];
        this.updated = json['updated'];
        this.organization = json['organization'];
        this.form_url = json['url'];
        this.form_id = json['form_id'];
        this.form_structure = json['form_structure'];
        this.responses = json['form_response_count'];
        this.sms_structure = json['sms_structure'];
        this.email_structure = json['email_structure'];
        this.meta = json['meta'];
        this.threat_active = json['threat_active'] || false;
        this._previewUrl = environment.formUrl + this.id;
        this._previewUrlV2 = environment.formUrlV2 + this.id;
    }
}

export class Field extends BaseModel {
    ref: string;
    type: string;
    title: string;
    properties: object = {};
    id: string;
    validations: object = {};
    attachment: Attachement = new Attachement();

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.type = json['type'];
        this.title = json['title'];
        this.ref = json['ref'];
        if (json['properties']) {
            this.properties['description'] = json['properties']['description'];
            this.properties['choices'] = json['properties']['choices'];
            this.properties['labels'] = json['properties']['labels'];
            this.properties['default_country_code'] =
        json['properties']['default_country_code'];
            if (this.type === 'multiple_choice') {
                this.properties['allow_multiple_selection'] = json['properties'][
                    'allow_multiple_selection'
                ]
                    ? json['properties']['allow_multiple_selection']
                    : false;
            }
            if (this.type === 'rating') {
                this.properties['steps'] = json['properties']['steps'];
            }
        }
        this.attachment = json['attachment']
            ? new Attachement(json['attachment'])
            : undefined;
        this.id = json['id'];
        this.validations = json['validations'] || {required: false};
        if (json['properties'] && json['properties']?.notification) {
            this.properties['notification'] = deepCopy(json['properties']['notification']);
        }
    }
}

export class WelcomeScreen extends BaseModel {
    ref: string = 'default_ref_welcome';
    title: string = '';
    properties: PropertyWelcomeScreen = new PropertyWelcomeScreen();
    attachment: Attachement = new Attachement();

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.title = json['title'];
        this.ref = json['ref'];
        this.properties = json['properties']
            ? new PropertyWelcomeScreen(json['properties'])
            : new PropertyWelcomeScreen();
        this.attachment = json['attachment']
            ? new Attachement(json['attachment'])
            : undefined;
    }
}

export class ThankyouScreen extends BaseModel {
    ref: string = 'default_ref_thankyou';
    title: string = '';
    properties: PropertyThankyouScreen = new PropertyThankyouScreen();
    attachment: Attachement = new Attachement();

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.title = json['title'];
        this.ref = json['ref'];
        this.properties = json['properties']
            ? new PropertyThankyouScreen(json['properties'])
            : new PropertyThankyouScreen();
        this.attachment = json['attachment']
            ? new Attachement(json['attachment'])
            : undefined;
    }
}

class PropertyWelcomeScreen extends BaseModel {
    description: string = '';
    show_button: boolean = true;
    button_text: string = '';

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.description = json['description'] ? json['description'] : undefined;
        this.show_button = json['show_button'] ? json['show_button'] : true;
        this.button_text = json['button_text'] ? json['button_text'] : undefined;
    }
}

class PropertyThankyouScreen extends BaseModel {
    show_button: boolean = true;
    button_text: string = '';
    button_mode: string = 'reload';
    redirect_url: string = '';
    share_icons: boolean = false;

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.show_button = json.hasOwnProperty('show_button') ? json['show_button'] : true;
        this.button_text = json['button_text'] ? json['button_text'] : undefined;
        this.button_mode = json['button_mode'] ? json['button_mode'] : 'reload';
        this.redirect_url = json['redirect_url'] ? json['redirect_url'] : undefined;
        this.share_icons = json['share_icons'] ? json['share_icons'] : false;
    }
}

export class Attachement extends BaseModel {
    type: string = 'image';
    href: string = '';

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.type = json['type'] ? json['type'] : undefined;
        this.href = json['href'] ? json['href'] : undefined;
    }
}

export class Logic extends BaseModel {
    // working
    type: string;
    ref: string;
    actions = [];

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.type = json['type'] ? json['type'] : undefined;
        this.ref = json['ref'] ? json['ref'] : undefined;
        for (const data of json['actions']) {
            if (data['action'] === 'jump') {
                this.actions.push({
                    action: 'jump',
                    details: new JumpDetails(data['details']),
                    condition: new Condition(data['condition']),
                });
            } else {
                this.actions.push({
                    action: data['action'],
                    details: new CalculationDetails(data['details']),
                    condition: new Condition(data['condition']),
                });
            }
        }
    }
}

export class JumpDetails extends BaseModel {
    to: object;

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.to = json['to'] ? json['to'] : undefined;
    }
}

export class CalculationDetails extends BaseModel {
    target: object;
    value: object;

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.target = json['target'] ? json['target'] : undefined;
        this.value = json['value'] ? json['value'] : undefined;
    }
}

export class Condition extends BaseModel {
    op: string;
    vars: [];

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.op = json['op'] ? json['op'] : undefined;
        this.vars = json['vars'] ? json['vars'] : undefined;
    }
}

@Injectable()
export class FormService extends BaseBackendService<Form> {
    private selectedTemplate: BehaviorSubject<Object> = new BehaviorSubject(null);
    private fromCopyData: BehaviorSubject<object> = new BehaviorSubject(null);

    constructor(
        http: HttpClient,
        authService: AuthService,
        messageModal: MessageModalService
    ) {
        super(http, authService, Form, 'forms', messageModal);
    }

    setFormCopyData(data: object): void {
        this.fromCopyData.next(data);
    }

    getFormCopyData(): object {
        return this.fromCopyData.getValue();
    }

    setTemplateData(data: object): void {
        this.selectedTemplate.next(data);
    }

    getTemplatedata(): object {
        return this.selectedTemplate.getValue();
    }

    getFormTemplateData() {
        if (this.authService.getCurrentOrganization().form_service === FORM_SERVICE_CHOICES.FORM_SERVICE_BFORM) {
            return this.http.get(environment.bformTemplateDataEndpoint);
        }
        return this.http.get(environment.formTemplateDataEndpoint);
    }

    getFormLanguageData() {
        return this.http.get(environment.formTemplateLanguageEndpoint);
    }
}

export class FormTheme extends BaseModel {
    id: string;
    organization: number;
    font: string;
    name: string = 'My theme';
    colors: {
        'question': string,
        'answer': string,
        'button': string,
        'background': string
    } = {
            question: '#000000',
            answer: '#4fb0ae',
            button: '#4fb0ae',
            background: '#ffffff',
        };
    has_transparent_button: false;
    visibility: string;
    background: {
        brightness: number;
        layout: string;
        href: string;
    };
    // Consider typeform_id and bform_id are same
    typeform_id: string;
    screens = {}
    fields = {}

    constructor(json?) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.organization = json['organization'];
        this.font = json['font'];
        this.name = json['name'];
        this.colors = json['colors'];
        this.has_transparent_button = json['has_transparent_button'];
        this.visibility = json['visibility'];
        this.background = json['background'];
        this.typeform_id = json['typeform_id'] || json['id'];
    }
}

@Injectable()
export class FormThemeService extends BaseBackendService<FormTheme> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, FormTheme, 'formthemes', messageModal);
    }

    getThemes(custom?: string): Observable<any> {
        const beaconDetailUrl = this.apiURL;
        const url = !custom ? this.buildURL(beaconDetailUrl) : this.buildURL(beaconDetailUrl, 1, 30, null, true, null, null, custom);
        return this.http
            .get(url, this.buildHeaders({})).pipe(
                map(response => {
                    return response
                }),
                catchError(error => {
                    return this.handleError(error);
                }));
    }

    createNewTheme(body: FormTheme, custom?: string): Observable<any> {
        return this.post(body, custom).pipe(
            map(response => {
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }

    saveTheme(body: FormTheme, id: string, custom?: string): Observable<any> {
        return this.put(body, id, custom).pipe(
            map(response => {
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }
}

@Injectable()
export class FormThreatVerificationService extends BaseBackendService<any> {
    constructor(
        http: HttpClient,
        authService: AuthService,
        messageModal: MessageModalService
    ) {
        super(
            http,
            authService,
            FormTheme,
            'product/malware/verify/form',
            messageModal
        );
    }
}
