<div class="bx--row" *ngIf="type==DBC_DESIGN_TYPE.TEMPLATES && !isMobileScreen">
    <div class="bx--col-xl-4 bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
        <div class="bx--row py-4 desktop-container">
            <div *ngIf="(cardType !== DBCCardTypes.BULK_CARDS) && (cardType !== DBCCardTypes.TEMPLATE_CARDS) && !orgCardSettings.enforce_card_template" class="mr-4 hover-move-up card-template-container-desktop d-flex flex-column px-3 pt-3">
                <div class="card-template-body-desktop d-flex justify-content-center align-items-center w-100 flex-column" (click)="selectCardTemplate()">
                    <div style="height: 90%" class="d-flex justify-content-center align-items-center">
                        <i class="fas fa-plus"></i>
                    </div>
                    <div class="card-template-description d-flex align-items-end pb-3">
                        <div class="start-from-scratch-overlay">
                            <div class="card-template-name-mobile">Start from scratch</div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngFor="let cardTemplate of digitalBusinessCardTemplates;"
                 class="mr-4 hover-move-up card-template-container ">
                <div class="template-selector-overlay" (click)="selectCardTemplate(cardTemplate)"></div>
                <div class="d-flex flex-column justify-content-center align-items-top w-100" id="customPreview-{{cardTemplate.id}}" style="height: 100%">
                <div class="iframe-container">
                    <iframe id="preview-template-frame-{{cardTemplate.id}}"  class="live-preview-iframe cursor-pointer" style="border: 1px solid #C0C5CB; border-radius: 4px;overflow-y: scroll; width:100%;height: 100%"></iframe>
                    <div class="template-footer-overlay-mobile">
                        <div class="card-template-name-mobile">
                            {{cardTemplate.template_name}}
                        </div>
                        <div class="card-template-options-mobile">
                            <div *ngIf="cardType === DBCCardTypes.TEMPLATE_CARDS" class="dropdown" style="width: 10px; text-align: center;" dropdown>
                                    <span class="dropdown-toggle-template" data-toggle="dropdown">
                                    <i class="fas fa-ellipsis-h cursor-pointer" dropdownToggle></i>
                                    </span>
                                <div class="dropdown-menu dropdown-menu-right menu-options sm-and-below-full-width-options" *dropdownMenu aria-labelledby="simple-dropdown" style="max-width: 158px;">
                                        <beaconstac-options
                                            (valueChange)="selectTemplateOperation($event, cardTemplate)"
                                            [maxWidth]="'auto'"
                                            [maxHeight]="'max-content'"
                                            [highlightSelectedValue]="false"
                                            [optionLabelField]="'name'"
                                            [optionsType]="1"
                                            [optionValueField]="'value'"
                                            [listItems]="templateOperationList"></beaconstac-options>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div *ngIf="digitalBusinessCardTemplates?.length === 0 && (cardType === DBCCardTypes.TEMPLATE_CARDS)" class="mr-4 pb-3 no-template-container d-flex flex-column px-3">
                No templates found. Lets start by creating a fresh template for your business cards
            </div>
        </div>
    </div>
</div>

<div class="bx--row bg-light m-0" *ngIf="type==DBC_DESIGN_TYPE.LAYOUTS && !isMobileScreen">
    <div [ngClass]="showFirstFlowLayouts ? 'd-block' : 'd-none'">
        <ng-container [ngTemplateOutlet]="firstFlowLayoutsContainer">
        </ng-container>
    </div>
    <div [ngClass]="showFirstFlowLayouts ? 'd-none' : 'd-block'" class="bx--col-xl-4 bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
        <div class="bx--row">
            <div *ngFor="let layout of layoutData; let i = index"
                 class="hover-move-up mr-4 pb-4">
                <div [ngClass]="{'hidden': !layout?.isLoading}" class="skeleton-loader"></div>
                <img
                    [ngClass]="{'hidden': layout?.isLoading}"
                    src="{{layout.imageAddress}}"
                    alt="default layouts"
                    class="w-100 cursor-pointer dbc-layout-img"
                    (click)="selectCardLayout(layout)"
                    (load)="setImageLoadingState(i)">
            </div>

            <div *ngIf="showLockedTemplate" class="hover-move-up mr-4 pb-4">
                <div class="position-relative">
                    <img
                        src="{{premiumBlurredLayoutImgAddress}}"
                        alt="default layouts"
                        class="w-100 cursor-pointer locked-layout">
                    <div class="blur-container">
                        <app-blur-paywall
                            [message]="getMoreDesignsText"
                            [minHeight]="'100%'"
                            (upgradeClicked)="showUpsell()"
                        ></app-blur-paywall>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="bx--row m-0 bg-light w-100" *ngIf="type==DBC_DESIGN_TYPE.LAYOUTS && isMobileScreen">
    <div [ngClass]="showFirstFlowLayouts ? 'd-block' : 'd-none'">
        <ng-container [ngTemplateOutlet]="firstFlowLayoutsContainer">
        </ng-container>
    </div>
    <div [ngClass]="showFirstFlowLayouts ? 'd-none' : 'd-block w-100'">
        <div #scrollContainer class="d-flex" [id]="'scroll-container'" style="overflow-x: scroll; padding-top:8px;" (scroll)="onLayoutScroll()">
                <div *ngFor="let layout of layoutData; let i = index" class="hover-move-up mr-4 mb-3 scroll-item">
                    <div [ngClass]="{'hidden': !layout?.isLoading}" class="skeleton-loader"></div>
                    <img [ngClass]="{'hidden': layout?.isLoading}" src="{{layout.imageAddress}}"
                     class="cursor-pointer mob-layout-img"
                     (click)="selectCardLayout(layout)"
                     (load)="setImageLoadingState(i)">
                </div>
                <div *ngIf="showLockedTemplate" class="hover-move-up mr-4 mb-3 scroll-item">
                    <div class="position-relative locked-layout-container">
                        <img
                            src="{{premiumBlurredLayoutImgAddress}}"
                            alt="default layouts"
                            class="w-100 cursor-pointer locked-layout">
                        <div class="blur-container">
                            <app-blur-paywall
                                [message]="getMoreDesignsText"
                                [minHeight]="'100%'"
                                (upgradeClicked)="showUpsell()"
                            ></app-blur-paywall>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex pt-2 w-100 justify-content-center">
                <div *ngFor="let layout of layoutData; index as i" [id]="'scroll-item-' + i" class="rounded-circle mx-1 scroll-selector" [ngClass]="(i === 0) ? 'bg-color-active' : 'bg-color-inactive'"></div>
                <div *ngIf="showLockedTemplate" [id]="'scroll-item-' + layoutData.length" class="rounded-circle mx-1 scroll-selector bg-color-inactive"></div>
            </div>
        </div>
</div>

<!-- code for templates on mobile screen -->
<div class="bx--row m-0 w-100 " *ngIf="type==DBC_DESIGN_TYPE.TEMPLATES && isMobileScreen" style="background-color: #f4f5f7;">
    <div class="d-flex" [id]="'scroll-container'" style="overflow-x: scroll; padding-top:8px; background-color:  #f4f5f7;padding-bottom: 16px;" (scroll)="onTemplateScroll()">

        <!-- start from scratch -->
        <div *ngIf="(cardType !== DBCCardTypes.BULK_CARDS) && (cardType !== DBCCardTypes.TEMPLATE_CARDS) && !orgCardSettings.enforce_card_template" class="mr-4 hover-move-up card-template-container d-flex flex-column px-0">
            <div class="card-template-body-mobile d-flex justify-content-center align-items-center w-100 flex-column" (click)="selectCardTemplate()">
                <div style="height: 90%" class="d-flex justify-content-center align-items-center">
                    <i class="fas fa-plus"></i>
                </div>
                <div class="card-template-description d-flex align-items-end pb-3">
                    <div class="start-from-scratch-overlay-mobile">
                        <div class="card-template-name-mobile">Start from scratch</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- start from scratch ends -->

        <div *ngFor="let cardTemplate of digitalBusinessCardTemplates" class="hover-move-up mr-4 scroll-item card-template-container">
            <div class="template-selector-overlay-mobile" (click)="selectCardTemplate(cardTemplate)"></div>
            <div class="iframe-container">
                <iframe id="preview-template-frame-{{cardTemplate.id}}" class="live-preview-iframe cursor-pointer iframe-styles" style="border: 1px solid #C0C5CB; border-radius: 4px;overflow-y: scroll;width: 100%;height: 100%"></iframe>
                <div class="template-footer-overlay-mobile">
                    <div class="card-template-name-mobile">
                        {{cardTemplate.template_name}}
                    </div>
                    <div class="card-template-options-mobile">
                        <div *ngIf="cardType === DBCCardTypes.TEMPLATE_CARDS && isMobileScreen" class="dropdown" style="width: 10px; text-align: center;" dropdown>
                                <span class="dropdown-toggle-template" data-toggle="dropdown">
                                <i class="fas fa-ellipsis-h cursor-pointer" dropdownToggle></i>
                                </span>
                            <div class="dropdown-menu dropdown-menu-right menu-options sm-and-below-full-width-options" *dropdownMenu aria-labelledby="simple-dropdown" style="max-width: 158px;">
                                    <beaconstac-options
                                        (valueChange)="selectTemplateOperation($event, cardTemplate)"
                                        [maxWidth]="'auto'"
                                        [maxHeight]="'max-content'"
                                        [highlightSelectedValue]="false"
                                        [optionLabelField]="'name'"
                                        [optionsType]="1"
                                        [optionValueField]="'value'"
                                        [listItems]="templateOperationList"></beaconstac-options>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="digitalBusinessCardTemplates.length >= 11" class="hover-move-up mr-4 load-more-templates-container" id="load-more-button" (click)="OnTemplateLoadClick()" >
            <div class="load-more-templates-body">
                <div class="fas fa-arrow-right load-more-templates-icon"></div>
                <div class="load-more-templates-text">Load more</div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="timesClicked === 1 && digitalBusinessCardTemplates.length < 15 ">
        <div class="d-flex pt-2 w-100 justify-content-center" style="background-color:#f4f5f7; padding-bottom: 80px;z-index: 1;" id="scroll-bar">
            <div *ngFor="let cardTemplate of digitalBusinessCardTemplates; index as i" [id]="'scroll-item-' + i" class="rounded-circle mx-1 scroll-selector" [ngClass]="(i === 0) ? 'bg-color-active' : 'bg-color-inactive'">
            </div>
        </div>
    </ng-container>
</div>
<!-- code for templates on mobile screen -->


<ng-template #firstFlowLayoutsContainer>
    <div class="d-flex pt-3 overflow-x-scroll gap-3">
        <div *ngFor="let layout of firstFlowLayoutsData; let i = index" class="hover-move-up mb-3 scroll-mobile-item">
            <div [ngClass]="{'hidden': !layout?.isLoading}" class="skeleton-loader skeleton-loader-mobile"></div>
            <img [ngClass]="{'hidden': layout?.isLoading}"
                 src="{{layout.imageAddress}}"
                 class="cursor-pointer layout-image"
                 (click)="selectCardLayout(layout)"
                 (load)="setFirstFlowImageLoadingState(i)"
                 alt="layout">
        </div>
    </div>
    <!-- show more button -->
    <div class="w-100 mt-3 d-flex justify-content-center justify-content-md-start align-items-center">
        <beaconstac-button
            class=""
            (onClick)="onShowMoreLayoutsClick()"
            [type]="BUTTON_TYPES.SECONDARY"
            [style]="BUTTON_STYLES.FILLED"
            [label]="'Show More Designs'"
        ></beaconstac-button>
    </div>
</ng-template>
