<div>
  <div class="px-4 mb-2 d-flex align-items-center border-bottom modal-negative-margins">
    <div class="pr-3 pr-lg-5">
      <div class="cursor-pointer sub-titles custom-tab"
        [ngClass]="activeTab === ActiveTabs.MY_FOLDER && 'active-folder'" (click)="setActiveTab(ActiveTabs.MY_FOLDER)">
        MY FOLDERS
      </div>
    </div>
    <div class="pr-0 pr-lg-5">
      <div class="cursor-pointer sub-titles custom-tab" [ngClass]="activeTab === ActiveTabs.SHARED && 'active-folder'"
        (click)="setActiveTab(ActiveTabs.SHARED)">
        SHARED WITH ME
      </div>
    </div>
    <div class="pr-1 d-none d-md-block">
      <div class="cursor-pointer sub-titles custom-tab" [ngClass]="activeTab === ActiveTabs.UPLOAD && 'active-folder'"
        (click)="setActiveTab(ActiveTabs.UPLOAD)">
        UPLOAD
      </div>
    </div>
  </div>

  <div *ngIf="activeTab === ActiveTabs.MY_FOLDER && !selectedFolderId && !isFileUploading"
    class="py-3 pl-2 gallery-container no-scrollbar" infiniteScroll [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50" (scrolled)="fetchFolders(false)" [scrollWindow]="false">

    <div *ngIf="folders" class="row">
      <div class="px-2 pb-2 col-12 px-md-2 text-small">Folders</div>
      <div class="flex-row flex-wrap p-1 pl-2 mb-4 folder-container d-flex shadow-hover">
        <div *ngFor="let data of folders" (click)="selectFolder(data.id, data)">
          <div *ngIf="getFolderPermission(data)"
            class="mb-3 mr-3 border rounded cursor-pointer folder-item border-secondary position-relative">
            <div class="px-3 py-2 row align-items-center"
              [ngClass]="(!data.meta?.creator_email || !hasShareFolderAccess) && 'min-height-folder'">
              <div class="pl-2 pr-0 col-2 col-md-3">
                <img width="32px" src="../../../assets/img/folders/folder.svg">
              </div>
              <div class="pl-2 text-left col-10 col-md-9 pl-lg-0 pr-md-0 pr-lg-1">
                <div [ngClass]="!data.meta?.creator_email && 'pt-1'" class="single-line-text font-weight-bold">
                  {{ data.name }}
                </div>
                <div *ngIf="hasShareFolderAccess" class="mt-1 small sub-text w-100 single-line-text"
                  [title]="'Created by:' + data.meta['creator_email']">
                  {{ data.meta['creator_email'] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-4 d-flex justify-content-center align-items-center" *ngIf="fetchingFolders">
      <spinner class="custom-spinner-analytics" [size]="30" [tickness]="2">
      </spinner>
    </div>
  </div>

  <div *ngIf="activeTab === ActiveTabs.SHARED && !selectedFolderId && !isFileUploading"
    class="py-3 pl-2 gallery-container no-scrollbar" infiniteScroll [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50" (scrolled)="fetchFolders(false)" [scrollWindow]="false">

    <div *ngIf="folders" class="row">
      <div *ngIf="!hasSharedFolders" class="px-2 pb-2 px-md-3 text-small w-100">Folders</div>
      <div *ngIf="hasSharedFolders"
        class="d-flex w-100 justify-content-center align-items-center gallery-container no-scrollbar">
        No shared folders found
      </div>
      <div class="flex-row flex-wrap p-1 pl-2 mb-4 folder-container d-flex pl-md-3 shadow-hover">
        <div *ngFor="let data of folders" (click)="selectFolder(data.id, data)">
          <div *ngIf="!getFolderPermission(data)"
            class="mb-3 mr-3 border rounded cursor-pointer folder-item border-secondary position-relative">
            <div class="px-3 py-2 row align-items-center">
              <div class="pl-2 pr-0 col-2 col-md-3">
                <img width="32px" src="../../../assets/img/folders/folder.svg">
              </div>
              <div class="pl-2 text-left col-10 col-md-9 pl-lg-0 pr-md-0 pr-lg-1">
                <div [ngClass]="!data.meta?.creator_email && 'pt-1' " class="single-line-text font-weight-bold">
                  {{ data.name }}
                </div>
                <div *ngIf="hasShareFolderAccess" class="mt-1 small sub-text w-100 single-line-text"
                  [title]="'Created by:' + data.meta['creator_email']">
                  {{ data.meta['creator_email'] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-4 d-flex justify-content-center align-items-center" *ngIf="fetchingFolders">
      <spinner class="custom-spinner-analytics" [size]="30" [tickness]="2">
      </spinner>
    </div>
  </div>

  <div *ngIf="activeTab === ActiveTabs.UPLOAD && !selectedFolderId && !isFileUploading"
    class="pt-2 pb-3 overflow-hidden gallery-container no-scrollbar">
    <div class="row h-100">
      <div class="col-12">
        <beaconstac-file-drop #fileDrop class="folder-item position-relative" [(file)]="imageUploadModel.file"
          [isUploading]="imageUploadModel.isUploading" (fileChange)="loadCropper()" [maxSize]="maxSize"
          [dimension]="dimensions" [fileDropSize]="'small'" inputText="Drag file here or" [acceptTypes]="acceptedTypes" (rejectionError)="rejectionError.emit();">
        </beaconstac-file-drop>
      </div>
    </div>

    <div class="py-4 d-flex justify-content-center align-items-center" *ngIf="fetchingFolders">
      <spinner class="custom-spinner-analytics" [size]="30" [tickness]="2">
      </spinner>
    </div>
  </div>

</div>

<div *ngIf="selectedFolderId && !isFileUploading">
  <div class="pt-3 pl-0">
    <span (click)="onBackPressed()" class="mr-2 cursor-pointer text-secondary">{{ activeTab === ActiveTabs.MY_FOLDER ?
      'My Folders' : 'Shared With Me' }}</span>
    <i class="mr-2 fas fa-caret-right text-secondary" aria-hidden="true"></i>
    <span class="font-weight-bold text-secondary">{{ selectedFolder.name }}</span>
  </div>
  <div *ngIf="!fetchingMedias && !medias.length"
    class="flex-row d-flex justify-content-center align-items-center gallery-container no-scrollbar">
    <div class="">
      <div class="text-center">No results found.</div>
      <label for="upload-{{acceptedTypes?.join(',')}}" *ngIf="activeTab === ActiveTabs.MY_FOLDER"
        class="mt-1 cursor-pointer text-primary">
        <input type="file" id="upload-{{acceptedTypes?.join(',_')}}" [accept]="acceptedTypes?.join(',')" class="d-none"
          (change)="onUploadFile($event.target.files)" (click)="logEventOnProfilePicUpload()">
        Click here to upload.
      </label>
    </div>
  </div>

  <div *ngIf="medias.length" class="pb-3 pl-2 gallery-container no-scrollbar" infiniteScroll
    [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="fetchMedias()" [scrollWindow]="false">
    <div class="row">
      <div class="pr-2 mb-4 folder-container gallery">
        <div *ngFor="let media of medias; let i = index;" [ngClass]="selectedMedia?.id == media.id && 'active-grid'"
          class="p-2 m-2 rounded cursor-pointer border-secondary" [attr.id]="'media-item-' + i">

          <div class="d-flex flex-column justify-content-center align-items-center"
            [ngClass]="(media.id == deleteMediaId) && 'deleting'">
            <img *ngIf="media.content_type.includes('image')" src="{{ media.url }}" (click)="selectMedia(media, true)"
              class="gallery-grid" (load)="media.isloaded = true" loading="lazy" (dblclick)="onSelect?.emit()">
            <img *ngIf="media.content_type.includes('pdf')" src="../../../assets/img/folders/PDF.png"
              (click)="selectMedia(media, true)" class="gallery-grid" (load)="media.isloaded = true" loading="lazy" (dblclick)="onSelect?.emit()">
            <img *ngIf="media.content_type.includes('audio')" src="../../../assets/img/folders/Music.png"
              (click)="selectMedia(media, true)" class="gallery-grid" (load)="media.isloaded = true" loading="lazy" (dblclick)="onSelect?.emit()">
            <img *ngIf="media.content_type.includes('video')" src="../../../assets/img/folders/Video.png"
              (click)="selectMedia(media, true)" class="gallery-grid" (load)="media.isloaded = true" loading="lazy" (dblclick)="onSelect?.emit()">
            <img *ngIf="!media.content_type.includes('image') && !media.content_type.includes('pdf') &&
            !media.content_type.includes('audio') && !media.content_type.includes('video')"
              src="../../../assets/img/folders/Other.png" (click)="selectMedia(media, true)" class="gallery-grid"
              (load)="media.isloaded = true" loading="lazy" (dblclick)="onSelect?.emit()">
            <div class="mt-2 text-center small text-main-dark single-line-text media-name">
              {{media.name}}
            </div>
          </div>

          <div *ngIf="deleteMediaId == media.id"
            class="d-flex justify-content-center align-items-center deleteing-spinner">
            <spinner class="custom-spinner-analytics" [size]="30" [tickness]="2">
            </spinner>
          </div>
        </div>
      </div>

      <div class="py-4 d-flex justify-content-center align-items-center" *ngIf="fetchingFolders">
        <spinner class="custom-spinner-analytics" [size]="30" [tickness]="2">
        </spinner>
      </div>
    </div>
  </div>
</div>

<div *ngIf="fetchingMedias" class="py-4 d-flex justify-content-center align-items-center">
  <spinner class="custom-spinner-analytics" [size]="30" [tickness]="2">
  </spinner>
</div>

<div *ngIf="isFileUploading">
  <div class="py-4 d-flex justify-content-center align-items-center" style="margin: 64px">
    <spinner class="custom-spinner-analytics" [size]="60" [tickness]="3">
    </spinner>
  </div>
</div>

<div *ngIf="isFooterVisible" class="modal-footer modal-footer-kz modal-negative-margins">
  <span *ngIf="activeTab === ActiveTabs.MY_FOLDER" class="float-left mr-auto">
    <label for="upload-{{acceptedTypes?.join(',')}}" class="cursor-pointer text-primary font-size-17">
      <input type="file" id="upload-{{acceptedTypes?.join(',')}}" [accept]="acceptedTypes?.join(',')" class="d-none"
        (change)="onUploadFile($event.target.files)" (click)="logEventOnProfilePicUpload()">
      <i class="fas fa-upload" aria-hidden="true"></i>
      Upload
    </label>
  </span>
  <beaconstac-button class="ml-2" (onClick)="onClose?.emit();" [type]="BUTTON_TYPES.SECONDARY"
    [style]="BUTTON_STYLES.FILLED" [label]="'Cancel'"></beaconstac-button>
  <beaconstac-button *ngIf="activeTab !== ActiveTabs.UPLOAD" class="ml-2" (onClick)="onSelect?.emit()"
    [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.FILLED" [label]="'Confirm'" [disabled]="isSelectDisabled">
  </beaconstac-button>
</div>
