<section *ngIf="user$ | async; let user" class="d-md-none layout--mobile">
  <!-- Trial Upgrade Banner QR Codes -->
  <section>
      <div *ngIf="showTrialUpgradeBanner()"
           class="p-2 trial-upgrade-banner d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
              <i class="pr-2 fas fa-info-circle" aria-hidden="true" title="Free trial expires in 13 days"></i>
              <span>QR Codes expire in {{ numberOfTrialDaysQR }} days</span>
          </div>
          <button
              class="rounded btn btn-warning font-weight--600"
              (click)="openUpgradePage('sidenav-upgrade-cta')"
              aria-label="Upgrade Now">
              Upgrade Now
          </button>
      </div>
      <div *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && showAnnouncementBanner"
           class="p-2 trial-upgrade-banner d-flex align-items-center justify-content-between" style="column-gap: 4px"
           [ngStyle]="showTrialUpgradeBanner() ? {'position': 'absolute', 'top': '0'} : {}"
      >
          <div>
              📣 Important: We're restructuring our subscription plans. Some features you currently enjoy will be shifting to our Pro Plan.
              <span class="banner-text-style" style="color: #2595FF;" (click)="showDiscountModal()">Know More</span>
          </div>
          <i class="float-right fas fa-times" style="color: #16212f; font-size: 20px;" (click)="hideAnnouncementBanner()"></i>
      </div>
  </section>
  <!-- Trial Upgrade Banner Cards -->
  <section
    *ngIf="showCardsUpgradeNowButton && (subscription$ | async)?.dbcTrial && isDbcDashboard"
    class="p-2 trial-upgrade-banner d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
        <i class="pr-2 fas fa-info-circle" aria-hidden="true" title="Free trial expires in 13 days"></i>
        <span>Free trial expires in {{ numberOfTrialDaysDBC }} days</span>
    </div>
    <button
        class="rounded btn btn-warning font-weight--600"
        (click)="openUpgradePage('sidenav-upgrade-cta')"
        aria-label="Upgrade Now">
        Upgrade Now
    </button>
  </section>

  <!-- Navigation -->
  <nav class="p-3 d-flex justify-content-between align-items-center navbar">
    <div class="d-flex align-items-center container--left">
      <i *ngIf="!isSearchBarOpen && !trialFirst"  (click)="onToggleHamburgerIcon($event)"
          class="text-white fas"
          [ngClass]="isSideDrawerOpen ? 'fa-times icon-size--24' : 'fa-bars icon-size--24'"
          aria-hidden="true"
          [title]="isSideDrawerOpen ? 'Menu' : 'Close'">
      </i>
      <span *ngIf="!isSearchBarOpen && !isSideDrawerOpen && !isOnEnterprisePlan; else whitelabelLogoImage" class="navbar-brand" (click)="navigateToOverview()">
        <img src="../../assets/img/uniqode.svg" alt="uniqode"/>
      </span>
      <ng-template #whitelabelLogoImage>
        <a *ngIf="!isSearchBarOpen && !isSideDrawerOpen" class="navbar-brand" (click)="handleBeaconstacLogo()"
          style="height:24px; width: 48px; background-size: contain;" [style.backgroundImage]="'url(' + brandLogo + ')'">
        </a>
      </ng-template>
    </div>
    <div class="d-flex align-items-center">
      <button *ngIf="(currentDashboard === DASHBOARD_MENU.QRCODES ? showPlanUpgradeButtonOnQR : showPlanUpgradeButtonOnCards) && (!isSideDrawerOpen && !isSearchBarOpen)"
      class="mr-2 rounded btn btn-warning font-weight--600 text-uppercase"  (click)="openUpgradePage('sidenav-upgrade-cta')"
      aria-label="Upgrade">
      Upgrade
      </button>
      <button *ngIf="(currentDashboard === DASHBOARD_MENU.QRCODES ? !isOnTrialPlan && !isOnEnterprisePlan && isOnPlusPlan : !(subscription$ | async)?.dbcTrial && showGiftQrInviteButton) && !isSideDrawerOpen && !isSearchBarOpen"
        class="mr-2 rounded btn btn-primary font-weight--600 text-uppercase"  (click)="getMoreQrCodes()"
        [attr.aria-label]="currentDashboard === DASHBOARD_MENU.QRCODES ?  trialFirst ? 'UPGRADE NOW' : 'GET MORE CODES' : 'GET MORE CARDS'">
        {{currentDashboard === DASHBOARD_MENU.QRCODES ?  trialFirst ? 'UPGRADE NOW' : 'GET MORE CODES' : 'GET MORE CARDS'}}
      </button>
      <i *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && !isSideDrawerOpen && !isSearchBarOpen && !trialFirst" (click)="onToggleSearchIcon($event)"
        class="mr-2 text-white fas fa-search icon-size--24" aria-hidden="true" title="Search"></i>
      <i *ngIf="showTopNavInviteCollaboratorButton && !isSideDrawerOpen && !isSearchBarOpen && !trialFirst" (click)="handleAddUserClick(INVITE_TEAMMATES_TOUCHPOINTS.TOPNAV)"
        class="mr-2 text-white fas fa-user-plus icon-size--24" aria-hidden="true" title="Invite teammates"></i>
      <figure *ngIf="!isSideDrawerOpen && !isSearchBarOpen" role="img" (click)="isProfileDrawerOpen = !isProfileDrawerOpen">
        <img *ngIf="user?.profile_picture" class="img-avatar height-24" [src]="user?.profile_picture" alt="Profile Picture">
        <div *ngIf="!user.profile_picture && user.hasName()" class="img-avatar height-24 d-inline profile-name-holder" id="profile-info">
          {{user.first_name[0]}}
        </div>
        <img *ngIf="!user.profile_picture && !user.hasName()" class="img-avatar height-24" src="../../assets/img/profile-picture-placeholder.png" alt="Profile Picture">
      </figure>
      <div class="global-search-container--mobile"
        *ngIf="user && isSearchBarOpen && (this.currentDashboard === DASHBOARD_MENU.QRCODES) && !trialFirst">
        <beaconstac-global-search [isReseller]="isReseller && !isUmbrellaUser" (onToggleSearchBar)="closeSearchBar()" ></beaconstac-global-search>
      </div>
    </div>
  </nav>
  <section class="navigation-drawer--container" [ngClass]="isSideDrawerOpen ? 'open': 'close'" (click)="isSideDrawerOpen = !isSideDrawerOpen">
    <section class="pb-3 bg-white navigation-drawer" (click)="$event.stopPropagation()">

      <div *ngIf="(currentDashboard === DASHBOARD_MENU.QRCODES && qrCreateCtaVariation === QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY)"
        class="dropdown btn-dropdown mx-4 mb-4 pt-4" dropdown style="z-index: 1;">
        <button class="btn create-qr-cta-btn btn-width text-white border-0 container" dropdownToggle
        style="font-size: 20px;">
          + Create New
        </button>
        <div class="dropdown-container dropdown-menu mt-2 w-100" *dropdownMenu aria-labelledby="simple-dropdown">
          <div class="d-flex" (mouseout)="selectDropdownIcon()">
            <div class="w-100 dropdown-content py-2 cursor-pointer" (click)="toggleDrawer()">
              <div class="text-left pl-3 py-2 my-0 text-secondary dropdown-text website-url d-flex align-items-center"
                id="qr-code-cta-overview" [routerLink]="['/qr-codes/add']" [queryParams]="{orgID: currentOrganizationId}"
                (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY, 'QR')"
                (mouseover)="selectDropdownIcon(campaignTypes.QR_CODE)">
                <img [src]="dropdownIcon.qrCode" class="pr-3"><span style="font-size: 20px;">QR Code</span>
              </div>
              <div class="text-left pl-3 py-2 my-0 text-secondary dropdown-text linkpage d-flex align-items-center"
                [routerLink]="['/linkpage/templates/']" [queryParams]="{orgID: currentOrganizationId}"
                (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY, 'Linkpages')"
                (mouseover)="selectDropdownIcon(campaignTypes.LINK_PAGE)">
                <img [src]="dropdownIcon.linkpage" class="pr-3"><span style="font-size: 20px;">Linkpage</span>
              </div>
              <div class="text-left pl-3 py-2 my-0 text-secondary dropdown-text form d-flex align-items-center"
                [routerLink]="['/forms/templates']" [queryParams]="{setFormName: true, orgID: currentOrganizationId}"
                (mouseover)="selectDropdownIcon(campaignTypes.FORM)"
                (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY, 'Forms')">
                <img [src]="dropdownIcon.form" class="pr-3"><span style="font-size: 20px;" >Form</span>
              </div>
            </div>
          </div>
        </div>
      </div>



      <ul class="nav d-flex flex-column" (click)="toggleDrawer()">
      <!-- Create QR Code visibility controlled by "qrCreateCtaVariation variable when that is QR_CREATE_CTA_VARIATIONS.CREATE_QR_PRIMARY". -->
        <div *ngIf="(currentDashboard === DASHBOARD_MENU.QRCODES && qrCreateCtaVariation === QR_CREATE_CTA_VARIATIONS.CREATE_QR_PRIMARY)" class="m-4">
          <button id="qr-code-cta-list"
            [routerLink]="['/qr-codes/add']" [queryParams]="{orgID: currentOrganizationId}"
            class="flex-row float-right text-white border-0 btn create-qr-cta-btn  justify-content-center align-items-center mt-0"
            [ngClass]="w-max-content" (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_QR_PRIMARY)"
            style="font-size: 20px;"
            >
            <i class="fa fa-plus" aria-hidden="true"></i>
            Create QR Code
          </button>
        </div>


        <li class="py-3 pl-4 nav-item nav-link nav-icon" [routerLink]="['/overview']" routerLinkActive="active" (click)="logOverviewClicks()" >
          <i class="fa fa-chart-pie fa-md nav-link-icon" aria-hidden="true"></i>
          <a class="p-0 nav-link-grey"routerLinkActive="nav-item-active">Overview</a>
        </li>
        <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES" class="nav-item nav-dropdown">
          <ul class="p-0">
            <div class="px-4 d-flex justify-content-between align-items-center nav-link"  (click)="toggleSidebarMenu('qrcodes', $event)">
              <div class="py-2 d-flex justify-content-start align-items-center">
              <i class="fa fa-qrcode fa-md nav-link-icon" aria-hidden="true"></i>
              <a class="pl-1 nav-link-grey" [routerLink]="[]" [queryParams]="{orgID: currentOrganizationId}">QR Codes</a>
              </div>
              <i [@rotateToBottom]="sidebarMenu.has('qrcodes') ? 'open' : 'closed' " class="fas fa-xs fa-chevron-right text-muted"></i>
            </div>
            <li [@fadeInOut] *ngIf="sidebarMenu.has('qrcodes')">
              <ul class="p-0 nav-dropdown-items">
                <li *ngIf="qrCreateCtaVariation === QR_CREATE_CTA_VARIATIONS.CREATE_QR_TEXT" class="nav-item">
                  <a class="py-3 nav-link inner-link create-qr-code-cta-color" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" [routerLink]="['/qr-codes/add']" [queryParams]="{orgID: currentOrganizationId}" (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_QR_TEXT)">Create QR Code</a>
                </li>
                <li class="nav-item">
                  <a class="py-3 nav-link inner-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/qr-codes']" [queryParams]="{orgID: currentOrganizationId}" (click)="handleQrCodesClick()">{{qrCreateCtaVariation === QR_CREATE_CTA_VARIATIONS.CREATE_QR_TEXT ? 'My QR Codes' :'QR Codes'}}</a>
                </li>
                <li class="nav-item">
                  <a class="py-3 nav-link inner-link" routerLinkActive="active" [routerLink]="['/analytics/qr']" [queryParams]="{orgID: currentOrganizationId}">Analytics</a>
                </li>
                <li class="nav-item" *ngIf="hasTemplatePermission">
                  <a class="py-3 nav-link" routerLinkActive="active" [routerLink]="['/qr-templates']" [queryParams]="{orgID: currentOrganizationId}">
                    Templates
                    <i *ngIf="isOnTrialPlan" class="fas fa-crown" style="color: #fcbf49; font-size: 15px"></i>
                  </a>
                </li>
              </ul>
            </li>

            <li *ngIf="accessList.linkpage">
              <div class="px-4 d-flex justify-content-between align-items-center nav-link"  (click)="toggleSidebarMenu('linkpages', $event)">
                <div class="py-2 d-flex justify-content-start align-items-center">
                <i class="fas fa-list fa-md nav-link-icon" aria-hidden="true"></i>
                <a class="p-0 nav-link-grey" [routerLink]="[]" [queryParams]="{orgID: currentOrganizationId}">Linkpages</a>
                </div>
                <i [@rotateToBottom]="sidebarMenu.has('linkpages') ? 'open' : 'closed' " class="fas fa-xs fa-chevron-right text-muted"></i>
              </div>
              <ul [@fadeInOut] *ngIf="sidebarMenu.has('linkpages')" class="p-0 nav-dropdown-items">
                 <li class="nav-item">
                   <a class="py-3 nav-link inner-link" routerLinkActive="active" [routerLink]="['/linkpage']" [queryParams]="{orgID: currentOrganizationId}">Linkpages</a>
                 </li>
                 <li class="nav-item">
                   <a class="py-3 nav-link inner-link" routerLinkActive="active" [routerLink]="['/analytics/linkpage']" [queryParams]="{orgID: currentOrganizationId}">Analytics</a>
                 </li>
                 <li *ngIf="isOwner || isAdmin" class="nav-item">
                   <a class="py-3 nav-link inner-link" routerLinkActive="active" [routerLink]="['/linkpage-templates']" [queryParams]="{orgID: currentOrganizationId}">Templates</a>
                 </li>
               </ul>
            </li>

            <li>
              <div class="px-4 d-flex justify-content-between align-items-center nav-link" (click)="toggleSidebarMenu('campaigns', $event)">
                <div class="py-2 d-flex justify-content-start align-items-center">
                <i class="fa fa-bullhorn fa-md nav-link-icon" aria-hidden="true"></i>
                <a class="p-0 nav-link-grey" [routerLink]="[]" [queryParams]="{orgID: currentOrganizationId}">Campaigns</a>
                </div>
                <i [@rotateToBottom]="sidebarMenu.has('campaigns') ? 'open' : 'closed' " class="fas fa-chevron-right fa-xs text-muted"></i>
              </div>
              <ul [@fadeInOut] *ngIf="sidebarMenu.has('campaigns')" class="p-0 nav-dropdown-items">
                <li class="nav-item">
                  <a class="py-3 nav-link inner-link" routerLinkActive="active" [routerLink]="['/landing-pages']" [queryParams]="{orgID: currentOrganizationId}">
                    Landing Pages
                    <i *ngIf="isOnTrialPlan" class="fas fa-crown" style="color: #fcbf49; font-size: 15px"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="py-3 nav-link inner-link" routerLinkActive="active" [routerLink]="['/forms']" [queryParams]="{orgID: currentOrganizationId}">Forms</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li *ngIf="isDbcDashboard" class="nav-item nav-dropdown">
          <ul class="p-0">
              <ng-container *ngTemplateOutlet="cardsNavLinks; context: { aTagCardsClass: 'py-3 pl-4' }" id="mob-side-nav-card-links"></ng-container>
              <ng-container *ngTemplateOutlet="analyticsLinksDBC; context: {aTagAnalyticsClass: 'py-3 pl-4'}"></ng-container>
            <li *ngIf="dbcNavItemsPermissions.contactManager" class="py-3 pl-4 nav-item nav-link nav-icon" routerLinkActive="active" [routerLink]="['/contact-manager']" [queryParams]="{orgID: currentOrganizationId}"id="cards-contact-manager">
              <i class="far fa-address-book fa-md nav-link-icon" aria-hidden="true"></i>
              <a class="pl-1 nav-link-grey"routerLinkActive="nav-item-active">Contact Manager
              </a>
            </li>
          </ul>
        </li>


          <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES" class="py-3 pl-4 nav-item nav-link nav-icon" routerLinkActive="active" [routerLink]="['/asset-library']" [queryParams]="{orgID: currentOrganizationId}" >
            <i class="far fa-folder-open fa-md nav-link-icon" aria-hidden="true"></i>
            <a class="p-0 nav-link-grey"routerLinkActive="nav-item-active">Asset Library</a>
          </li>

        <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.assetLib" class="py-3 pl-4 nav-item nav-link nav-icon" routerLinkActive="active" [routerLink]="['/asset-library']" [queryParams]="{orgID: currentOrganizationId}">
          <i class="far fa-folder-open fa-md nav-link-icon" aria-hidden="true"></i>
          <a class="p-0 nav-link-grey"routerLinkActive="nav-item-active">Asset Library</a>
        </li>
        <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.template" class="nav-item nav-dropdown">
          <div class="px-4 d-flex justify-content-between align-items-center nav-link" (click)="$event.stopPropagation()">
            <div class="py-2 d-flex justify-content-start align-items-center">
            <i class="far fa-images fa-md nav-link-icon" aria-hidden="true"></i>
            <a class="p-0 nav-link-grey" [routerLink]="[]" (click)="toggleSidebarMenu('templates', $event)" [queryParams]="{orgID: currentOrganizationId}">Template</a>
          </div>
          <i [@rotateToBottom]="sidebarMenu.has('templates') ? 'open' : 'closed' " class="fas fa-xs fa-chevron-right text-muted"></i>
          </div>
          <ul [@fadeInOut] *ngIf="sidebarMenu.has('templates')" class="p-0 nav-dropdown-items">
            <li class="nav-item" *ngIf="isAdmin">
              <a *ngIf="hasCardsTemplateAccess" class="py-3 nav-link inner-link" routerLinkActive="active" [routerLink]="['/digital-business-cards/templates']" [queryParams]="{orgID: currentOrganizationId}">Business Cards</a>
              <a *ngIf="!hasCardsTemplateAccess" class="py-3 nav-link inner-link" (click)="showUpsellModel()">Business Cards <img class="pr-2 mb-1" src="../../assets/logo/crown-solid.svg"></a>
            </li>
            <li class="nav-item">
              <a class="py-3 nav-link inner-link" *ngIf="hasTemplatePermission" routerLinkActive="active" [routerLink]="['/qr-templates']" [queryParams]="{orgID: currentOrganizationId}">QR Codes</a>
            </li>
            <li class="nav-item" *ngIf="isAdmin">
              <a class="py-3 nav-link inner-link" routerLinkActive="active" [routerLink]="['/wallet-pass-template']" [queryParams]="{orgID: currentOrganizationId}">Wallet Passes</a>
            </li>
          </ul>
        </li>

        <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.template" class="py-3 pl-4 nav-item nav-link nav-icon" routerLinkActive="active" [routerLink]="['/digital-business-cards/settings']" [queryParams]="{orgID: currentOrganizationId}" id="mob-set">
          <i class="far fa-cog fa-md nav-link-icon" aria-hidden="true"></i>
          <a class="p-0 nav-link-grey"routerLinkActive="nav-item-active">Settings</a>
        </li>

        <li *ngIf="navBarItems.integrations" class="py-3 pl-4 nav-item nav-link nav-icon" routerLinkActive="active" [routerLink]="['/integrations']"[queryParams]="{orgID: currentOrganizationId}" >
          <i class="fa fa-network-wired fa-md nav-link-icon" aria-hidden="true"></i>
          <a class="p-0 nav-link-grey"routerLinkActive="nav-item-active">Integrations</a>
        </li>

        <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && navBarItems.api" class="py-3 pl-4 nav-item nav-link nav-icon" routerLinkActive="active" [routerLink]="['/api']" [queryParams]="{orgID: currentOrganizationId}">
          <i class="fa fa-code fa-md nav-link-icon" aria-hidden="true"></i>
          <a class="p-0 nav-link-grey" routerLinkActive="nav-item-active">API</a>
        </li>

          <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.api" class="py-3 pl-4 nav-item nav-link nav-icon" routerLinkActive="active" [routerLink]="['/api']" [queryParams]="{orgID: currentOrganizationId}">
            <i class="fa fa-code fa-md nav-link-icon" aria-hidden="true"></i>
            <a class="p-0 nav-link-grey" routerLinkActive="nav-item-active">API</a>
          </li>

        <li *ngIf="isAdminDashboard" class="py-3 pl-4 nav-item nav-link nav-icon" routerLinkActive="active" [routerLink]="['/request-logs']" [queryParams]="{orgID: currentOrganizationId}">
          <i class="fa fa-history fa-md nav-link-icon" aria-hidden="true"></i>
          <a class="p-0 nav-link-grey" routerLinkActive="nav-item-active">Logs</a>
        </li>
        <li class="mb-4">
          <div class="px-4 d-flex justify-content-between align-items-center nav-link" (click)="toggleSidebarMenu('help', $event)">
            <div class="py-2 d-flex justify-content-start align-items-center">
            <i class="far fa-question-circle fa-md nav-link-icon" aria-hidden="true"></i>
            <a class="p-0 nav-link-grey" [routerLink]="[]" [queryParams]="{orgID: currentOrganizationId}">Help</a>
          </div>
          <i [@rotateToBottom]="sidebarMenu.has('help') ? 'open' : 'closed' " class="fas fa-xs fa-chevron-right text-muted"></i>
          </div>

          <ul [@fadeInOut] *ngIf="sidebarMenu.has('help')" class="p-0 nav-dropdown-items">
            <li class="nav-item">
              <a class="py-3 nav-link inner-link" routerLinkActive="active" href="#" (click)="startNewIntercomConversation();">Live support</a>
            </li>
            <li class="nav-item">
              <a class="py-3 nav-link inner-link" href="#" (click)="redirectFromHelpSection(HelpMenuOptions.HELP_CENTER)">Help center</a>
            </li>
            <li class="nav-item">
              <a class="py-3 nav-link inner-link" href="#" (click)="redirectFromHelpSection(HelpMenuOptions.BLOGS)">Blogs</a>
            </li>
            <li class="nav-item">
              <a class="py-3 nav-link inner-link" href="#" (click)="redirectFromHelpSection(HelpMenuOptions.VIDEOS)">Videos</a>
            </li>
            <li class="nav-item">
              <a class="py-3 nav-link inner-link" href="#" (click)="redirectFromHelpSection(HelpMenuOptions.WHATS_NEW)">What's new</a>
            </li>
            <li class="nav-item">
              <a class="py-3 nav-link inner-link" routerLinkActive="active" href="#" (click)="startNewIntercomConversation();">Request a feature</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="p-0 px-4">
        <button
          *ngIf="showProductSwitcher"
          (click)="currentDashboard === DASHBOARD_MENU.QRCODES ? toggleDashboard(dashboardMenu[1]) : toggleDashboard(dashboardMenu[0])"
          class="product-switcher-button text-muted d-flex justify-content-center align-items-center w-100"
          [ngClass]="currentDashboard === DASHBOARD_MENU.QRCODES ? isOnTrialPlan ? 'bottom-118' : 'bottom-72' : 'bottom-8'">
          <i class="mr-2 fas fa-id-badge text-primary fa-lg"></i>
          Switch to {{ currentDashboard === DASHBOARD_MENU.QRCODES ? 'Cards': 'QR Codes' }}
        </button>
      </ul>
    </section>
  </section>

  <beaconstac-mobile-drawer [isFullScreen]="true" [isDrawerOpen]="isNotificationsDrawerOpen" (onOutsideClick)="isNotificationsDrawerOpen = !isNotificationsDrawerOpen">
    <div class="pb-2 text-uppercase font-weight-bold" style="color: #979797;">
      <i class="mr-2 fas fa-long-arrow-left fa-lg" (click)="goBackToProfileMenu()"></i>
      Notifications
    </div>
    <section class="pr-2 notifications-container d-flex flex-column">
      <a *ngFor="let notification of userNotifications" class="p-2 notification-item text-decoration-none"
          [href]="notification.link ? notification.link : '#'"
          [target]="(notification.id === NOTIFICATIONS_IDS.QR_UPGRADE || notification.id === NOTIFICATIONS_IDS.CARDS_UPGRADE) ? '_blank' : notification._isInternalLink ? '' : '_blank'"
          (click)="notification.onClick($event);">
        <div class="message">
          <div class="text-truncate">
            <span class="fas fa-circle" style="font-size: 10px" [ngStyle]=" { color : notification.read ? 'grey' : '#3db33d' }"></span>
            <span title="{{notification.title}}" class="ml-2">{{notification.title}}</span>
          </div>
          <div class="ml-3 text-muted" title="{{notification.message}}"><span>{{notification.message}}</span></div>
          <div style="font-style: italic; margin-left: 14px">
            <small class="mt-1 text-muted">{{notification.readableCreatedDate}}</small>
          </div>
        </div>
      </a>
    </section>
    <div *ngIf="!userNotifications || userNotifications.length === 0" class="p-3 text-center message">
      <div>No new notifications, please check back later.</div>
    </div>
    <div *ngIf="userNotifications?.length" class="mt-2 text-center mark-as-read--button text-secondary" (click)="markAllNotificationsAsRead()">
      Mark all as read
    </div>
  </beaconstac-mobile-drawer>

  <beaconstac-mobile-drawer [isFullScreen]="isOrgSwitcherOpen" [isDrawerOpen]="isProfileDrawerOpen" (onOutsideClick)="isProfileDrawerOpen = !isProfileDrawerOpen">
    <div class="" *ngIf="totalOrgsCount > 1 || isOnEnterprisePlan">
      <div *ngIf="isOrgSwitcherOpen" class="pb-2 font-weight-bold org-swither--header">
        <i class="mr-2 fas fa-long-arrow-left fa-lg" (click)="goBackToProfileMenuFromOrg()"></i>
        YOUR ORGANIZATIONS
      </div>
      <div *ngIf="!isOrgSwitcherOpen" class="p-3 mb-3 d-flex align-items-center nav-teams" (click)="toggleOrgSwitcher()">
        <i class="mr-2 fas fa-building text-muted" title="Organizations"></i>
        <span class="organization text-muted text-truncate" *ngIf="currentOrg" title="{{currentOrg.name}}">{{currentOrg.name}}</span>
        <i *ngIf="totalOrgsCount > 1" [@rotate]="isOrgSwitcherOpen ? 'open' : 'closed'" class="ml-auto fas fa-caret-down fa-lg" aria-hidden="true"></i>
      </div>
      <div *ngIf="isOrgSwitcherOpen" class="pt-3">
        <div *ngIf="totalOrgsCount > 8" class="pb-3">
          <beaconstac-search [searchPlaceholder]="'Search'" [id]="'organization'" class="hide-icon" (textChange)="searchOrganization($event)" (click)="$event.stopPropagation()"></beaconstac-search>
        </div>

          <div *ngIf="hasAccessToMasterOrg() && showMasterOrgSection" class="pb-2">
              <ng-container *ngTemplateOutlet="masterOrgTemplate" id="org-full-screen-drawer"></ng-container>
          </div>

          <div *ngIf="filteredOrgList.length">
              <div *ngIf="hasAccessToMasterOrg()" class="switch-container">
                  <span class="switch-heading">OTHER ORGANIZATIONS</span>
              </div>
              <div class="org-option-list">
                  <beaconstac-options *ngIf="filteredOrgList" [maxWidth]="'-webkit-fill-available'"
                                      [value]="currentOrg"
                                      (valueChange)="onOrgChanged($event)"
                                      [maxHeight]="'420px'"
                                      [highlightSelectedValue]="false"
                                      [optionLabelField]="'name'"
                                      [optionsType]="2"
                                      [optionSecondaryLabelField]="'role'"
                                      [optionValueField]="'id'"
                                      [listItems]="filteredOrgList"
                                      [enableInfiniteScrolling]="true"
                                      (fetchMoreData)="getMoreData()"
                                      [isFetchingData]="fetchingOrganizationsOnScroll"></beaconstac-options>
              </div>
          </div>
      </div>
    </div>
    <div *ngIf="!isOrgSwitcherOpen">
      <ul *ngIf="trialFirst; else menuOptionsBlock" class="p-0 m-0">
        <li
          *ngFor="let menuOption of accountMenuOptionsForNewUserExp"
          class="pl-0 border-0 list-group-item text-muted d-flex align-items-center"
          (click)="selectOption(menuOption)">{{menuOption.name}}
          <i *ngIf="menuOption.id === menuOptions.NOTIFICATIONS && unreadNotificationCount" class="pl-2 fas fa-circle main-error icon-size--12"></i>
        </li>
      </ul>
      <ng-template #menuOptionsBlock>
        <ul class="p-0 m-0">
          <li
            *ngFor="let menuOption of accountMenuOptions"
            class="pl-0 border-0 list-group-item text-muted d-flex align-items-center"
            (click)="selectOption(menuOption)">{{menuOption.name}}
            <i *ngIf="menuOption.id === menuOptions.NOTIFICATIONS && unreadNotificationCount" class="pl-2 fas fa-circle main-error icon-size--12"></i>
          </li>
        </ul>
      </ng-template>
    </div>
 </beaconstac-mobile-drawer>
 </section>

<section>
  <header>
      <div class="px-4 d-none d-md-block app-header navbar-banner banner-font-size" style = "background-color: #FFF0C8; z-index: 999"*ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && showAnnouncementBanner">
          📣 Important: We're restructuring our subscription plans. Some features you currently enjoy will be shifting to our Pro Plan.
          <span class="banner-text-style" style="color: #2595FF;" (click)="showDiscountModal()">Know More</span>
          <i class="float-right fas fa-times" style="color: #16212f; font-size: 20px;" (click)="hideAnnouncementBanner()"></i>
      </div>
      <div class="d-none d-md-block app-header navbar-banner banner-font-size" style = "background-color: #E2F8EC; z-index: 998"*ngIf="showBillingNavbar">
          Your billing information is incomplete! &nbsp;
          <span class="banner-text-style" (click)="setupModalToCollectBillingAddress()">Update Now</span>
      </div>
  </header>

  <header class="d-none d-md-block app-header navbar" [ngStyle]="{'margin-top': showBillingNavbar || showAnnouncementBanner ? '58px' : '0px'}">
    <div class="d-flex justify-content-between"style="width: 100%; height: 100%;">
      <div class="d-flex align-items-center nav-left" style="min-width: 80px;">
        <button *ngIf="!trialFirst" class="navbar-toggler d-lg-none" type="button" #navDrawer appMobileSidebarToggler>&#9776;</button>
          <a class="navbar-brand d-md-down-none" (click)="handleBeaconstacLogo()"
             style="border-left: 0 solid transparent; height: 25px; width: 118px; margin: 25px 8px 25px 25px; background-size: contain"
             [style.backgroundImage]="'url(' + brandLogo + ')'"></a>
          <div *ngIf="showProductSwitcher && !trialFirst" class="ml-3 new-tab-product-switcher">
              <div style="width: 29px; border: 1px solid #5E6980; transform: rotate(90deg);"></div>
          </div>
          <!--        New product switcher-->
          <div *ngIf="showProductSwitcher" class="ml-3 new-tab-product-switcher" style="background-color: unset !important;">
              <span
                  *ngIf="!trialFirst"
                  class="product-nav qr-product-nav"
                  [ngClass]="currentDashboard === DASHBOARD_MENU.QRCODES ? 'product-nav-active' : 'product-nav-inactive'"
                  (click)="toggleDashboard(dashboardMenu[0])">QR Codes</span>
              <span
                  *ngIf="!trialFirst"
                  class="product-nav dbc-product-nav"
                  [ngClass]="isDbcDashboard ? 'product-nav-active' : 'product-nav-inactive'"
                  (click)="toggleDashboard(dashboardMenu[1])">Cards
                  <span style="margin-left: 6px;" class="new-label">NEW</span>
              </span>
          </div>

          <!--        Old product Switcher-->
          <span *ngIf="showProductSwitcher" style="background-color: unset !important;"
               class="old-tab-product-switcher dropdown navheader-link-sm sm-and-below-position-unset product-dropdown" dropdown id="product-switcher">
              <a href class="d-flex align-items-center nav-teams" dropdownToggle (click)="false" style="color: white; padding: 0; margin-top: 2px;">
            <span class="dashboard-menu d-md-down-none" *ngIf="currentOrg" style="margin-left: 4px; margin-right: 4px">
                  <span style="font-weight: 500; font-size: 18px; line-height: 23px" *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES">QR Codes</span>
                  <span style="font-weight: 500; font-size: 18px; line-height: 23px" *ngIf="isDbcDashboard">Cards</span>
                </span>
                  <i class="fas fa-caret-down" aria-hidden="true" style="font-size: 20px; margin-left: 5px;"></i>
              </a>
              <div style="border: unset;"
                   class="dropdown-menu product-switcher-dropdown dropdown-menu-right"
                   *dropdownMenu aria-labelledby="simple-dropdown">
                  <beaconstac-options [maxWidth]="'-webkit-fill-available'"
                                      [value]="currentDashboard === DASHBOARD_MENU.QRCODES ? dashboardMenu[0] : dashboardMenu[1]"
                                      (valueChange)="toggleDashboard($event)"
                                      [maxHeight]="'265px'"
                                      [highlightSelectedValue]="false"
                                      [optionLabelField]="'name'"
                                      [optionsType]="2"
                                      [optionValueField]="'value'"
                                      [listItems]="dashboardMenu"
                                      [enableInfiniteScrolling]="false"></beaconstac-options>

              </div>
              </span>

<!-- Top nav upgrade buttons when side nav is in collapsed state -->
              <div *ngIf="(isSideNavCollapsed && (isDbcDashboard && showCardsUpgradeNowButton) ) || (isSideNavCollapsed &&  (currentDashboard === DASHBOARD_MENU.QRCODES && showQrUpgradeNowButton)) " class="d-flex justify-content-center align-items-center ml-2">
                <div *ngIf="(subscription$ |async); let subscription;">
                  <!--Special upgrade section for starter trail-->
                  <div class="text-center top-nav-upgrade">
                    <div *ngIf="isDbcDashboard && showCardsUpgradeNowButton" class="mx-auto my-0 nav-item">
                      <!--            Black Friday-->
                      <!--            <div *ngIf="!subscription.isStarterTrial && !isOnPlusPlan" style="font-style: italic;font-weight: 700; font-size: 14px; line-height: 16px; color: #16212F; margin-bottom: 8px">-->
                      <!--              <img width="16px" style="margin-bottom: 3px; margin-right: 8px" height="16px" src="assets/img/black-friday-discount.png"> <span>BLACK FRIDAY OFFER!</span>-->
                      <!--            </div>-->
                      <a class="cursor-pointer text-uppercase" (click)="openUpgradePage('sidenav-upgrade-cta')"> Upgrade Now</a>
                    </div>
                    <div *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && showQrUpgradeNowButton" class="mx-auto my-0 nav-item">
                      <a class="cursor-pointer text-uppercase" (click)="openUpgradePage('sidenav-upgrade-cta')"> Upgrade Now</a>
                    </div>
                  </div>
                </div>
              </div>

          </div>
      <div *ngIf="user$ | async; let user" class="d-flex align-items-center nav-right">
          <div class="global-search-container" *ngIf="user && (this.currentDashboard === DASHBOARD_MENU.QRCODES)  && !trialFirst">
            <beaconstac-global-search [isReseller]="isReseller && !isUmbrellaUser"></beaconstac-global-search>
          </div>

          <div *ngIf="!trialFirst" class="dropdown navheader-link" dropdown>
            <a href dropdownToggle (click)="false">
              <i class="text-white far fa-bell" style="font-size: 16px;" title="Notifications"></i>
              <span *ngIf="unreadNotificationCount > 0" class="badge notifications-badge">
                <span></span>
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right notifications" *dropdownMenu aria-labelledby="simple-dropdown">
              <div class="dropdown-header">
                <strong>Notifications</strong>
              </div>
              <a *ngFor="let notification of userNotifications" class="dropdown-item"
                 [href]="notification.link ? notification.link : '#'"
                 [target]="(notification.id === NOTIFICATIONS_IDS.QR_UPGRADE || notification.id === NOTIFICATIONS_IDS.CARDS_UPGRADE) ? '_blank' : notification._isInternalLink ? '' : '_blank'"
                 (click)="notification.onClick($event);">
                <div class="message">
                    <div style="display: flex">
                        <span class="fas fa-circle" style="font-size: 10px; line-height: 1.3rem; " [ngStyle]=" { color : notification.read ? 'grey' : '#3db33d' }"></span>
                        <div style="display: flex; flex-direction: column; margin-left: 10px; gap: 4px;">
                            <b><span title="{{notification.title}}" >{{notification.title}}</span></b>
                            <div class="text-muted" title="{{notification.message}}"><span>{{notification.message}}</span></div>
                            <small style="font-style: italic;" class="text-muted">{{notification.readableCreatedDate}}</small>
                        </div>
                    </div>
                </div>
              </a>
              <div *ngIf="!userNotifications || userNotifications.length === 0" class="p-3 text-center message">
                <div>No new notifications, please check back later.</div>
              </div>
              <div *ngIf="userNotifications?.length >0" class="text-center dropdown-header dropdown-header-clickable" (click)="markAllNotificationsAsRead()">
                Mark all as read
              </div>
            </div>
          </div>

          <div *ngIf="showTopNavInviteCollaboratorButton && !trialFirst">
            <i class="text-white cursor-pointer fa fa-solid fa-user-plus navheader-link" (click)="handleAddUserClick(INVITE_TEAMMATES_TOUCHPOINTS.TOPNAV)" title="Invite teammates"></i>
          </div>

          <div *ngIf="totalOrgsCount > 1 || isOnEnterprisePlan" class="dropdown navheader-link-sm sm-and-below-position-unset p-2" dropdown [(isOpen)]="isDropdownOpen">
            <a href class="text-white d-flex align-items-center nav-teams justify-content-end" dropdownToggle (click)="false">
              <i class="fas fa-building d-lg-none" title="Organizations" style="font-size: 20px;"></i>
              <span class="d-md-down-none organization text-truncate " *ngIf="currentOrg" title="{{currentOrg.name}}">{{ currentOrg.name | slice: 0:10 }}{{ currentOrg.name.length > 10 ? '...' : '' }}</span>
              <i [@rotate]="isDropdownOpen ? 'open' : 'closed'" class="fas fa-angle-down d-md-down-none" aria-hidden="true" style="font-size: 20px; margin-left: 5px;"></i>
            </a>
            <div class="border-0 dropdown-menu dropdown-menu-right sm-and-below-full-width-options py-3 use-shadow" style="max-height: 300px; width: 388px" *dropdownMenu aria-labelledby="simple-dropdown">
              <div class="switch-container">
                <span class="switch-heading">YOUR ORGANIZATIONS</span>
              </div>

              <div *ngIf="totalOrgsCount > 8" class="switch-container">
                <beaconstac-search [id]="'organization'" class="hide-icon" (textChange)="searchOrganization($event)" (click)="$event.stopPropagation()"></beaconstac-search>
              </div>

                <div *ngIf="hasAccessToMasterOrg() && showMasterOrgSection" class="pb-2">
                    <ng-container id="old-msg" *ngTemplateOutlet="masterOrgTemplate"></ng-container>
                </div>

                <div *ngIf="filteredOrgList.length > 0">
                    <div *ngIf="hasAccessToMasterOrg()" class="switch-container">
                        <span class="switch-heading">OTHER ORGANIZATIONS</span>
                    </div>
                    <div class="org-option-list use-shadow">
                        <beaconstac-options *ngIf="filteredOrgList" [maxWidth]="'-webkit-fill-available'"
                                            [value]="currentOrg"
                                            (valueChange)="onOrgChanged($event)"
                                            [maxHeight]="'265px'"
                                            [highlightSelectedValue]="false"
                                            [optionLabelField]="'name'"
                                            [optionsType]="2"
                                            [optionSecondaryLabelField]="'role'"
                                            [optionValueField]="'id'"
                                            [listItems]="filteredOrgList"
                                            [enableInfiniteScrolling]="true"
                                            (fetchMoreData)="getMoreData()"
                                            [isFetchingData]="fetchingOrganizationsOnScroll"></beaconstac-options>
                    </div>
                </div>

            </div>
          </div>

          <div *ngIf="showGiftQrInviteButton" class="bx--md-down-display-none">
            <button class="btn btn-primary gift-qrcode" (click)="getMoreQrCodes()" style="text-transform: none; border-radius: 2px;">
              <div class="gift-span" >
                {{currentDashboard === DASHBOARD_MENU.QRCODES ?  trialFirst ? 'UPGRADE NOW' : 'GET MORE CODES' : 'GET MORE CARDS'}}
              </div>
            </button>
          </div>

          <div class="dropdown navheader-link" dropdown>
            <a href dropdownToggle (click)="false">
              <img *ngIf="user.profile_picture" class="img-avatar" [src]="user.profile_picture" style="height: 24px; min-width: 24px;" alt="">
              <div *ngIf="!user.profile_picture && user.hasName()" class="img-avatar d-inline profile-name-holder" id = "profile-info">
                {{user.first_name[0]}}{{user.last_name[0]}}
              </div>
              <img *ngIf="!user.profile_picture && !user.hasName()" class="img-avatar" src="../../assets/img/profile-picture-placeholder.png" style="height: 24px; min-width: 24px;" alt="">
            </a>
            <div class="dropdown-menu dropdown-menu-right menu-options min-width-12" *dropdownMenu aria-labelledby="simple-dropdown">

              <beaconstac-options [value]="dropdownValue"
                                  (valueChange)="selectOption($event)"
                                  [maxWidth]="'auto'"
                                  [maxHeight]="'max-content'"
                                  [highlightSelectedValue]="false"
                                  [optionLabelField]="'name'"
                                  [optionValueField]="'id'"
                                  [listItems]="trialFirst ? accountMenuOptionsForNewUserExp : accountMenuOptions"></beaconstac-options>
            </div>
          </div>
      </div>
    </div>
  </header>

    <div class=" d-none d-md-block app-body" [ngStyle]="{'margin-top': showBillingNavbar || showAnnouncementBanner ? '116px' : '56px'}" (swiperight)="showSidebar()" (swipeleft)="hideSidebar()">

    <div *ngIf="!isSideNavCollapsed" class="sidebar d-flex sidebar-optimized" appSidebarOpener [pinSidebar]="pinSidebar" (click)="openSidebar()" [ngClass]="{'sidebar-collapsed':isSideNavCollapsed}">

            <!-- BUTTON FOR COLLAPSIBLE SIDE NAV ON DESKTOP -->
            <div *ngIf="showCollapsibleSideNav && !isSideNavCollapsed" (click)="toggleSideNav()">
                <div class="collapsible-icon" [ngClass]="{'cta-spacing': (currentDashboard === DASHBOARD_MENU.QRCODES && qrCreateCtaVariation === QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY)}">
                <span class="custom-tooltip">Collapse menu</span>
                  <i class="fas fa-chevron-left" aria-hidden="true" style="font-weight: 900;"></i>
              </div>
          </div>

        <nav *ngIf="user$ | async; let user" class="sidebar-nav sidebar-nav-optimized"
            appSidebarDropdown
            [ngClass]="{'nav-margin-top' : showBillingNavbar || showAnnouncementBanner}">
          <div class="d-lg-none">
            <div class="d-flex justify-content-between" style="height: 56px; padding: 20px 24px;">
              <img [src]="user.organization.whitelabel_logo_small ? brandLogo : brandLogoSidebar" alt="">
              <i class="fas fa-times" aria-hidden="true" (click)="hideSidebar();"></i>
            </div>
          </div>

          <!--Sidebar for users having QR product / QR and dbc product-->
          <ul *ngIf="hasOnlyQrAccess" [ngClass]="isOnTrialPlan && isOwner && (currentDashboard === DASHBOARD_MENU.QRCODES) ? 'custom-height' : ''" class="nav">
            <!-- Buttons for Create QR Code -->
            <button *ngIf="(currentDashboard === DASHBOARD_MENU.QRCODES && qrCreateCtaVariation === QR_CREATE_CTA_VARIATIONS.CREATE_QR_PRIMARY)" id="qr-code-cta-list"
              [routerLink]="['/qr-codes/add']" [queryParams]="{orgID: currentOrganizationId}"
              class="flex-row float-right text-white border-0 btn create-qr-cta-btn d-flex justify-content-center align-items-center mt-3 mb-3 mr-4 ml-4"
              [ngClass]="w-max-content" (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_QR_PRIMARY)">
              <i class="fa fa-plus" aria-hidden="true"></i>
              Create QR Code
            </button>
            <div *ngIf="(currentDashboard === DASHBOARD_MENU.QRCODES && qrCreateCtaVariation === QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY)"
              class="dropdown btn-dropdown mx-4 my-3" dropdown>
              <button class="btn create-qr-cta-btn btn-width text-white border-0 w-100" dropdownToggle>
                + Create New
              </button>
              <div class="dropdown-container dropdown-menu mt-1 w-100" *dropdownMenu aria-labelledby="simple-dropdown">
                <div class="d-flex" (mouseout)="selectDropdownIcon()">
                  <div class="w-100 dropdown-content py-2 cursor-pointer" style="line-height: 28px;">
                    <div class="text-left pl-3 py-1 my-0 text-secondary dropdown-text website-url d-flex align-items-center"
                      id="qr-code-cta-overview" [routerLink]="['/qr-codes/add']" [queryParams]="{orgID: currentOrganizationId}"
                      (mouseover)="selectDropdownIcon(campaignTypes.QR_CODE)"
                      (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY, 'QR')">
                      <img [src]="dropdownIcon.qrCode" class="pr-3"><span>QR Code</span>
                    </div>
                    <div class="text-left pl-3 py-1 my-0 text-secondary dropdown-text linkpage d-flex align-items-center"
                      [routerLink]="['/linkpage/templates/']" [queryParams]="{orgID: currentOrganizationId}"
                      (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY, 'Linkpages')"
                      (mouseover)="selectDropdownIcon(campaignTypes.LINK_PAGE)">
                      <img [src]="dropdownIcon.linkpage" class="pr-3"><span>Linkpage</span>
                    </div>
                    <div class="text-left pl-3 py-1 my-0 text-secondary dropdown-text form d-flex align-items-center"
                      [routerLink]="['/forms/templates']" [queryParams]="{setFormName: true, orgID: currentOrganizationId}"
                      (mouseover)="selectDropdownIcon(campaignTypes.FORM)"
                      (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY, 'Forms')">
                      <img [src]="dropdownIcon.form" class="pr-3"><span>Form</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <li class="nav-item nav-item-expanded">
              <a class="nav-link custom-padding-overview-cta" [routerLink]="['/overview']" routerLinkActive="active" (click)="logOverviewClicks()"
              ><i class="fa fa-chart-pie fa-lg" aria-hidden="true"></i>Overview</a>
            </li>

            <!-- DBC -->
            <ng-container *ngTemplateOutlet="DBC_Mycards_Orgcards_Analytics"></ng-container>
            <!-- DBC ends -->

            <!-- Leads -->
            <li *ngIf="isDbcDashboard" class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/contact-manager']" [queryParams]="{orgID: currentOrganizationId}" id="cards-contact-manager" style="position: relative;">
                <i class="far fa-address-book fa-lg"></i>
                Contact Manager
            </a>
            </li>
            <!-- Leads Ends -->

            <!-- QR Codes -->
            <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES" class="nav-item nav-dropdown" [ngClass]="toggleQRCodes ? '' : 'open'">
              <a class="nav-link nav-dropdown-toggle" [routerLink]="[]" (click)="this.toggleQRCodes = !this.toggleQRCodes;" [queryParams]="{orgID: currentOrganizationId}"> <span><i class="fas fa-qrcode fa-lg" aria-hidden="true"></i> </span> QR Codes</a>
              <ul class="nav-dropdown-items">
                <li *ngIf="qrCreateCtaVariation === QR_CREATE_CTA_VARIATIONS.CREATE_QR_TEXT" class="nav-item">
                  <a class="nav-link inner-link create-qr-code-cta-color" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" [routerLink]="['/qr-codes/add']" [queryParams]="{orgID: currentOrganizationId}" (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_QR_TEXT)">Create QR Code</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" [routerLink]="['/qr-codes']" [queryParams]="{orgID: currentOrganizationId}" (click)="handleQrCodesClick()">
                    {{qrCreateCtaVariation === QR_CREATE_CTA_VARIATIONS.CREATE_QR_TEXT ? 'My QR Codes' :'QR Codes'}}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/analytics/qr']" [queryParams]="{orgID: currentOrganizationId}" id="sidebar-analytics-non-starter">Analytics</a>
                </li>
                <li class="nav-item" *ngIf="hasTemplatePermission">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/qr-templates']" [queryParams]="{orgID: currentOrganizationId}">
                    Templates
                    <i *ngIf="isOnTrialPlan" class="fas fa-crown" style="color: #fcbf49; font-size: 12px"></i>
                  </a>
                </li>
              </ul>
            </li>
            <!-- QR Codes ends -->

            <!-- Linkpages -->
           <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && accessList.linkpage" class="nav-item nav-dropdown" [ngClass]="toggleLinkPages ? '' : 'open'">
             <a class="nav-link nav-dropdown-toggle" [routerLink]="[]" (click)="this.toggleLinkPages = !this.toggleLinkPages;" [queryParams]="{orgID: currentOrganizationId}">
              <i class="fas fa-list fa-lg" aria-hidden="true"></i>
              Linkpages
            </a>
             <ul class="nav-dropdown-items">
               <li class="nav-item">
                 <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/linkpage']" [queryParams]="{orgID: currentOrganizationId}">Linkpages</a>
               </li>
               <li class="nav-item">
                 <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/analytics/linkpage']" [queryParams]="{orgID: currentOrganizationId}">Analytics</a>
               </li>
               <li *ngIf="isOwner || isAdmin" class="nav-item">
                 <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/linkpage-templates']" [queryParams]="{orgID: currentOrganizationId}">Templates</a>
               </li>
             </ul>
           </li>

            <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && (accessList.markdownCard || accessList.form)" class="nav-item nav-dropdown"
                [ngClass]="toggleCampaigns ? '' : 'open'">
              <a class="nav-link nav-dropdown-toggle" (click)="this.toggleCampaigns = !this.toggleCampaigns;"
                 [routerLink]="[]" [queryParams]="{orgID: currentOrganizationId}">
                 <i class="fas fa-bullhorn fa-lg"></i>
                 Campaigns</a>
              <ul class="nav-dropdown-items">
                <li *ngIf="accessList.markdownCard" class="nav-item">
                  <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/landing-pages']" [queryParams]="{orgID: currentOrganizationId}">
                    Landing Pages
                    <i *ngIf="isOnTrialPlan" class="fas fa-crown" style="color: #fcbf49; font-size: 12px"></i>
                  </a>
                </li>
                <li *ngIf="accessList.form" class="nav-item">
                  <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/forms']" [queryParams]="{orgID: currentOrganizationId}">Forms</a>
                </li>
              </ul>
            </li>

            <li class="nav-item" *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/asset-library']" [queryParams]="{orgID: currentOrganizationId}">
                <i class="far fa-folder-open fa-lg"></i>
                Asset Library
              </a>
            </li>

              <li class="nav-item" *ngIf="isDbcDashboard && dbcNavItemsPermissions.assetLib">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/asset-library']" [queryParams]="{orgID: currentOrganizationId}">
                    <i class="far fa-folder-open fa-lg"></i>
                    Asset Library
                  </a>
              </li>

            <!-- Template start -->
            <ng-container *ngTemplateOutlet="DBC_template_section"></ng-container>
            <!-- Template end -->

            <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.settings" class="nav-item" id="settings-in-both-products">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/digital-business-cards/settings']" [queryParams]="{orgID: currentOrganizationId}" id="cards-settings">
                <i class="far fa-cog fa-lg"></i>
                Settings
              </a>
            </li>

            <!--Integrations-->
            <li class="nav-item" *ngIf="navBarItems.integrations">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/integrations']"[queryParams]="{orgID: currentOrganizationId}">
                <i class="fas fa-network-wired fa-lg"></i>
                Integrations
              </a>
            </li>
            <!--Integrations end-->

            <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && navBarItems.api" class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/api']" [queryParams]="{orgID: currentOrganizationId}">
                <i class="fas fa-code fa-lg"></i>
                API
              </a>
            </li>

              <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.api" class="nav-item">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/api']" [queryParams]="{orgID: currentOrganizationId}">
                    <i class="fas fa-code fa-lg"></i>
                      API
                  </a>
              </li>

            <li *ngIf="isAdminDashboard" class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/request-logs']" [queryParams]="{orgID: currentOrganizationId}">
                <i class="fas fa-history fa-lg"></i>
                Logs
              </a>
            </li>

            <li class="nav-item nav-dropdown" [ngClass]="toggleHelp ? '' : 'open'">
              <a class="nav-link nav-dropdown-toggle" (click)="this.toggleHelp = !this.toggleHelp;"
                 [routerLink]="[]" [queryParams]="{orgID: currentOrganizationId}">
                 <i class="far fa-question-circle fa-lg"></i>
                 Help
                </a>
              <ul class="nav-dropdown-items">
                <li class="nav-item">
                  <a class="nav-link inner-link" routerLinkActive="active" href="Javascript:void(0);" (click)="startNewIntercomConversation();">Live support</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" href="Javascript:void(0);" (click)="redirectFromHelpSection(HelpMenuOptions.HELP_CENTER)">Help center</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" href="Javascript:void(0);" (click)="redirectFromHelpSection(HelpMenuOptions.BLOGS)">Blogs</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" href="Javascript:void(0);" (click)="redirectFromHelpSection(HelpMenuOptions.VIDEOS)">Videos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" href="Javascript:void(0);" (click)="redirectFromHelpSection(HelpMenuOptions.WHATS_NEW)">What's new</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" routerLinkActive="active" href="Javascript:void(0);" (click)="startNewIntercomConversation();">Request a feature</a>
                </li>
              </ul>
            </li>
          </ul>
          <!--Sidebar for users having QR product / QR and dbc product (ends)-->

          <!--Sidebar for users with DBC product only-->
          <ul *ngIf="!hasOnlyQrAccess" [ngClass]="isOnTrialPlan && isOwner && (currentDashboard === DASHBOARD_MENU.QRCODES) ? 'custom-height' : ''" class="nav">
            <li class="nav-item">
              <a id="customOverviewClick" class="nav-link" [routerLink]="['/overview']" routerLinkActive="active" (click)="logOverviewClicks()">
                <i class="fas fa-chart-pie fa-lg"></i>
                Overview
              </a>
            </li>
            <!-- QR Codes -->
            <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES" class="nav-item nav-dropdown" [ngClass]="toggleQRCodes ? '' : 'open'">
              <a class="nav-link nav-dropdown-toggle" (click)="this.toggleQRCodes = !this.toggleQRCodes;"
                 [routerLink]="[]" [queryParams]="{orgID: currentOrganizationId}">QR Codes</a>
              <ul class="nav-dropdown-items">
                <li class="nav-item">
                  <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/qr-codes']" [queryParams]="{orgID: currentOrganizationId}">QR Codes</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/analytics/qr']" [queryParams]="{orgID: currentOrganizationId}" id="sidebar-analytics-starter">Analytics</a>
                </li>
                <li class="nav-item" *ngIf="hasTemplatePermission">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/qr-templates']" [queryParams]="{orgID: currentOrganizationId}">Templates</a>
                </li>
              </ul>
            </li>
            <!-- QR Codes ends -->
            <!-- Linkpages -->
           <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && accessList.linkpage" class="nav-item nav-dropdown" [ngClass]="toggleLinkPages ? '' : 'open'">
             <a class="nav-link nav-dropdown-toggle" [routerLink]="[]" (click)="this.toggleLinkPages = !this.toggleLinkPages;" [queryParams]="{orgID: currentOrganizationId}">Linkpages</a>
             <ul class="nav-dropdown-items">
               <li class="nav-item">
                 <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/linkpage']" [queryParams]="{orgID: currentOrganizationId}">Linkpages</a>
               </li>
               <li class="nav-item">
                 <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/analytics/linkpage']" [queryParams]="{orgID: currentOrganizationId}">Analytics</a>
               </li>
               <li class="nav-item">
                 <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/linkpage-templates']" [queryParams]="{orgID: currentOrganizationId}">Templates</a>
               </li>
             </ul>
           </li>
            <!-- Linkpages ends-->

            <!--Campaigns -->
            <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && (accessList.markdownCard || accessList.form)" class="nav-item nav-dropdown" [ngClass]="toggleCampaigns ? '' : 'open'">
              <a class="nav-link nav-dropdown-toggle" (click)="this.toggleCampaigns = !this.toggleCampaigns;">Campaigns</a>
              <ul class="nav-dropdown-items">
                <li *ngIf="accessList.markdownCard" class="nav-item">
                  <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/landing-pages']" [queryParams]="{orgID: currentOrganizationId}">Landing Pages</a>
                </li>
                <li *ngIf="accessList.form" class="nav-item">
                  <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/forms']" [queryParams]="{orgID: currentOrganizationId}">Forms</a>
                </li>
              </ul>
            </li>
            <!--Campaigns end-->

            <!-- DBC -->
            <ng-container *ngTemplateOutlet="DBC_Mycards_Orgcards_Analytics"></ng-container>
            <!-- DBC ends -->

            <!-- Leads -->
            <li *ngIf="isDbcDashboard" class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/contact-manager']" [queryParams]="{orgID: currentOrganizationId}" id="cards-contact-manager">
                <i class="far fa-address-book"></i>
                Contact Manager
            </a>
            </li>
            <!-- Leads Ends -->

            <li class="nav-item" *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/asset-library']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{orgID: currentOrganizationId}">
                Asset Library</a>
            </li>

              <li class="nav-item" *ngIf="isDbcDashboard && dbcNavItemsPermissions.assetLib">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/asset-library']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{orgID: currentOrganizationId}">
                    <i class="far fa-folder-open fa-lg"></i>
                    Asset Library
                  </a>
              </li>

            <li class="nav-item" *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/places']" [queryParams]="{orgID: currentOrganizationId}">Places</a>
            </li>

            <!-- Template Starts -->
            <ng-container *ngTemplateOutlet="DBC_template_section"></ng-container>
            <!-- Template Ends -->

            <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.settings" class="nav-item" id="only-dbc-settings">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/digital-business-cards/settings']" [queryParams]="{orgID: currentOrganizationId}" id="cards-settings">
                <i class="far fa-cog fa-md nav-link-icon" aria-hidden="true"></i>
                Settings
              </a>
            </li>

            <!--Integrations-->
            <li class="nav-item" *ngIf="navBarItems.integrations">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/integrations']" [queryParams]="{orgID: currentOrganizationId}">
                <i class="fas fa-network-wired fa-lg"></i>
                Integrations
              </a>
            </li>
            <!--Integrations end-->

            <li class="nav-item" *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && navBarItems.api">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/api']" [queryParams]="{orgID: currentOrganizationId}">
                <i class="fas fa-code fa-lg"></i>
                API
              </a>
            </li>

              <li class="nav-item" *ngIf="isDbcDashboard && dbcNavItemsPermissions.api">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/api']" [queryParams]="{orgID: currentOrganizationId}">
                    <i class="fas fa-code fa-lg"></i>
                      API
                  </a>
              </li>

            <li *ngIf="isAdminDashboard" class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/request-logs']" [queryParams]="{orgID: currentOrganizationId}">
                <i class="fas fa-history fa-lg"></i>
                Logs
              </a>
            </li>

            <li class="nav-item nav-dropdown" [ngClass]="toggleHelp ? '' : 'open'">
              <a class="nav-link nav-dropdown-toggle" (click)="this.toggleHelp = !this.toggleHelp;"
                 [routerLink]="[]" [queryParams]="{orgID: currentOrganizationId}">
                 <i class="far fa-question-circle fa-lg"></i>
                 Help
                </a>
              <ul class="nav-dropdown-items">
                <li class="nav-item">
                  <a class="nav-link inner-link" routerLinkActive="active" href="Javascript:void(0);" (click)="startNewIntercomConversation();">Live support</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" href="Javascript:void(0);" (click)="redirectFromHelpSection(HelpMenuOptions.HELP_CENTER)">Help center</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" href="Javascript:void(0);" (click)="redirectFromHelpSection(HelpMenuOptions.BLOGS)">Blogs</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" href="Javascript:void(0);" (click)="redirectFromHelpSection(HelpMenuOptions.VIDEOS)">Videos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" href="Javascript:void(0);" (click)="redirectFromHelpSection(HelpMenuOptions.WHATS_NEW)">What's new</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link inner-link" routerLinkActive="active" href="Javascript:void(0);" (click)="startNewIntercomConversation();">Request a feature</a>
                </li>
              </ul>
            </li>
          </ul>
          <!--Sidebar for users with DBC product only (ends)-->
        </nav>
        <div [ngClass]="{'upgrade-button-present': (showCardsUpgradeNowButton && !isOnDbcTrialPlan),'upgrade-button-absent': !(showCardsUpgradeNowButton && !isOnDbcTrialPlan)}" class="desktop-nudge-container" *ngIf="!isMobileDevice && (isDbcDashboard)">
            <pwa-nudge-component (triggerPopup)="handleNudgePopup($event)">
            </pwa-nudge-component>
        </div>

        <mycards-popup [showModal]="showModal" (closeMycardsPopupEmitter)="closePopupEmitter($event)"></mycards-popup>
      <div class="d-flex">
        <div *ngIf="(subscription$ |async); let subscription;">
          <!--Special upgrade section for starter trail-->
          <div class="text-center nav upgrade-starter-plan nav-optimized" [ngStyle]="{'bottom': showBillingNavbar || showAnnouncementBanner ? '58px' : '0px'}">
            <div *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && showQrUpgradeNowButton && subscription.isStarterTrial" class="nav-item">
              <!-- Time remaining text for QR Code -->
              <div class="mb-1">
                <span class="qr-time-remaining-header">Your QR Codes will expire in</span>
              </div>
              <div style="color: #FF445E;" class="mb-3">
                <i class="mr-1 far fa-clock"></i><span class="qr-time-remaining-subheader">
                  {{trialEndDate}}
                </span>
              </div>
            </div>
            <div *ngIf="isDbcDashboard && showCardsUpgradeNowButton && subscription.dbcTrial">
              <span class="time-remaining-text"><i class="far fa-clock"></i> {{subscription.dbcExpires}} days remaining
              </span>
            </div>
            <div *ngIf="isDbcDashboard && showCardsUpgradeNowButton" class="mx-auto my-0 nav-item">
              <!--            Black Friday-->
              <!--            <div *ngIf="!subscription.isStarterTrial && !isOnPlusPlan" style="font-style: italic;font-weight: 700; font-size: 14px; line-height: 16px; color: #16212F; margin-bottom: 8px">-->
              <!--              <img width="16px" style="margin-bottom: 3px; margin-right: 8px" height="16px" src="assets/img/black-friday-discount.png"> <span>BLACK FRIDAY OFFER!</span>-->
              <!--            </div>-->
              <a class="cursor-pointer text-uppercase" (click)="openUpgradePage('sidenav-upgrade-cta')"><i class="fas fa-crown mr-2"></i> Upgrade Now</a>
            </div>
            <div *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && showQrUpgradeNowButton" class="mx-auto my-0 nav-item">
              <a class="cursor-pointer text-uppercase" (click)="openUpgradePage('sidenav-upgrade-cta')"><i class="fas fa-crown mr-2"></i>Upgrade Now</a>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end" *ngIf="showInviteCollaboratorBox">
        <div class="text-center nav upgrade-starter-plan nav-optimized" [ngStyle]="{'bottom': showBillingNavbar || showAnnouncementBanner ? '58px' : '0px'}">
          <div class="mx-3 nav-item invite-collaborator-box" style="margin: 0 auto;">
            <img class="mt-2" src="../../assets/img/invite-collaborator-image.png" alt=""
              style="max-height: 50px; display: block; margin: auto;">
            <p class="px-3" style="text-align: center; font-size: 13px;">Add your teammates to start collaborating
              🤝</p>
            <p (click)="handleAddUserClick(INVITE_TEAMMATES_TOUCHPOINTS.SIDENAV);" style="color: #2595ff; cursor: pointer; text-align: center;">Invite teammates</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Collapsed Side Nav Bar -->
    <!-- TODO: Cleanup and move away from using input output binding  -->
    <app-collapsed-side-nav
      *ngIf="isSideNavCollapsed"
      [currentDashboard]="currentDashboard"
      [isDbcDashboard]="isDbcDashboard"
      [navBarItems]="navBarItems"
      [accessList]="accessList"
      [dbcNavItemsPermissions]="dbcNavItemsPermissions"
      [hasTemplatePermission]="hasTemplatePermission"
      [hasCardsTemplateAccess]="hasCardsTemplateAccess"
      [qrCreateCtaVariation]="qrCreateCtaVariation"
      [isAdminDashboard]="isAdminDashboard"
      [showQrUpgradeNowButton]="showQrUpgradeNowButton"
      [showCardsUpgradeNowButton]="showCardsUpgradeNowButton"
      [DASHBOARD_MENU]="DASHBOARD_MENU"
      [HelpMenuOptions]="HelpMenuOptions"
      [showModal]="showModal"
      [isMobileDevice]="isMobileDevice"
      [QR_CREATE_CTA_VARIATIONS]="QR_CREATE_CTA_VARIATIONS"
      [campaignTypes]="campaignTypes"
      [dropdownIcon]="dropdownIcon"
      (toggleCollapsed)="toggleSideNav()"
      (startIntercom)="startNewIntercomConversation()"
      (redirectHelp)="redirectFromHelpSection($event)"
      (nudgeClick)="handleNudgePopup($event)"
      (closeMycardsPopupEmitter)="closePopupEmitter($event)"
      (openUpgradePageEmitter)="openUpgradePage($event)"
      (selectDropdownIconEvent)="selectDropdownIcon($event)"
      (createQRctaEvent)="onCreateQRcta($event)"
      (logOverviewClicksEvent)="logOverviewClicks()"
  >
</app-collapsed-side-nav>


    </div>
    <main class="main section-container-main" [ngClass]="{'pt-md-3': !isLinkpageRoute, 'collapsed-nav-left-margin': isSideNavCollapsed}" (tap)="hideSidebar()" >
      <beaconstac-wait-loader
      id="full-layout-overlay"
      [loadingText]="loadingText$ | async"
      class="beaconstac-overlay"
      [ngClass]="{
        'd-none': !(isLoading || (loading$ | async)),
        'collapsed-overlay': isSideNavCollapsed
      }"
    ></beaconstac-wait-loader>
      <div *ngIf="!isSideDrawerOpen" class="container-fluid container-fluid-xs" [ngClass]="((showBillingNavbar || showAnnouncementBanner) && !isMobileDevice) ? 'container-margin-top' : '' " >
        <router-outlet *ngIf="reloadHack"></router-outlet>
        <beaconstac-message-modal></beaconstac-message-modal>
        <beaconstac-upsell></beaconstac-upsell>
        <beaconstac-product-owner-access></beaconstac-product-owner-access>
        <beaconstac-discard-change></beaconstac-discard-change>
        <app-firebase-popup-notification></app-firebase-popup-notification>
        <app-response-addon-modal></app-response-addon-modal>
    </div>
        <pwa-nudge-mobile *ngIf="showNudge && isMobileDevice && (isDbcDashboard)" [source]="PWA_MOBILE_NUDGE_SOURCE.MOBILE_OVERVIEW_PAGE"></pwa-nudge-mobile>
      <div class="side-notification" *ngIf="(dashboardVersionChange$ | async) ? 'active' : ''" [ngClass]="(dashboardVersionChange$ |async) ? 'active' : ''">
          A new version of the dashboard is now available. Please click <span class="cursor-pointer btn-link" (click)="onReload()">here to update</span>.
      </div>
    </main>


  <div *ngIf="user$ | async; let user">
    <footer class="app-footer" *ngIf="!isWhiteLabelDashboard">
      <div class="bx--row">
        <div class="bx--col-lg-4 company-label">
          <span> &copy; {{ currentYear }} Uniqode Phygital, Inc. </span>
        </div>
        <div class="bx--col-lg-12" *ngIf="(user | isOnHigherPlan : PLAN_TYPES.Trial)">
          <div class="bx--row">
            <div class="bx--col-lg-2 bx--col-sm-4 support-text">
              <span> Support:</span>
            </div>
            <div class="bx--col-lg-3 bx--col-sm-4 contact-info">
              <span><a href="tel:+16468672545"><img src="../../assets/img/american_flag.svg" alt="America" class="flag-image"> +1 646 867 2545</a></span>
            </div>
            <div class="bx--col-lg-3 bx--col-sm-4 contact-info">
              <span><a href="tel:+61272026847"><img src="../../assets/img/australian_flag.svg" alt="Australia" class="flag-image"> +61 2 7202 6847</a></span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</section>

<beaconstac-help-menu-display #helpModal></beaconstac-help-menu-display>

<beaconstac-stripe-card #creditCardModal headerText="Upgrade to Starter plan" primaryButtonText="Upgrade Now"
                        (onPlanTypeSelected)="staterPlanYearly = $event;" (accountPassword)="accountPassword = $event"
                        staterUpgrade="true"></beaconstac-stripe-card>

<app-beaconstac-add-on (dataChange)="reloadRouterOutlet();" (addInviteUser)="sendUserInviteOnAddOn();" #addOns></app-beaconstac-add-on>

<div bsModal #trialSelectModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content trial-modal-content" [ngStyle]="TrialInfo.Product === 'QR Codes' ? {width: '400px'} : {width: '476px'} ">
            <div class="p-0 modal-body trial-modal-body" style="background: #A8D5FF;">
                <img style="max-width: 100%;"
                     [src]="TrialInfo.Product === 'QR Codes'
                     ? '../../assets/img/upsell-images/qr-discovery-image.png'
                     : '../../assets/img/upsell-images/dbc-discovery-image.png'" alt="">
            </div>
            <div class="p-4 modal-body">

                <h4 class="trial-modal-heading">
                    <strong style="font-style: italic">{{TrialInfo.Heading}}</strong>
                </h4>

                <div class="mt-3 trial-modal-description">
                    {{TrialInfo.Description}}
                </div>

                <div class="mt-2 d-flex flex-column">
                    <span class="flex-row pb-2 d-flex" *ngFor="let val of TrialInfo.Details; let i = index">
                        <i class="pt-1 pr-4 fas fa-check" style="color: #26B969"></i>
                        <span class="product-trial-options">{{val}} </span>
                    </span>
                </div>

                <div style="padding-top: 32px;">
                <span style="float: right; display: flex; flex-direction: row">
                    <span style="padding-top: 6px; margin-right: 8px;">
                      <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [style]="BUTTON_STYLES.TEXT"
                                         (onClick)="onTrialModalSkipClick()" [label]="TrialInfo.secondaryCta"></beaconstac-button>
                    </span>
                    <span style="padding-left: 16px">
                      <beaconstac-button (onClick)="handleTrialCreateModal()" [label]="TrialInfo.primaryCta"></beaconstac-button>
                    </span>
                </span>
                </div>

            </div>
        </div>
    </div>
</div>


<div *ngIf="trialFirst" bsModal #newUserExpQRModal="bs-modal" class="modal fade" role="dialog"
  [config]="{'backdrop':'static'}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="width: 400px;">
      <div class="p-4 modal-body">
        <div class="mb-2 font-fw-400 new-user-exp-modal-header">
          Are you sure you want to leave this page?
        </div>
        <div class="mb-2 font-fw-400 new-user-exp-modal-sub-header">
          You’ll lose progress in creating your first QR Code.
        </div>
        <div class="float-right d-flex">
          <span class="mr-3 d-flex align-items-center"><beaconstac-button (onClick)="handleNewUserExpQRModal()"
              [style]="BUTTON_STYLES.TEXT" [label]="'LEAVE'"></beaconstac-button></span>
          <span><beaconstac-button (onClick)="newUserExpQRModal.hide()" [label]="'CONTINUE CREATING'"
              [type]="BUTTON_TYPES.PRIMARY"></beaconstac-button></span>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #modalToCollectBillingAddress="bs-modal" class="modal fade" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="p-2 modal-content">

      <div class="border-0 modal-header">
        <h4 class="modal-title">Complete your billing information</h4>
      </div>

      <div class="pt-1 modal-body">
        <div class="text-muted" style="font-size: 15px; line-height: 24px">
            Add country and postal code to update the address in your billing information
        </div>

        <div class="pt-3">
          <beaconstac-filter-dropdown
            [label]="'Country'"
            [placeholder]="'Select your country'"
            [title]="'country-banner'"
            [width]="'475px'"
            [optionLabelField]="'name'"
            [optionValueField]="'code'"
            [listItems]="utils.countries"
            [(value)]="billingAddressDetails.country"
          ></beaconstac-filter-dropdown>
        </div>

        <div class="pt-3">
          <beaconstac-textfield
            [label]="true"
            [placeholder]="'Enter postal code'" [title]="'Postal Code'"
            [type]="TEXT_FIELD_TYPES.TEXT"
            [maxWidth]="'475px'"
            [(text)]=billingAddressDetails.postalCode
          >
          </beaconstac-textfield>
        </div>

      </div>

      <div class="border-0 modal-footer">
        <beaconstac-button
          [type]="BUTTON_TYPES.SECONDARY"
          [label]="'Do It Later'"
          (onClick)="onClosingBillingModal()"
        >
        </beaconstac-button>
        <beaconstac-button
          [type]="BUTTON_TYPES.PRIMARY"
          [label]="'Done'"
          [disabled]="!(billingAddressDetails.country.name && billingAddressDetails.postalCode)"
          (onClick)="updateBillingDetails()"
        >
        </beaconstac-button>
      </div>

    </div>
  </div>
</div>

<div bsModal #addUserModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
  <div class="modal-dialog" role="document" style="width: 400px">
    <div class="modal-content">
      <form (ngSubmit)="false" #addUserForm="ngForm">
        <div class="modal-body" *ngIf="newUserModel" style="padding: 24px">

          <h4 style="color: #16212F; font-size: 20px; margin-bottom: 24px">Invite teammate</h4>

          <div class="bx--row">
            <div class="bx--col">
              <beaconstac-textfield name="username" [label]="true"
                                    [placeholder]="'Enter email address'"
                                    [valid]="isEmailValid ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR"
                                    [errorMessage]="'Please enter a valid email'"
                                    [title]="'Email'"
                                    (textChange)="emailChangeSubject.next($event)"
                                    [type]="TEXT_FIELD_TYPES.EMAIL"
                                    [(text)]="newUserModel.username"
                                    [maxWidth]="'352px'"></beaconstac-textfield>
            </div>
          </div>

          <div class="bx--row" style="margin-top: 8px;" *ngIf="showUserRolesList">
            <div class="bx--col">
              <beaconstac-filter-dropdown
                [label]="'Role'"
                [title]="'AccessGroup'"
                [(value)]="userRole"
                [listItems]="userRolesList"
                [width]="'352px'"
                [optionLabelField]="'name'"
                [optionValueField]="'value'"
                (valueChange)="handleRole($event)"
              ></beaconstac-filter-dropdown>
            </div>
          </div>

          <div [ngSwitch]="userRole.value" class="mt-4">
            <div *ngSwitchCase="userGroup[0]" [@collapse]>
              <beaconstac-message [type]="'primary'" [message]="'Administrators can add or remove users and manage everything created by anyone in the organization.'"></beaconstac-message>

            </div>
            <div *ngSwitchCase="userGroup[1]" [@collapse]>
              <beaconstac-message [type]="'primary'" [message]="'Editor can  only view and modify content of resources ' +
               'for their organization.'"></beaconstac-message>

            </div>
            <div *ngSwitchCase="userGroup[2]" [@collapse]>
              <beaconstac-message [type]="'primary'" [message]="'Users added will have the role of Viewer. ' +
               'Viewers can only view resources and analytics of their organization. '"></beaconstac-message>
            </div>
          </div>

          <div style="padding-top: 24px">
            <div class="flex-row d-flex justify-content-end">
              <span>
                <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" (onClick)="addUserModal.hide()" [label]="'Cancel'"></beaconstac-button>
              </span>
              <span style="padding-left: 16px">
                <beaconstac-button (onClick)="sendUserInvite();" [label]="'Invite'" [disabled]="!isEmailValid || !newUserModel.username"></beaconstac-button>
              </span>
            </div>
          </div>

        </div>

      </form>
    </div>
  </div>
</div>

<div bsModal #userDetailModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
  <div class="modal-dialog" role="document">
    <div class="p-2 modal-content">

      <div class="border-0 modal-header">
        <h4 class="modal-title">Hello there!</h4>
      </div>

      <div class="pt-1 modal-body">
        <div class="text-muted" style="font-size: 15px; line-height: 24px">
          Enter your full name to get started
        </div>

        <div class="pt-3">
          <beaconstac-textfield
            [label]="true"
            [placeholder]="'Enter first name'" [title]="'First name'"
            [type]="TEXT_FIELD_TYPES.TEXT"
            [maxWidth]="'475px'"
            [(text)]=userDetail.first_name
          >
          </beaconstac-textfield>
        </div>

        <div class="pt-3">
          <beaconstac-textfield
            [label]="true"
            [placeholder]="'Enter last name'" [title]="'Last name'"
            [type]="TEXT_FIELD_TYPES.TEXT"
            [maxWidth]="'475px'"
            [(text)]=userDetail.last_name
          >
          </beaconstac-textfield>
        </div>

      </div>

      <div class="border-0 modal-footer">
        <beaconstac-button
          [type]="BUTTON_TYPES.PRIMARY"
          label="Get Started"
          [disabled]="!(userDetail.first_name && userDetail.last_name)"
          (onClick)="updateUserDetail()"
        >
        </beaconstac-button>
      </div>

    </div>
  </div>
</div>

<ng-template #masterOrgTemplate>
    <div class="switch-container">
        <span class="switch-heading">MASTER ORGANIZATION</span>
    </div>
    <div *ngIf="!isMasterOrgSelected" class="master-option" (click)="onMasterOrgClick()">
        <span class="master-name text-truncate">{{masterOrgReduced.name}}</span>
        <span class="float-right master-role">{{masterOrgReduced.role}}</span>
    </div>
    <div
        *ngIf="isMasterOrgSelected"
        class="master-option text-primary"
        style="font-weight: 600"
        (click)="onMasterOrgClick()"
        (keydown)="onMasterOrgClick()"
        (keyup)="onMasterOrgClick()"
    >
        <span>
          <span class="master-name text-truncate">{{masterOrgReduced.name}}</span>
          <i class="ml-2 fas fa-check"></i>
        </span>
        <span class="float-right master-role">{{masterOrgReduced.role}}</span>
    </div>
</ng-template>

<ng-template #cardsNavLinks let-aTagCardsClass="aTagCardsClass">
    <div *ngIf="isDbcDashboard && dbcNavItemsPermissions.myCards" [ngClass]="aTagCardsClass" class="py-3 pl-4 nav-item nav-link nav-icon" routerLinkActive="active" [routerLink]="['/digital-business-cards/my-cards']" id="my-cards-both" [queryParams]="{orgID: currentOrganizationId}">
      <i class="far fa-address-card fa-md nav-link-icon" aria-hidden="true"></i>
      <a class="p-0 nav-link-grey"routerLinkActive="nav-item-active">My Cards</a>
    </div>
    <div *ngIf="isDbcDashboard && dbcNavItemsPermissions.orgCards" [ngClass]="aTagCardsClass" class="py-3 pl-4 nav-item nav-link nav-icon" routerLinkActive="active" [routerLink]="['/digital-business-cards/team-cards']" id="organization-cards-both" [queryParams]="{orgID: currentOrganizationId}">
      <i class="far fa-building fa-md nav-link-icon" aria-hidden="true"></i>
      <a class="pl-1 nav-link-grey"routerLinkActive="nav-item-active">Organization Cards</a>
    </div>

</ng-template>

<ng-template #analyticsLinksDBC let-aTagAnalyticsClass="aTagAnalyticsClass">
  <div *ngIf="isDbcDashboard && dbcNavItemsPermissions.analytics" [ngClass]="aTagCardsClass" class="py-3 pl-4 nav-item nav-link nav-icon" routerLinkActive="active" [routerLink]="['/analytics/digital-business-cards']" [queryParams]="{orgID: currentOrganizationId}">
    <i class="fas fa-chart-bar fa-md nav-link-icon" aria-hidden="true"></i>
    <a class="pl-1 nav-link-grey" routerLinkActive="nav-item-active">Analytics</a>
  </div>
</ng-template>

<ng-template #DBC_Mycards_Orgcards_Analytics>
  <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.myCards" class="nav-item" >
    <a class="nav-link" id="cards-contact-manager" routerLinkActive="active" [routerLink]="['/digital-business-cards/my-cards']" id="my-cards-both" [queryParams]="{orgID: currentOrganizationId}">
      <i class="far fa-address-card fa-lg"></i>
      My Cards
  </a>
  </li>
  <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.orgCards" class="nav-item" >
    <a class="nav-link" id="cards-contact-manager" routerLinkActive="active" [routerLink]="['/digital-business-cards/team-cards']" id="organization-cards-both" [queryParams]="{orgID: currentOrganizationId}">
      <i class="far fa-building fa-lg"></i>
      Organization Cards
  </a>
  </li>
  <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.analytics" class="nav-item" >
    <a class="nav-link" id="cards-contact-manager" routerLinkActive="active" [routerLink]="['/analytics/digital-business-cards']" [queryParams]="{orgID: currentOrganizationId}">
      <i class="fas fa-chart-bar fa-lg"></i>
      Analytics
  </a>
  </li>
</ng-template>

<ng-template #DBC_template_section>
  <li class="nav-item nav-dropdown" *ngIf="isDbcDashboard && dbcNavItemsPermissions.template" [ngClass]="toggleTemplate ? '' : 'open'">
    <a class="nav-link nav-dropdown-toggle" (click)="this.toggleTemplate = !this.toggleTemplate;">
      <i class="far fa-images"></i>
      Template
    </a>
    <ul class="nav-dropdown-items">
      <li class="nav-item" *ngIf="isAdmin">
        <a *ngIf="hasCardsTemplateAccess" class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/digital-business-cards/templates']" [queryParams]="{orgID: currentOrganizationId}">Business Cards</a>
        <a *ngIf="!hasCardsTemplateAccess" class="nav-link inner-link" (click)="showUpsellModel()">Business Cards <img class="pr-2 mb-1" src="../../assets/logo/crown-solid.svg"></a>
      </li>
      <li class="nav-item">
        <a class="nav-link inner-link" *ngIf="hasTemplatePermission" routerLinkActive="active" [routerLink]="['/qr-templates']" [queryParams]="{orgID: currentOrganizationId}">QR Codes</a>
      </li>
      <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link inner-link" routerLinkActive="active" [routerLink]="['/wallet-pass-template']" [queryParams]="{orgID: currentOrganizationId}">Wallet Passes</a>
      </li>
    </ul>
  </li>
</ng-template>
