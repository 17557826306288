import { environment } from '../../../environments/environment';
import {PLAN_TYPES} from '../../user-account/user.model';

export const UPSELL_DATA = {
    location: {
        [PLAN_TYPES.Pro]: {
            title: {
                main: 'Get more with ',
                plan: 'Pro plan ',
                icon: '✨',
            },
            description: 'Get more codes, analytics and integrations',
            detail: {
                title: 'PRO PLAN',
                save: {
                    text: 'Save 34%',
                    tooltipIcon: 'fal fa-info-circle',
                    tooltipContent: 'On each QR Code',
                },
                price: {
                    value: '$49',
                    message: 'USD/month, billed yearly',
                },
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: ['GPS Tracking', '250 QR Codes', 'QR Code API', 'Advanced Analytics', 'Integrations'],
            },
            directToPricing: false,
            redirectCTA: 'Learn more about other plans ->',
            upgradeCTA: 'Upgrade to Pro',
            imageURL: '../../../assets/img/location-upsell.png',
            imageBackground: '#F0FBF6',
        },
    },
    location_spu: {
        PO: {
            title: {
                main: 'Get more with ',
                plan: 'Pro plan ',
                icon: '✨',
            },
            description: 'Get more codes, analytics and integrations',
            detail: {
                title: 'PRO PLAN',
                save: {
                    text: 'Now at 50% OFF',
                },
                price: {
                    originalValue: '$49',
                    value: '$24.5',
                    message: 'USD/month, billed yearly',
                    subMessage: 'for first year',
                    tooltipIcon: 'fal fa-info-circle',
                    tooltipContent: 'Enjoy advanced features at a discounted first-year rate! Your plan will automatically renew next year at $49/month',
                },
                featureHeading: 'Includes',
                features: ['GPS Tracking', '250 QR Codes', 'QR Code API', 'Integrations and Multi-language Support', 'Short URL'],
            },
            upgradeCTA: 'Upgrade to Pro',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/location-upsell.png',
            imageBackground: '#F0FBF6',
            coupon: 'PROAT50',
        },
    },
    bulk_upload: {
        LT: {
            heading: 'Upgrade to create QR Codes in bulk',
            currentPlan: {
                title: 'STARTER',
                description: 'For QR Code Beginners 🍼',
                price: {
                    value: '$5',
                    message: 'USD/month, billed yearly',
                },
                upgradeCTA: 'CURRENT_PLAN',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    {
                        text: '3 QR Codes',
                        tooltip: {
                            description: 'Create upto 3 Dynamic QR Codes',
                            media: {},
                        },
                    },
                ],
            },
            recommendedPlan: {
                title: 'LITE',
                description: 'For Serious QR Code users 🚀',
                price: {
                    value: '$15',
                    message: 'USD/month, billed yearly',
                },
                upgradeCTA: 'UPGRADE NOW',
                featureHeading: 'All Starter features, plus',
                highlightFirstItem: true,
                features: [
                    {
                        text: 'Bulk QR Creation',
                        tooltip: {
                            description: 'Create QR Codes in batches',
                            media: {
                                type: 'image',
                                src: '../../../assets/img/upsell-images/bulk_creation.png',
                            },
                        },
                    },
                    {
                        text: '50 QR Codes',
                        tooltip: {
                            description: 'Create upto 50 Dynamic QR Codes',
                            media: {},
                        },
                    },
                    {
                        text: '5 Linkpages',
                        tooltip: {
                            description:
                                'Create mobile-first landing pages that can host multiple links. Use a single URL or a QR Code to share all important information — social profiles, contact information, location, product pages, etc. — at one go.',
                            media: {},
                        },
                    },
                    {
                        text: 'GPS Tracking',
                        tooltip: {
                            description: 'Get accurate geolocation of your users for better scan tracking.',
                            media: {
                                type: 'image',
                                src: '../../../assets/img/upsell-images/gps_tracking.png',
                            },
                        },
                    },
                ],
            },
            redirectCTA: 'Compare all plans',
        },
    },
    slg_upsell_modal_lite: {
        [PLAN_TYPES.Pro]: {
            heading: 'Take it to the next level with PRO Plan',
            currentPlan: {
                title: 'LITE',
                price: {
                    value: '$15',
                    message: 'USD/month, billed yearly',
                },
                upgradeCTA: 'CURRENT PLAN',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    {
                        text: '50 QR Codes',
                        tooltip: {},
                    },
                ],
            },
            recommendedPlan: {
                title: 'PRO',
                price: {
                    value: '$49',
                    message: 'USD/month, billed yearly',
                },
                upgradeCTA: 'UPGRADE NOW',
                featureHeading: 'All Lite features, plus',
                highlightFirstItem: true,
                features: [
                    {
                        text: '250 QR Codes',
                        tooltip: {},
                    },
                    {
                        text: 'Advanced analytics',
                        tooltip: {
                            description: 'Pro unlocks detailed analytics beyond basic scan counts available on lower plans. Track scans by time of day, devices used, and user location for geographic insights.',
                            media: {},
                        },
                    },
                    {
                        text: 'Advanced customizations',
                        tooltip: {
                            description:
                                'Enhance your QR code campaigns with advanced customizations like smart rules, location triggers, custom landing pages, templated designs, and scan access control for a more dynamic and secure user experience.',
                            media: {},
                        },
                    },
                    {
                        text: 'Integrations',
                        tooltip: {
                            description: 'Google Analytics, Google Sheets, Zapier, Canva, Slack, and more.',
                            media: {},
                        },
                    },
                ],
            },
        },
    },
    slg_upsell_modal_lite_mobile: {
        [PLAN_TYPES.Pro]: {
            title: {
                main: 'Take it to the next level with PRO Plan',
            },
            detail: {
                title: 'PRO',
                price: {
                    value: '$49',
                    message: '/month, billed yearly',
                },
                featureHeading: 'All Lite features, plus',
                highlightFirstItem: true,
                features: [
                    '250 QR Codes',
                    'Advanced analytics',
                    'Advanced customizations',
                    'Integrations',
                ],
            },
            upgradeCTA: 'Upgrade Now',
            imageURL: '../../../assets/img/upsell-images/new-team-upsell.png',
            imageBackground: '#FFF0C8',
        },
    },
    multi_lang: {
        [PLAN_TYPES.Pro]: {
            title: {
                main: 'Go global with ',
                plan: 'Pro plan ',
                icon: '🌎',
            },
            description: 'Multi-lingual localization for your QR Codes',
            detail: {
                title: 'PRO PLAN',
                save: {
                    text: 'Save 34%',
                    tooltipIcon: 'fal fa-info-circle',
                    tooltipContent: 'On each QR Code',
                },
                price: {
                    value: '$49',
                    message: 'USD/month, billed yearly',
                },
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Multi-language support',
                    '250 QR Codes',
                    'QR Code API',
                    'Integrations',
                    'All Starter and Lite features',
                ],
            },
            directToPricing: false,
            redirectCTA: 'Learn more about other plans ->',
            upgradeCTA: 'Upgrade to Pro',
            imageURL: '../../../assets/img/upsell-images/multi_lang_upsell.png',
            imageBackground: '#FFEFEF',
        },
    },
    whitelabel_access: {
        EN: {
            title: {
                main: 'Get White-label with ',
                plan: 'Business+ ',
                icon: '',
            },
            description: 'Ensure consistent branding on your campaigns',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'White Label',
                    'Custom Domain',
                    'Single Sign-on (SSO)',
                    'Multiple Organizations',
                    'Dedicated Customer Success Manager',
                    'Retargeting (Google Ads + FB Pixel)',
                ],
            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42',
        },
    },
    mfa_access: {
        EN: {
            title: {
                main: 'Enable MFA on ',
                plan: 'Business+ ',
                icon: '',
            },
            description: 'Safeguard your data from unauthorized access',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Multi-factor Authentication (MFA)',
                    'Custom Domain',
                    'Single Sign-on (SSO)',
                    'White-Label',
                    'Multiple Organizations',
                    'Retargeting (Google Ads + FB Pixel)',
                ],
            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42',
        },
    },
    sso: {
        EN: {
            title: {
                main: 'Get SSO with ',
                plan: 'Business+ ',
                icon: '',
            },
            description: 'Create secure logins with single sign-on',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Single Sign-on (SSO)',
                    'Custom Domain',
                    'Multiple Organizations',
                    'White-Label',
                    'Dedicated Customer Success Manager',
                    'Retargeting (Google Ads + FB Pixel)',
                ],
            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42',
        },
    },
    multi_org: {
        EN: {
            title: {
                main: 'Manage teams on ',
                plan: 'Business+ ',
                icon: '',
            },
            description: 'Collaborative workspaces with access control',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Multiple Organizations',
                    'Custom Domain',
                    'Single Sign-on (SSO)',
                    'Retargeting (Google Ads + FB Pixel)',
                    'Dedicated Customer Success Manager',
                    'Retargeting (Google Ads + FB Pixel)',
                ],
            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42',
        },
    },
    retargeting: {
        EN: {
            title: {
                main: 'Retarget scanners on ',
                plan: 'Business+ ',
                icon: '',
            },
            description: 'Send targeted ads to boost conversions',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Retargeting (Google Ads + FB Pixel)',
                    'Multiple Organizations',
                    'Single Sign-on (SSO)',
                    'White-Label',
                    'Dedicated Customer Success Manager',
                ],
            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42',
        },
    },
    custom_domain: {
        PL: {
            title: {
                main: 'Custom Domain with ',
                plan: 'Plus plan ',
                icon: '',
            },
            description: 'Get 5x increase in scans from Custom Domain',
            detail: {
                title: 'PLUS plan',
                price: {
                    value: '$99',
                    message: 'USD/month, billed yearly',
                },
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: ['All Pro Features', '500 QR Codes', 'Add up to 3 Users', 'Custom Domain as addon'],
            },
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            upgradeCTA: 'Pay & Upgrade',
            imageURL: '../../../assets/img/upsell-images/custom-domain-upsell.svg',
            imageBackground: 'rgba(226, 248, 236, 1)',
            buyWithAddon: {
                checked: true,
                title: 'Add Custom Domain ',
                subtitle: 'for +$167/month',
            },
        },
    },
    multi_user: {
        PL: {
            title: {
                main: 'Invite your team with ',
                plan: 'Plus plan ',
                icon: '',
            },
            description: 'Collaborate with your team to manage and track your QR Codes',
            detail: {
                title: 'PLUS plan',
                price: {
                    value: '$99',
                    message: 'USD/month, billed yearly',
                },
                featureHeading: 'Add multiple users and manage their permission to:',
                highlightFirstItem: false,
                featureIcons: ['✏️', '🔍', '✨'],
                features: ['View or edit QR codes', 'View analytics', 'Run new campaigns'],
            },
            directToPricing: true,
            closeCTA: 'Cancel',
            upgradeCTA: 'Upgrade Plan',
            imageURL: '../../../assets/img/user-modal.png',
            imageBackground: 'rgba(221, 239, 255, 1)',
        },
    },
    advanced_customization: {
        BN: {
            title: {
                main: 'Get Advanced Customization with ',
                plan: 'Business plan ',
                icon: '🚀',
            },
            description: 'Unlock new customizations to design your business card the way you want.',
            detail: {
                title: 'BUSINESS PLAN',
                save: {
                    text: 'Save 20%',
                    tooltipIcon: 'fal fa-info-circle',
                    tooltipContent: 'On each user seat',
                },
                price: {
                    value: '$4',
                    message: '/user, per month',
                },
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Advanced Color & Font Options',
                    'Zapier Integration with 5000+ apps',
                    'Google Analytics for viewer insights',
                    'Custom Domain with your URL',
                ],
            },
            upgradeCTA: 'Upgrade to Business',
            imageURL: `${environment.gifEndpoint}dbc/premium-customization-upsell.gif`,
            imageBackground: '#FFF0C8',
        },
    },
    advanced_customizations_new_card_designs : {
        [PLAN_TYPES.Business]: {
            title: {
                main: 'Get this card design with ',
                plan: 'Business plan ',
                icon: '✨',
            },
            description: 'Explore our new card designs to create stunning digital business cards',
            detail: {
                title: 'BUSINESS PLAN',
                save: {
                    text: 'Save 20%',
                    tooltipIcon: 'fal fa-info-circle',
                    tooltipContent: 'On each user seat',
                },
                price: {
                    value: '$4',
                    message: '/user, per month',
                },
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Premium Card Designs',
                    'Zapier Integration (5000 + Apps)',
                    'Custom Domain',
                    'Advanced Customizations',
                ],
            },
            upgradeCTA: 'Upgrade to Business',
            imageURL: '../../../assets/img/upsell-images/new-card-designs.svg',
            imageBackground: '#FFF0C8',
        },
    },
    custom_fonts: {
        [PLAN_TYPES.BusinessPlus]: {
            title: {
                main: 'Get Custom Fonts with ',
                plan: ' Business+ ',
                icon: ''
            },
            description: 'Create cards with your brand’s custom fonts',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Custom Fonts & Style',
                    'Custom Domain',
                    'Single Sign-on (SSO)',
                    'Advanced Customizations',
                    'Dedicated Customer Success Manager']

            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42'
        }
    },
    white_label: {
        [PLAN_TYPES.BusinessPlus]: {
            title: {
                main: 'Get White-label with ',
                plan: ' Business+',
                icon: ''
            },
            description: 'Ensure consistent branding on your campaigns',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'White Label',
                    'Custom Domain',
                    'Single Sign-on (SSO)',
                    'Advanced Customizations',
                    'Dedicated Customer Success Manager']

            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42'
        }
    },
    entra_id: {
        [PLAN_TYPES.BusinessPlus]: {
            title: {
                main: 'Connect Microsoft Entra ID on ',
                plan: ' Business+',
                icon: ''
            },
            description: 'Seamless digital business card creation at scale',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Microsoft Entra ID & Salesforce Integrations',
                    'Multiple Organizations',
                    'Single Sign-on (SSO)',
                    'Custom Fonts & Style',
                    'Dedicated Customer Success Manager']

            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42'
        }
    },
    salesforce: {
        [PLAN_TYPES.BusinessPlus]: {
            title: {
                main: 'Connect Salesforce on ',
                plan: ' Business+',
                icon: ''
            },
            description: 'Nurture leads collected towards conversion',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Microsoft Entra ID & Salesforce Integrations',
                    'Multiple Organizations',
                    'Single Sign-on (SSO)',
                    'Custom Fonts & Style',
                    'Dedicated Customer Success Manager']

            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42'
        }
    },
    hubspot: {
        [PLAN_TYPES.BusinessPlus]: {
            title: {
                main: 'Connect HubSpot on ',
                plan: ' Business+',
                icon: ''
            },
            description: 'Nurture leads collected towards conversion',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Salesforce & HubSpot Integrations',
                    'Multiple Organizations',
                    'Single Sign-on (SSO)',
                    'Custom Fonts & Style',
                    'Dedicated Customer Success Manager']

            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42'
        }
    },
    dbc_custom_domain: {
        [PLAN_TYPES.BusinessPlus]: {
            title: {
                main: 'Get Custom Domains with ',
                plan: ' Business+',
                icon: ''
            },
            description: 'Ensure consistent branding on your campaigns',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Custom Domain',
                    'White Label',
                    'Single Sign-on (SSO)',
                    'Advanced Customizations',
                    'Dedicated Customer Success Manager']

            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42'
        }
    },
    dbc_whitelabel_access: {
        [PLAN_TYPES.BusinessPlus]: {
            title: {
                main: 'Get White-label with ',
                plan: 'Business+ ',
                icon: '',
            },
            description: 'Ensure consistent branding on your campaigns',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'White Label',
                    'Custom Domain',
                    'Single Sign-on (SSO)',
                    'Multiple Organizations',
                    'Dedicated Customer Success Manager',
                ],
            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42',
        },
    },
    dbc_sso: {
        [PLAN_TYPES.BusinessPlus]: {
            title: {
                main: 'Get SSO with ',
                plan: 'Business+ ',
                icon: '',
            },
            description: 'Create secure logins with single sign-on',
            detail: {
                title: 'BUSINESS+ PLAN',
                customPricing: 'Custom Pricing',
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Single Sign-on (SSO)',
                    'Custom Domain',
                    'Multiple Organizations',
                    'White-Label',
                    'Dedicated Customer Success Manager'
                ],
            },
            upgradeCTA: 'Schedule Demo',
            redirectButton: {
                redirectCTA: 'Explore Plans',
            },
            imageURL: '../../../assets/img/upsell-images/upsell-business.png',
            imageBackground: '#1D2C42',
        },
    },
    advanced_analytics: {
        [PLAN_TYPES.DBCNTeam]: {
            title: {
                main: 'Get advanced analytics with ',
                plan: 'Team plan ',
                icon: '🚀',
            },
            description: 'Know when, where, and how your digital business cards are being scanned',
            detail: {
                title: 'TEAM PLAN',
                price: {
                    value: '$6',
                    message: '/user, per month',
                },
                featureHeading: 'Advanced Analytics Includes',
                highlightFirstItem: true,
                features: [
                    'Unlimited Analytics Access',
                    'Export of Analytics data',
                    'Card Views by City',
                    'Card Views by Location',
                ],
            },
            upgradeCTA: 'Upgrade to Team',
            imageURL: '../../../assets/img/upsell-images/advanced_analytics.svg',
            imageBackground: '#FFF0C8',
        },
    },
    cards_limit_increase: {
        [PLAN_TYPES.DBCNTeam]: {
            title: {
                main: 'Create more cards with the ',
                plan: 'Team plan ',
                icon: '🚀',
            },
            description: 'Add unlimited users to create more digital business cards for you and your teammates.',
            detail: {
                title: 'TEAM PLAN',
                price: {
                    value: '$6',
                    message: '/user, per month',
                },
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Advanced Color & Font Options',
                    'Zapier Integration with 5000+ apps',
                    'Google Analytics for viewer insights',
                    'Unlimited Lead Generation',
                ],
            },
            upgradeCTA: 'Upgrade to Team',
            imageURL: '../../../assets/img/upsell-images/new-team-upsell.png',
            imageBackground: '#FFF0C8',
        },
    },
    dbc_premium_layouts : {
        [PLAN_TYPES.Team]: {
            title: {
                main: 'Get more card designs with the ',
                plan: 'Team plan ',
                icon: '✨',
            },
            description: 'Explore more card designs to create stunning digital business cards',
            detail: {
                title: 'TEAM PLAN',
                price: {
                    value: '$6',
                    message: '/user, per month',
                },
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Premium Card Designs',
                    'Advanced Customizations',
                    'Zapier Integration (5000 + Apps)',
                    'Unlimited Lead Generation',
                ],
            },
            upgradeCTA: 'Upgrade to Team',
            imageURL: '../../../assets/img/upsell-images/new-card-designs.svg',
            imageBackground: '#FFF0C8',
        },
    },
    premium_customization: {
        [PLAN_TYPES.DBCNTeam]: {
            title: {
                main: 'Get Advanced Customization with ',
                plan: 'Team plan ',
                icon: '🚀',
            },
            description: 'Unlock new customizations to design your business card the way you want.',
            detail: {
                title: 'TEAM PLAN',
                price: {
                    value: '$6',
                    message: '/user, per month',
                },
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Advanced Color & Font Options',
                    'Zapier Integration with 5000+ apps',
                    'Google Analytics for viewer insights',
                    'Custom Domain with your URL',
                ],
            },
            upgradeCTA: 'Upgrade to Team',
            imageURL: `${environment.gifEndpoint}dbc/premium-customization-upsell.gif`,
            imageBackground: '#FFF0C8',
        },
    },
    premium_customizations_new_card_designs : {
        [PLAN_TYPES.DBCNTeam]: {
            title: {
                main: 'Get this card design with ',
                plan: 'Team plan ',
                icon: '✨',
            },
            description: 'Explore our new card designs to create stunning digital business cards',
            detail: {
                title: 'TEAM PLAN',
                price: {
                    value: '$6',
                    message: '/user, per month',
                },
                featureHeading: 'Includes',
                highlightFirstItem: true,
                features: [
                    'Premium Card Designs',
                    'Zapier Integration (5000 + Apps)',
                    'Custom Domain',
                    'Advanced Customizations',
                ],
            },
            upgradeCTA: 'Upgrade to Team',
            imageURL: '../../../assets/img/upsell-images/new-card-designs.svg',
            imageBackground: '#FFF0C8',
        },
    },
    cards_integrations : {
        [PLAN_TYPES.Team]: {
            title: {
                main: 'Connect Cards with your favorite apps on ',
                plan: 'Team plan ',
                icon: '✨',
            },
            description: 'Integrate your Uniqode Cards account with other apps to simplify your tasks',
            detail: {
                title: 'TEAM PLAN',
                price: {
                    value: '$6',
                    message: '/user, per month',
                },
                featureHeading: 'Includes',
                highlightFirstItem: false,
                features: [
                    'Zapier Integration (5000+ Apps)',
                    'Google Analytics Integration',
                    'Facebook Pixel Integration',
                    'Google Ads Integrations',
                ],
            },
            upgradeCTA: 'Upgrade to Team',
            imageURL: '../../../assets/img/upsell-images/cards_integrations.svg',
            imageBackground: '#FFF0C8',
        },
    },
};

export const UPSELL_DATA_FOR_V4 = {
    custom_domain: {
        PL: {
            title: 'Get Custom Domain add-on with <b>Plus</b> plan',
            description: 'Brands see a 5x increase in scans using Custom Domains like “qr.<b>yourdomain</b>.com”',
        },
    },
    plan_upgrade: {
        PL: {
            title: 'Get multiple Custom Domains on <b>Business+</b> plan',
            description: 'Increase your brand recognition by adding Custom Domains.',
        },
    },
    geolocation: {
        PO: {
            title: 'Geolocation QR Codes are available on the <b>Pro plan</b>',
            description: 'Redirect scanners to different destination URLs based on their location',
        },
    },
    smart_rules: {
        PO: {
            title: 'Smart Rules QR Codes are available on the <b>Pro plan</b>',
            description: 'Redirect scanners to different destination URLs based on time, date, scans, location, etc.',
        },
    },
    geolocation_country: {
        PO: {
            title: 'Add unlimited countries with our <b>Plus plan</b>',
            description: 'Looks like you\'ve hit the limit for adding countries on your current plan.',
        },
    },
    landing_page: {
        PO: {
            title: 'Landing Page QR Codes are available on the <b>Pro plan</b>',
            description: 'Design and build mobile-friendly landing pages in our HTML Editor.',
        },
    },
};


export const UPSELL_DATA_DISCOUNT_MODAL = {
    spu: {
        [PLAN_TYPES.Pro]: {
            update: {
                title: 'Important: <b>Plan Updates</b>',
                description: 'Starting from your next billing cycle, these features will only be available on our <b>Pro</b> plan',
                features: [
                    'Landing Pages',
                    'Geolocation & Smart Rules',
                    'Age Gating & Password Protection',
                    'QR Code Template Management',
                    'GPS Tracking'
                ],
                note: 'Your live QR Code campaigns will not be affected.',
                warning: 'Click on <b>Buy Pro Now</b> to upgrade instantly and retain these features',
                primaryCTA: 'Buy Pro Now',
                secondaryCTA: 'Continue with Less',
            },
            offer: {
                title: {
                    plan: 'PRO',
                    description: ' Now at ',
                    percent: '50% OFF',
                },
                price: {
                    original: '$49',
                    discounted: '$24.5',
                    billingCycle: 'USD/month, billed yearly',
                    message: 'for first year'
                },
                includes: {
                    heading: 'All Starter and Lite features, plus',
                    features: ['250 Dynamic QR Codes', 'Integrations', 'Multi-language Support', 'Short URL (qrcodes.pro/customtext)', 'QR Code API'],
                },
                imagePath: '../../../assets/img/upsell-images/businesses.png',
            },
            coupon: 'PROAT50'
        }
    }
};
