import {BaseModel} from '../global-services/base.model';
import {Organization} from './user-organization.model';
import {PRODUCT_TYPES} from '../shared/utils';
import {FEATURES} from './organization-permission-model';

export enum PLAN_TYPES {
    Trial = 'TR',
    Starter = 'ST',
    Basic = 'BA',
    Premium = 'PR',
    Reseller = 'RE',
    Umbrella = 'UM',
    WhiteLabel = 'WL',
    UmbrellaReseller = 'UR',
    Enterprise = 'EN',
    BusinessPlus = 'BP',
    EnterpriseAhead = 'EA',
    EnterpriseAccelerate = 'EC',
    EnterpriseUnlimited = 'EU',
    Lite = 'LT',
    Pro = 'PO',
    Plus = 'PL',
    Advanced = 'AD',
    Solo = 'SL',
    Team = 'TM',
    Business = 'BN',
    DBCEnterprise = 'DE',
    DBCBusinessPlus = 'DB',
    DBCFreemium = 'DF',
    DBCNTeam = 'NT',
}

export enum DBC_PRICE_RATES {
    Solo = 6,
    Team = 5,
    Business = 4,
    Enterprise = 4,
    DBCBusinessPlus = 4,
    DBCFreemium = 0,
    DBCNTeam = 6
}

export enum QR_PRICE_RATES {
    Starter = 5,
    Lite = 15,
    Pro = 49,
    Plus = 99,
}

// Highest priority is of BusinessPlus and DBCEnterprise
export const DBC_PLAN_PRIORITIES = {
    [PLAN_TYPES.DBCFreemium]: 1,
    [PLAN_TYPES.DBCNTeam]: 2,
    [PLAN_TYPES.DBCBusinessPlus]: 3,
    [PLAN_TYPES.DBCEnterprise]: 3
}


// Uniqode plans for QR and DBC and their plan prices
export const QR_AND_DBC_PLANS = [
    {
        planType: 'STARTER',
        planPrice: QR_PRICE_RATES.Starter
    },
    {
        planType: 'LITE',
        planPrice: QR_PRICE_RATES.Lite
    },
    {
        planType: 'PRO',
        planPrice: QR_PRICE_RATES.Pro
    },
    {
        planType: 'PLUS',
        planPrice: QR_PRICE_RATES.Plus
    },
    {
        planType: 'SOLO',
        planPrice: DBC_PRICE_RATES.Solo
    },
    {
        planType: 'TEAM',
        planPrice: DBC_PRICE_RATES.Team
    },
    {
        planType: 'BUSINESS',
        planPrice: DBC_PRICE_RATES.Business
    },
];

export enum DBC_MINM_USER_SEATS {
    Solo = 1,
    Team = 10,
    Business = 50,
    Enterprise = 50,
    DBCFreemium = 1,
    DBCNTeam = 1,
    DBCBusinessPlus = 50
}

export enum DBC_MAX_USER_SEATS {
    Solo = 9,
    Team = 49,
    Business = 2499,
    DBCFreemium = 1,
    DBCNTeam = 1000000,
    DBCBusinessPlus = 10000000
}

export const DBC_PLANS_FEATURES_DATA = {
    'Solo': {
        'noOfUsers': '1-9 users',
        'includes': ['3 Cards per user', 'Share card via Apple Wallet/Google Wallet', 'Two-way contact sharing', 'Contact Manager', 'Card analytics', 'GPS tracking']
    },
    'Team': {
        'noOfUsers': '10-49 users',
        'includes': ['Everything in Solo', '5 Cards per user', 'Bulk creation', 'Template management', 'Template enforcement']
    },
    'Business': {
        'noOfUsers': '50+ users',
        'includes': ['Everything in Team', '5 Cards per user', 'Zapier integration', 'Advanced Customizations', 'Premium Card Designs']
    },
    'BusinessPlus': {
        'noOfUsers': '50+ users',
        'includes': ['Everything in Business', 'Unlimited Cards per user', 'Custom domain', 'Active Directory & Salesforce integrations', 'White label', 'Custom Fonts & Style', 'Advanced Customizations']
    },
}

export enum USER_GROUPS {
    Owner = 'OW',
    Admin = 'AD',
    SandBox = 'SB',
    ReadOnly = 'RO'
}

export enum SUBSCRIPTION_STATE {
    Active = 'A',
    Expired = 'E',
    Removed = 'R'
}
export class UserGroup extends BaseModel {
    organization: number;
    organization__name: string;
    user_group: USER_GROUPS;
    cards_permission: boolean
    qr_permission: boolean
    // permissions object is required for server configuration
    permissions: {
        qr: boolean
        cards: boolean;
    }

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.organization = json['organization']
        this.organization__name = json['organization__name']
        this.user_group = json['user_group']
        this.cards_permission = json['cards_permission'];
        this.qr_permission = json['qr_permission'];
        this.permissions = {
            qr: this.qr_permission,
            cards: this.cards_permission
        };
        // Remove qr or cards from permissions
        if (!this.cards_permission) {
            delete this.permissions.cards
        } else if (!this.qr_permission) {
            delete this.permissions.qr
        }
    }
}

export class SubscriptionInfo extends BaseModel {
    plan: PLAN_TYPES;
    state: SUBSCRIPTION_STATE;
    status: string;

    constructor(json?: any) {
        super();
        this.plan = json['plan'] ? json['plan'] : null;
        this.state = json['state'] ? json['state'] : null;
        this.status = json['status'] ? json['status'] : '';
    }
}

export class Subscription extends BaseModel {
    dbc: SubscriptionInfo;
    qr: SubscriptionInfo;

    constructor(json?: any) {
        super();
        this.dbc = json['dbc'] ? new SubscriptionInfo(json['dbc']) : null;
        this.qr = json['qr'] ? new SubscriptionInfo(json['qr']) : null;
    }
}

export class User extends BaseModel {
    id: number;
    username: string;
    email: string;
    email_verified: string;
    billing_email: string;
    first_name: string;
    last_name: string;
    user_group: string;
    customer_plan: PLAN_TYPES;
    date_joined: Date;
    allow_first_login: boolean;
    subscription_state: SUBSCRIPTION_STATE;
    paywhirl_customer_id: string;
    shopify_id: string;
    stripe_id: string;
    firebase_token: string;
    profile_picture: string;
    organization: Organization;
    meta: any;
    timezone: string;
    weekly_analytics: boolean;
    monthly_analytics: boolean;
    org_user_groups: Array<UserGroup> = []
    delete_organizations = []
    designation: string;
    phone_number: string;
    subscription: Subscription;


    static getReadablePlan(plan: PLAN_TYPES): string {
        switch (plan) {
            case PLAN_TYPES.Basic:
                return 'Basic';
            case PLAN_TYPES.Premium:
                return 'Premium';
            case PLAN_TYPES.Reseller:
                return 'Reseller';
            case PLAN_TYPES.Umbrella:
                return 'Umbrella';
            case PLAN_TYPES.WhiteLabel:
                return 'Whitelabel';
            case PLAN_TYPES.Enterprise:
                return 'Enterprise Activate';
            case PLAN_TYPES.BusinessPlus:
                return 'Business+';
            case PLAN_TYPES.EnterpriseAhead:
                return 'Enterprise Ahead';
            case PLAN_TYPES.EnterpriseAccelerate:
                return 'Enterprise Accelerate';
            case PLAN_TYPES.EnterpriseUnlimited:
                return 'Enterprise Unlimited';
            case PLAN_TYPES.UmbrellaReseller:
                return 'Umbrella Reseller';
            case PLAN_TYPES.Trial:
                return 'Trial';
            case PLAN_TYPES.Starter:
                return 'Starter';
            case PLAN_TYPES.Lite:
                return 'Lite';
            case PLAN_TYPES.Pro:
                return 'Pro';
            case PLAN_TYPES.Plus:
                return 'Plus';
            case PLAN_TYPES.Advanced:
                return 'Advanced';
            case PLAN_TYPES.Solo:
                return 'Solo'
            case PLAN_TYPES.Team:
                return 'Team'
            case PLAN_TYPES.Business:
                return 'Business'
            case PLAN_TYPES.DBCEnterprise:
                return 'Enterprise DBC'
            case PLAN_TYPES.DBCBusinessPlus:
                return 'Business+ (DBC)'
            case PLAN_TYPES.DBCFreemium:
                return 'Free plan'
            case PLAN_TYPES.DBCNTeam:
                return 'Team (DBC)'
            default:
                return '';
        }
    }

    constructor(json: any) {
        super();
        this.id = json['id'];
        this.username = json['username'];
        this.email = json['email'];
        this.email_verified = json['email_verified'];
        this.billing_email = json['billing_email'];
        this.first_name = json['first_name'];
        this.last_name = json['last_name'];
        this.user_group = json['user_group'];
        this.customer_plan = json['customer_plan'];
        this.date_joined = json['date_joined'] ? new Date(json['date_joined']) : null;
        this.allow_first_login = json['allow_first_login'];
        this.subscription_state = json['subscription_state'];
        this.shopify_id = json['shopify_id'];
        this.stripe_id = json['stripe_id'];
        this.firebase_token = json['firebase_token'];
        this.paywhirl_customer_id = json['paywhirl_customer_id'];
        this.profile_picture = json['profile_picture'];
        this.organization = new Organization(json['organization']);
        this.meta = json['meta'] || {};
        this.timezone = json['timezone'] || 'UTC';
        this.weekly_analytics = json['weekly_analytics'] || false;
        this.monthly_analytics = json['monthly_analytics'] || false;
        this.phone_number = json['phone_number'];
        this.designation = json['designation'];
        this.subscription = json['subscription'];
        for (const userGroup of (json['org_user_groups'] || [])) {
            this.org_user_groups.push(new UserGroup(userGroup))
        }
        if (json['delete_organizations']) {
            this.delete_organizations = json['delete_organizations']
        }
        this.subscription = json['subscription'] ? new Subscription(json['subscription']) : null;
        // TODO: Handle in better way
        if ( json['subscription'] && json['subscription'].qr) {
            this.customer_plan = json['subscription'].qr.plan;
            this.subscription_state = json['subscription'].qr.state;
        }
    }

    isReseller(): boolean {
        return this.organization.feature_permissions.reseller_access;
    }

    isWhiteLabel(): boolean {
        return this.organization.feature_permissions.whitelabel_access;
    }

    isOwner(): boolean {
        return this.user_group === USER_GROUPS.Owner;
    }

    isAdmin(currentOrgId): boolean {
        return this.isSuperAdmin() || this.org_user_groups.filter(res => res.organization === currentOrgId)[0]?.user_group === USER_GROUPS.Admin;
    }

    hasOwnerPermissions(): boolean {
        return this.user_group === USER_GROUPS.Owner || (this.user_group === USER_GROUPS.Admin && !this.organization.parent);
    }

    isSuperAdmin(): boolean {
        return this.user_group === USER_GROUPS.Owner || (this.user_group === USER_GROUPS.Admin && !this.organization.parent);
    }

    isSuperEditor(): boolean {
        return this.user_group === USER_GROUPS.SandBox && !this.organization.parent;
    }

    isSuperViewer(): boolean {
        return this.user_group === USER_GROUPS.ReadOnly && !this.organization.parent;
    }

    isUmbrellaUser() {
        return this.customer_plan === PLAN_TYPES.Umbrella;
    }

    isSandBoxUser() {
        return this.user_group === USER_GROUPS.SandBox;
    }

    isOnStarterPlan() {
        return this.subscription?.qr?.plan === PLAN_TYPES.Starter;
    }

    isOnLitePlan(){
        return this.subscription?.qr?.plan === PLAN_TYPES.Lite;
    }

    isOnTrialPlan(product: PRODUCT_TYPES = PRODUCT_TYPES.QR) {
        if (product === PRODUCT_TYPES.QR) {
            return this.qrCustomerPlan === PLAN_TYPES.Trial;
        } else if (product === PRODUCT_TYPES.DBC) {
            return this.subscription.dbc ? this.subscription.dbc.status === 'trialing' : false;
        }
    }

    hasQRWatchlistPermission() {
        return this.organization.feature_permissions?.qr_watchlist || false;
    }

    isOnPlusPlan() {
        return this.customer_plan === PLAN_TYPES.Plus;
    }

    isOnEnterprisePlan() {
        return this.customer_plan === PLAN_TYPES.Enterprise || this.customer_plan === PLAN_TYPES.EnterpriseAhead || this.customer_plan === PLAN_TYPES.EnterpriseAccelerate || this.customer_plan === PLAN_TYPES.EnterpriseUnlimited || this.customer_plan === PLAN_TYPES.BusinessPlus;
    }

    isOnQREnterprisePlan() {
        return this.qrCustomerPlan === PLAN_TYPES.Enterprise || this.qrCustomerPlan === PLAN_TYPES.EnterpriseAhead || this.qrCustomerPlan === PLAN_TYPES.EnterpriseAccelerate || this.qrCustomerPlan === PLAN_TYPES.EnterpriseUnlimited || this.qrCustomerPlan === PLAN_TYPES.BusinessPlus;
    }

    isOnDBCEnterprisePlan() {
        return this.dbcCustomerPlan === PLAN_TYPES.DBCEnterprise || this.dbcCustomerPlan === PLAN_TYPES.DBCBusinessPlus;
    }

    isOnDBCFreemiumPlan() {
        return this.dbcCustomerPlan === PLAN_TYPES.DBCFreemium;
    }

    isOnDBCTeamPlan() {
        return this.dbcCustomerPlan === PLAN_TYPES.DBCNTeam;
    }
    hasTrackingAccess(): boolean {
        return this.organization.feature_permissions.retargeting;
    }

    hasMultiUserAccess(): boolean {
        return this.organization.feature_permissions.multi_user;
    }

    hasIntegrationAccess(product: PRODUCT_TYPES = PRODUCT_TYPES.QR): boolean {
        if (product === PRODUCT_TYPES.QR) {
            return this.organization.feature_permissions.webhook;
        } else if (product === PRODUCT_TYPES.DBC) {
            return this.hasDbcPermissionFor(FEATURES.webhook);
        }
    }

    hasBulkUploadAccess(): boolean {
        return this.organization.feature_permissions.bulk_qr;
    }

    hasWalletPassAccess(): boolean {
        return this.organization.feature_permissions.wallet_pass;
    }

    hasCustomDomainAccess(): boolean {
        return this.organization.feature_permissions.custom_domain;
    }

    hasRetargetingAccess(): boolean {
        return this.organization.feature_permissions.retargeting;
    }

    hasLocationAccess(): boolean {
        return this.organization.feature_permissions.location;
    }

    hasCustomSlugAccess(): boolean {
        return this.organization.feature_permissions.custom_slug;
    }

    hasWriteAccess(currentOrgId): boolean {
        return this.isSuperAdmin() ||
      this.org_user_groups.filter(res => res.organization === currentOrgId)[0].user_group !== USER_GROUPS.ReadOnly
    }

    hasAzureADIntegrationAccess(product: PRODUCT_TYPES = PRODUCT_TYPES.QR): boolean {
        if (product === PRODUCT_TYPES.QR) {
            return this.organization.feature_permissions.azure_scim_integration;
        } else if (product === PRODUCT_TYPES.DBC) {
            return this.hasDbcPermissionFor(FEATURES.azure_scim_integration)
        }
    }

    hasSalesForceIntegrationAccess(product: PRODUCT_TYPES = PRODUCT_TYPES.QR): boolean {
        if (product === PRODUCT_TYPES.QR) {
            return false;
        } else if (product === PRODUCT_TYPES.DBC) {
            return this.hasDbcPermissionFor(FEATURES.salesforce_integration)
        }
    }

    hasHubspotIntegrationAccess(): boolean {
        return this.organization.dbc_feature_permissions.hubspot_integration;
    }

    hasDBCAccess(currentOrgId): boolean {

        let state = false;

        if (this.subscription.dbc) {
            state = !(this.subscription.dbc.state === SUBSCRIPTION_STATE.Expired)
        }

        let permissionInCurrentOrg: boolean

        if (!this.isSuperAdmin()) {
            permissionInCurrentOrg = this.org_user_groups.filter((userGroup) => userGroup.organization === currentOrgId)[0].cards_permission
        } else {
            permissionInCurrentOrg = this.org_user_groups[0].cards_permission
        }

        return this.organization.feature_permissions.dbc && state && permissionInCurrentOrg;
    }

    hadDBCAccess(): boolean {
        return !!this.subscription.dbc;
    }


    hasQRAccess(currentOrgId): boolean {

        let state = false;

        if (this.subscription.qr) {
            state = !(this.subscription.qr.state === SUBSCRIPTION_STATE.Expired)
        }

        let permissionInCurrentOrg: boolean

        if (!this.isSuperAdmin()) {
            permissionInCurrentOrg = this.org_user_groups.filter((userGroup) => userGroup.organization === currentOrgId)[0].qr_permission
        } else {
            permissionInCurrentOrg = this.org_user_groups[0].qr_permission
        }

        return this.organization.feature_permissions.qr && state && permissionInCurrentOrg
    }

    hadQRAccess(): boolean {
        return !!this.subscription.qr;
    }

    getReadableGroup(): string {
        switch (this.user_group) {
            case USER_GROUPS.Admin:
                return 'Administrator';
            case USER_GROUPS.Owner:
                return 'Owner';
            case USER_GROUPS.ReadOnly:
                return 'Viewer';
            case USER_GROUPS.SandBox:
                return 'Editor';
            default:
                return '';
        }
    }

    getReadableGroupFromCurrentOrg(currentOrgId): string {
        const userGroup = this.org_user_groups.filter((res) => {
            return res.organization === currentOrgId
        })[0].user_group
        switch (userGroup) {
            case USER_GROUPS.Admin:
                return 'Administrator';
            case USER_GROUPS.Owner:
                return 'Owner';
            case USER_GROUPS.ReadOnly:
                return 'Viewer';
            case USER_GROUPS.SandBox:
                return 'Editor';
            default:
                return '';
        }
    }

    getReadablePlan(product: PRODUCT_TYPES = PRODUCT_TYPES.QR): string {
        if (product === PRODUCT_TYPES.QR) {
            return User.getReadablePlan((this.qrCustomerPlan));
        } else {
            return User.getReadablePlan(this.dbcCustomerPlan);
        }
    }

    toJSON(): object {
        return {
            id: this.id,
            username: this.username,
            email: this.email,
            email_verified: this.email_verified,
            billing_email: this.billing_email,
            first_name: this.first_name,
            last_name: this.last_name,
            user_group: this.user_group,
            customer_plan: this.customer_plan,
            date_joined: this.date_joined?.toISOString(),
            allow_first_login: this.allow_first_login,
            subscription_state: this.subscription_state,
            shopify_id: this.shopify_id,
            stripe_id: this.stripe_id,
            paywhirl_customer_id: this.paywhirl_customer_id,
            firebase_token: this.firebase_token,
            profile_picture: this.profile_picture,
            organization: this.organization?.toJSON(),
            meta: this.meta,
            timezone: this.timezone,
            weekly_analytics: this.weekly_analytics,
            monthly_analytics: this.monthly_analytics,
            org_user_groups: this.org_user_groups,
            delete_organizations: this.delete_organizations,
            designation: this.designation,
            phone_number: this.phone_number,
            subscription: this.subscription
        };
    }

    toString(): string {
        return JSON.stringify(this.toJSON());
    }

    /**
   * Returns true if customers plan if higher than specified plan
   * @param plan to compare
   */
    isOnHigherPlan(plan: PLAN_TYPES): boolean {
        const customerPlan = this.qrCustomerPlan;
        let isOnHigherPlan = true;
        switch (customerPlan) {
            case PLAN_TYPES.Trial:
                if (plan === PLAN_TYPES.Starter || plan === PLAN_TYPES.Lite || plan === PLAN_TYPES.Pro || plan === PLAN_TYPES.Plus ||
          plan === PLAN_TYPES.Advanced || plan === PLAN_TYPES.Basic
          || plan === PLAN_TYPES.Premium || plan === PLAN_TYPES.Reseller
          || plan === PLAN_TYPES.WhiteLabel || plan === PLAN_TYPES.UmbrellaReseller || plan === PLAN_TYPES.BusinessPlus || plan === PLAN_TYPES.Enterprise || plan ===  PLAN_TYPES.EnterpriseAhead || plan ===  PLAN_TYPES.EnterpriseAccelerate) {
                    isOnHigherPlan = false;
                }
                break;
            case PLAN_TYPES.Starter:
                if (plan === PLAN_TYPES.Lite || plan === PLAN_TYPES.Pro || plan === PLAN_TYPES.Plus ||
          plan === PLAN_TYPES.Advanced || plan === PLAN_TYPES.Basic
          || plan === PLAN_TYPES.Premium || plan === PLAN_TYPES.Reseller
          || plan === PLAN_TYPES.WhiteLabel || plan === PLAN_TYPES.UmbrellaReseller || plan === PLAN_TYPES.BusinessPlus || plan ===  PLAN_TYPES.Enterprise || plan ===  PLAN_TYPES.EnterpriseAhead || plan ===  PLAN_TYPES.EnterpriseAccelerate) {
                    isOnHigherPlan = false;
                }
                break;
            case PLAN_TYPES.Lite:
                if (plan === PLAN_TYPES.Pro || plan === PLAN_TYPES.Plus || plan === PLAN_TYPES.Basic
          || plan === PLAN_TYPES.Premium || plan === PLAN_TYPES.Advanced || plan === PLAN_TYPES.Reseller
          || plan === PLAN_TYPES.WhiteLabel || plan === PLAN_TYPES.UmbrellaReseller || plan === PLAN_TYPES.BusinessPlus || plan ===  PLAN_TYPES.Enterprise || plan ===  PLAN_TYPES.EnterpriseAhead || plan ===  PLAN_TYPES.EnterpriseAccelerate) {
                    isOnHigherPlan = false;
                }
                break;
            case PLAN_TYPES.Pro:
                if (plan === PLAN_TYPES.Plus || plan === PLAN_TYPES.Basic
          || plan === PLAN_TYPES.Premium || plan === PLAN_TYPES.Advanced || plan === PLAN_TYPES.Reseller
          || plan === PLAN_TYPES.WhiteLabel || plan === PLAN_TYPES.UmbrellaReseller || plan === PLAN_TYPES.BusinessPlus || plan ===  PLAN_TYPES.Enterprise || plan ===  PLAN_TYPES.EnterpriseAhead || plan ===  PLAN_TYPES.EnterpriseAccelerate) {
                    isOnHigherPlan = false;
                }
                break;
            case PLAN_TYPES.Basic:
                if (plan === PLAN_TYPES.Premium || plan === PLAN_TYPES.Advanced || plan === PLAN_TYPES.Reseller
          || plan === PLAN_TYPES.WhiteLabel || plan === PLAN_TYPES.UmbrellaReseller || plan === PLAN_TYPES.BusinessPlus || plan ===  PLAN_TYPES.Enterprise || plan ===  PLAN_TYPES.EnterpriseAhead || plan ===  PLAN_TYPES.EnterpriseAccelerate) {
                    isOnHigherPlan = false;
                }
                break;
            case PLAN_TYPES.Plus:
                if (plan === PLAN_TYPES.Premium || plan === PLAN_TYPES.Advanced || plan === PLAN_TYPES.Reseller
          || plan === PLAN_TYPES.WhiteLabel || plan === PLAN_TYPES.UmbrellaReseller || plan === PLAN_TYPES.BusinessPlus || plan ===  PLAN_TYPES.Enterprise || plan ===  PLAN_TYPES.EnterpriseAhead || plan ===  PLAN_TYPES.EnterpriseAccelerate) {
                    isOnHigherPlan = false;
                }
                break;
            case PLAN_TYPES.Premium:
                if (plan === PLAN_TYPES.Reseller || plan === PLAN_TYPES.WhiteLabel || plan === PLAN_TYPES.UmbrellaReseller || plan === PLAN_TYPES.BusinessPlus || plan ===  PLAN_TYPES.Enterprise || plan ===  PLAN_TYPES.EnterpriseAhead || plan ===  PLAN_TYPES.EnterpriseAccelerate) {
                    isOnHigherPlan = false;
                }
                break;
            case PLAN_TYPES.Advanced:
                if (plan === PLAN_TYPES.Reseller || plan === PLAN_TYPES.WhiteLabel || plan === PLAN_TYPES.UmbrellaReseller || plan === PLAN_TYPES.BusinessPlus || plan ===  PLAN_TYPES.Enterprise || plan ===  PLAN_TYPES.EnterpriseAhead || plan ===  PLAN_TYPES.EnterpriseAccelerate) {
                    isOnHigherPlan = false;
                }
                break;
            case PLAN_TYPES.Reseller:
                if (plan === PLAN_TYPES.WhiteLabel || plan === PLAN_TYPES.UmbrellaReseller || plan === PLAN_TYPES.BusinessPlus || plan ===  PLAN_TYPES.Enterprise || plan ===  PLAN_TYPES.EnterpriseAhead || plan ===  PLAN_TYPES.EnterpriseAccelerate) {
                    isOnHigherPlan = false;
                }
                break;
            case PLAN_TYPES.WhiteLabel:
                isOnHigherPlan = true;
                break;
            case PLAN_TYPES.Enterprise:
                isOnHigherPlan = true;
                break;
            case PLAN_TYPES.BusinessPlus:
                isOnHigherPlan = true;
                break;
            case PLAN_TYPES.EnterpriseAhead:
                isOnHigherPlan = true;
                break;
            case PLAN_TYPES.EnterpriseAccelerate:
                isOnHigherPlan = true;
                break;
            default:
                isOnHigherPlan = false;
        }
        return isOnHigherPlan;
    }

    isOnHigherDBCPlan(plan: PLAN_TYPES): boolean {
        const customerPlan = this.subscription.dbc?.plan;
        let isOnHigherDBCPlan = true;
        switch (customerPlan) {
            case PLAN_TYPES.Trial:
                if (plan === PLAN_TYPES.Solo || plan === PLAN_TYPES.Team || plan === PLAN_TYPES.Business || plan === PLAN_TYPES.DBCEnterprise) {
                    isOnHigherDBCPlan = false;
                }
                break;
            case PLAN_TYPES.Solo:
                if (plan === PLAN_TYPES.Team || plan === PLAN_TYPES.Business || plan === PLAN_TYPES.DBCEnterprise) {
                    isOnHigherDBCPlan = false;
                }
                break;
            case PLAN_TYPES.Team:
                if (plan === PLAN_TYPES.Business || plan === PLAN_TYPES.DBCEnterprise) {
                    isOnHigherDBCPlan = false;
                }
                break;
            case PLAN_TYPES.Business:
                if (plan === PLAN_TYPES.DBCEnterprise || plan === PLAN_TYPES.DBCBusinessPlus) {
                    isOnHigherDBCPlan = false;
                }
                break;
            case PLAN_TYPES.DBCEnterprise:
            case PLAN_TYPES.DBCBusinessPlus:
                isOnHigherDBCPlan = true;
                break;
            default:
                isOnHigherDBCPlan = false;
        }
        return isOnHigherDBCPlan;
    }

    /**
     * Returns true if customers plan if higher than specified comparisonPlan
     * @param comparisonPlan to compare
     * @returns {boolean}
     * **/
    planIsGreatorThan(comparisonPlan: PLAN_TYPES) {
        return (DBC_PLAN_PRIORITIES[this.dbcCustomerPlan] > DBC_PLAN_PRIORITIES[comparisonPlan]);
    }
    isOnQRPlan() {
        switch (this.customer_plan) {
            case PLAN_TYPES.Trial:
            case PLAN_TYPES.Starter:
            case PLAN_TYPES.Lite:
            case PLAN_TYPES.Pro:
                return true;
            default:
                return false;
        }
    }

    isOnDBCPlan() {
        switch (this.subscription.dbc?.plan) {
            case PLAN_TYPES.Solo:
            case PLAN_TYPES.Team:
            case PLAN_TYPES.Business:
            case PLAN_TYPES.DBCBusinessPlus:
            case PLAN_TYPES.DBCEnterprise:
            case PLAN_TYPES.DBCFreemium:
            case PLAN_TYPES.DBCNTeam:
                return true;
            default:
                return false;
        }
    }


    get maxQRAllowed(): number {
        return this.organization.account_limits.qrcode;
    }

    get maxLinkpageAllowed(): number {
        return this.organization.account_limits.link_page;
    }

    get maxDbcPerOwnerAllowed(): number {
        return this.organization.account_limits.dbc;
    }

    get maxScansAllowed(): number {
        return this.organization.account_limits.qrcode_scan
    }

    get maxUsersAllowed(): number {
        return this.organization.account_limits.child_user;
    }

    get maxChildOrgnanisationAllowed(): number {
        return this.organization.account_limits.child_organization
    }

    get maxLinkPageAllowed(): number {
        return this.organization.account_limits.link_page;
    }

    get mainOrganization() {
        return this.organization.parent || this.organization.id
    }

    get maxLinksPerLinkpageAllowed(): number {
        return this.organization.account_limits.links_per_page
    }

    get maxCustomDomainAllowed(): number {
        return this.organization.account_limits.custom_domain;
    }

    get qrCustomerPlan(): PLAN_TYPES {
        return this.subscription.qr ? this.subscription.qr.plan : null
    }

    get qrSubscriptionState(): SUBSCRIPTION_STATE {
        if (this.subscription.qr) {
            return this.subscription.qr.state;
        }
        return this.subscription_state;
    }

    get dbcCustomerPlan(): PLAN_TYPES {
        return this.subscription.dbc ? this.subscription.dbc.plan : null;
    }

    get dbcSubscriptionState(): SUBSCRIPTION_STATE {
        return this.subscription.dbc ? this.subscription.dbc.state : SUBSCRIPTION_STATE.Expired;
    }

    get isOnOldDbcPlan() {
        const old_plans = [PLAN_TYPES.Solo, PLAN_TYPES.Team, PLAN_TYPES.Business]; // Plans before releasing DBC freemium pricing changes
        return old_plans.includes(this.dbcCustomerPlan);
    }

    isEmailReportsAllowed(): boolean {
        return [PLAN_TYPES.Reseller, PLAN_TYPES.WhiteLabel, PLAN_TYPES.UmbrellaReseller, PLAN_TYPES.Umbrella].indexOf(this.customer_plan) === -1
    }

    hasInvitationExpired(): boolean {
        let hasExpired = this.meta.hasOwnProperty('invitation_state') && this.meta['invitation_state'] === 'pending';
        hasExpired = hasExpired && this.meta.hasOwnProperty('invitation_expires_at') && this.meta['invitation_expires_at'] <= new Date().getTime() / 1000;
        return hasExpired;
    }

    hasBillingEmailAndEmailSame() {
        return this.email === this.billing_email;
    }

    isOnPlusPLan() {
        return this.customer_plan === PLAN_TYPES.Plus;
    }

    isOnProPlan() {
        return this.customer_plan === PLAN_TYPES.Pro;
    }

    getMaxBatchSizeAllowed() {
        switch (this.customer_plan) {
            case PLAN_TYPES.Trial:
            case PLAN_TYPES.Starter:
            case PLAN_TYPES.Lite:
                return 50;
            case PLAN_TYPES.Pro:
                return 250;
            case PLAN_TYPES.Plus:
                return 1000;
            default:
                return 2000;
        }
    }

    isInvitationPending() {
        return this.meta.hasOwnProperty('invitation_state') && this.meta['invitation_state'] === 'pending'
    }

    isEditor(currentOrgId) {
        return this.org_user_groups.filter(res => res.organization === currentOrgId)[0]?.user_group === USER_GROUPS.SandBox;
    }

    isViewer(currentOrgId) {
        return this.org_user_groups.filter(res => res.organization === currentOrgId)[0]?.user_group === USER_GROUPS.ReadOnly;
    }

    isOnSoloTrial() {
        return this.dbcCustomerPlan === PLAN_TYPES.Solo && (this.isOnTrialPlan(PRODUCT_TYPES.DBC));
    }

    isOnTeamTrial() {
        return this.dbcCustomerPlan === PLAN_TYPES.Team && (this.isOnTrialPlan(PRODUCT_TYPES.DBC));
    }

    isOnBusinessTrial() {
        return this.dbcCustomerPlan === PLAN_TYPES.Business && (this.isOnTrialPlan(PRODUCT_TYPES.DBC));
    }

    isOnSoloPlan() {
        return this.dbcCustomerPlan === PLAN_TYPES.Solo;
    }

    isOnTeamPlan() {
        return this.dbcCustomerPlan === PLAN_TYPES.Team;
    }

    isOnBusinessPlan() {
        return this.dbcCustomerPlan === PLAN_TYPES.Business
    }

    getProductSubscriptionState(product: PRODUCT_TYPES) {
        return this.subscription[product] ? this.subscription[product].state : null
    }

    // Use these functions to check feature permissions for product.
    hasDbcPermissionFor(feature: FEATURES) {
        return this.organization?.dbc_feature_permissions[feature] || false;
    }

    hasQRPermissionFor(feature: FEATURES) {
        return this.organization?.feature_permissions[feature] || false;
    }

    hasInviteCollaboratorAccess(product: PRODUCT_TYPES = PRODUCT_TYPES.QR) {
        return product === PRODUCT_TYPES.QR
            ? this.organization.feature_permissions.non_admin_add_user
            : this.organization.dbc_feature_permissions.non_admin_add_user;
    }

    hasName() {
        return this.first_name && this.last_name;
    }

}
