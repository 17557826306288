
export class SocialLinkObject {
    redirect_url: string = '';
    icon_url: string = '';
    button_name: string = '';
    button_id: string = '';
    custom_icon: boolean = false;
    __isValidURL__: boolean = true;
    __isValidButtonName__: boolean = true;

    constructor(json?) {
        if (!json) {
            return;
        }
        this.redirect_url = json['redirect_url'] || '';
        this.icon_url = json['icon_url'] || '';
        this.button_name = json['button_name'] || '';
        this.button_id = json['button_id'].toLowerCase() || '';
        this.custom_icon = json['custom_icon'] || false;
    }

    clearModelForPost() {
        delete this.__isValidURL__;
        delete this.__isValidButtonName__;
    }

    clearModelForPut() {
        delete this.__isValidURL__;
        delete this.__isValidButtonName__;
    }

}
