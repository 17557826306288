import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-blur-paywall',
    templateUrl: './blur-paywall.component.html',
    styleUrls: ['./blur-paywall.component.scss']
})
export class BlurPaywallComponent {
    @Input() message: string = 'Upgrade to view more';  // Default message
    @Input() minHeight: string = '100%';  // Default height

    @Output() upgradeClicked: EventEmitter<void> = new EventEmitter<void>();

    onUpgradeClick() {
        this.upgradeClicked.emit();
    }
}
